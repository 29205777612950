import React, { useState, useEffect } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const CustomPagination = ({ pagination, setPagination, totalLength, loader }) => {
    const [pageNumber, setPageNumber] = useState([]);

    useEffect(() => {
        const totalPages = Math.ceil((totalLength || 0) / (pagination.totalItemOnPage || 1)) || 1;
        const { currentPage } = pagination;

        const range = 2;
        const pagesToShow = [];

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pagesToShow.push(i);
            }
        } else {
            pagesToShow.push(1);

            if (currentPage > range + 2) {
                pagesToShow.push("...");
            }

            for (
                let i = Math.max(2, currentPage - range);
                i <= Math.min(totalPages - 1, currentPage + range);
                i++
            ) {
                pagesToShow.push(i);
            }

            if (currentPage < totalPages - range - 1) {
                pagesToShow.push("...");
            }

            pagesToShow.push(totalPages);
        }
        ;
        setPageNumber(pagesToShow);
    }, [totalLength, pagination]);

    const handlePageChange = (page) => {
        if (page !== "..." && page >= 1 && page <= Math.ceil(totalLength / pagination.totalItemOnPage)) {
            setPagination({ ...pagination, currentPage: page });
        }
    };

    return (
        <div className="table-paging">
            <ul>

                <li
                    onClick={() => handlePageChange(pagination.currentPage - 1)}
                    className={pagination.currentPage === 1 ? "disabled" : ""}
                >
                    <FiChevronLeft />
                </li>
                {pageNumber.map((page, index) =>
                    page === "..." ? (
                        <li key={`dots-${index}`} className="dots">
                            {page}
                        </li>
                    ) : (
                        <li
                            key={`page-${page}`}
                            className={page === pagination.currentPage ? "active" : ""}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </li>
                    )
                )}
                <li
                    onClick={() => handlePageChange(pagination.currentPage + 1)}
                    className={
                        pagination.currentPage === Math.ceil(totalLength / pagination.totalItemOnPage)
                            ? "disabled"
                            : ""
                    }
                >
                    <FiChevronRight />
                </li>
            </ul>
        </div>
    );
};

export default CustomPagination;

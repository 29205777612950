import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack } from "react-icons/io5";

function VerificationFourthStep() {
    return (
        <>
            <div className="verification-wrap">
                <div className="verification-outer">
                    <div className="verification-title">
                        <button className="nextBtn"><IoChevronBack /></button>
                        <h2>Add phone number</h2>
                        <button className="nextBtn"><IoClose /></button>
                    </div>
                    <div className="verification-main">
                        <div className="text-center"><img src={require("../../../images/icon/mobile.svg").default} alt="" /></div>
                        <h4 className="text-center pt-4">Check your text messages</h4>
                        <p className="text-center pt-2">Enter the verification code we sent to</p>
                        <h5 className="text-center pt-2">+852 19850622</h5>

                        <div className="otp-field mt-4">
                            <ul>
                                <li><input className="input-style" type="text" /></li>
                                <li><input className="input-style" type="text" /></li>
                                <li><input className="input-style" type="text" /></li>
                                <li><input className="input-style" type="text" /></li>
                            </ul>
                        </div>

                        <p className="text-center pt-4">Resend (60s)</p>
                        <p className="text-center pt-2"><a href="">Need help?</a></p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default VerificationFourthStep;
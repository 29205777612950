import React, { useState, useEffect } from 'react'
import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Dropdown } from 'react-bootstrap';
import event from "../../../images/icon/events.svg"
import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import Pagination from '../../Common/Pagination';
import { FaSpinner } from 'react-icons/fa';
const PayoutEarnings = (props) => {
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([])

    const filterData = (data, filter) => {

        const now = new Date();
        let startDate, endDate;

        switch (filter) {

            case 'week':
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 6);
                endDate = new Date(now);
                // startDate = new Date(now.setDate(now.getDate() - now.getDay())); 
                // endDate = new Date(now.setDate(startDate.getDate() + 6)); 
                break;
            case 'month':
                // startDate = new Date(now.getFullYear(), now.getMonth(), 1); 
                // endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); 
                startDate = new Date(now);
                startDate.setMonth(now.getMonth() - 1); // Start date is one month ago from today
                endDate = new Date(now); // End date is today

                break;
            case 'year':
                // startDate = new Date(now);
                // startDate.setFullYear(now.getFullYear() - 1); // Start date is one year ago from today
                // endDate = new Date(now); // End date is today
                startDate = new Date(now.getFullYear(), 0, 1);
                endDate = new Date(now.getFullYear(), 11, 31);
                break;
            default:

                return data;
        }

        return data.filter(item => {

            const itemDate = new Date(item.created_at);

            return itemDate >= startDate && itemDate <= endDate;
        });
    };


    useEffect(() => {

        setFilteredData(filterData(data, filter));
    }, [data, filter]);
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        } else {
            setData([])
        }
    }, [props.data])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                    <>
                        <div className="account-block mt-4">

                            <div className='d-flex align-items-center justify-content-between'>
                                <h6>Payout History</h6>
                                <div className='input-wrap simple'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            {filter}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setFilter("week")}>This Week</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilter("month")}>This Month</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setFilter("year")}>This Year</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className='table-responsive mt-4'>
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>Tansactoion ID</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                            <th>Payout Amount</th>
                                            <th>Remaining Balance</th>
                                            <th>Status</th>
                                            <th> Paid Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                            const bankDetails = JSON.parse(curElem.bank_details)
                                            return (<tr key={i}>
                                                <td data-label="Tansactoion ID">{curElem.id}</td>
                                                <td data-label="Description">Bank Transfer for ₹{curElem.amount} to {bankDetails.account_no}</td>
                                                <td data-label="Date"><img src={event} alt="" />{curElem.created_at}</td>
                                                <td data-label="Payout Amount">₹{curElem.amount}</td>
                                                <td data-label="Remaining Balance">₹{curElem.balance}</td>
                                                {curElem.status === "1" ? <td><span className="table-stat active">Approved</span></td> : curElem.status === "0" ? <td><span className="table-stat inactive">Pending</span></td> : <td><span className="table-stat banned">Rejected</span></td>}

                                                {curElem.is_payed === "1" ? <td><span className="table-stat active">Paid</span></td> : <td><span className="table-stat inactive">Unpaid</span></td>}



                                            </tr>)
                                        }) :
                                            <td className='text-center pt-3' colspan={7}>No taskers Data</td>
                                        }
                                    </tbody>
                                </table>
                                <Pagination

                                    listData={filteredData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={data}
                                    loader={props.loader.fetch}
                                />

                            </div>
                        </div>
                    </>
            }
        </>

    )
}

export default PayoutEarnings
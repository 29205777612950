import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
export const fetchMessageAction = (url, formData, setData, loader, setLoader,) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const fetchAllMessage = (url, formData, setData, loader, setLoader,) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data.list)
            }
            setLoader({
                ...loader,
                create: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const sendMessageAction = (url, formData, setData, loader, setLoader, handleCreateMessage) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setData('')
                setLoader({
                    ...loader,
                    save: false
                })
                handleCreateMessage(formData.id)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setData('')
                setLoader({
                    ...loader,
                    save: false
                })
            }
            setData('')
            setLoader({
                ...loader,
                save: false
            })
            handleCreateMessage(formData.id)
        })
        .catch((err) => {
            console.log(err)


        })
}
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const fetchAssignActionList = (url, formData, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const fetchUserList = (url, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios(url, {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const SubmitUserAssignList = (url, formdata, setLoader, loader, fetchData, handleClose, setCheckedId, handleClose2) => (dispatch, getState) => {

    commonAxios(url, formdata, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleClose()
                setCheckedId([])
                fetchData()
                handleClose2()
                setLoader({
                    ...loader,
                    assign: false,
                    delete: false
                })
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                assign: false,
                delete: false
            })
            handleClose2()
        })
        .catch((err) => {
            console.log(err)


        })
}

import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";



export const FetchAdminTaskTaskerUser = (formData, loader, setLoader, setData) => (dispatch, getState) => {

    commonAxios("fetch-user-list", formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const FetchAdminTaskTaskerSubmissionlist = (formData, loader, setLoader, setData) => (dispatch, getState) => {

    commonAxios("fetch-task-submission-list", formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}
export const FetchAdminCreatorUser = (formData, loader, setLoader, setData) => (dispatch, getState) => {

    commonAxios("/fetch-creator-list", formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}


export const FetchAdminTaskerUserDetails = (data, loader, setLoader, setUserData) => (dispatch, getState) => {

    commonAxios("admin-users-details", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setUserData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}
export const FetchAdminCreaterUserDetails = (data, loader, setLoader, setUserData) => (dispatch, getState) => {

    commonAxios("admin-creator-details", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setUserData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const activeAccountUserDetails = (data, loader, setLoader, handleClose1, fetchDataTasker) => (dispatch, getState) => {

    commonAxios("activate-account", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchDataTasker()

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose1()
            setLoader({
                ...loader,
                banAccount: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const banAccoutUserDetails = (data, loader, setLoader, handleClose, fetchDataTasker) => (dispatch, getState) => {

    commonAxios("ban-account", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchDataTasker()
            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                active: false,
            })
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}
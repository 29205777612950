import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { MdCurrencyRupee } from 'react-icons/md';
import Pagination from '../../Common/Pagination';
import { FaSpinner } from 'react-icons/fa';


function MySpending(props) {
    const [filter, setFilter] = useState('');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 5
    });

    const filterData = (data, filter) => {
        const now = new Date();
        let startDate;

        switch (filter) {
            case 'Week':
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 6);
                break;
            case 'Month':
                startDate = new Date(now);
                startDate.setMonth(now.getMonth() - 1);
                break;
            case 'Year':
                startDate = new Date(now.getFullYear(), 0, 1);
                break;
            default:
                return data;
        }

        return data.filter(item => {
            const itemDate = new Date(item.created_at);
            return itemDate >= startDate && itemDate <= now;
        });
    };

    let spendingData = [];
    if (props.all_spending) {
        spendingData = props.all_spending;
    }

    const filteredData = filterData(spendingData, filter);


    const indexOfLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexOfFirstTodo, indexOfLastTodo);

    return (
        <>
            <div className='account-block relative'>
                <h5>Spending</h5>
                <div className='contStat'>
                    <ul>
                        <li>
                            <h6>Net Spending</h6>
                            <h5>{props.spending.net_spending}</h5>
                        </li>
                        <li>
                            <h6>Billed</h6>
                            <h5>{props.spending.billed || 0}</h5>
                        </li>
                        <li>
                            <h6>Upcoming</h6>
                            <h5>{props.spending.upcoming_spend}</h5>
                        </li>
                        <li>
                            <h6>Platform Fee ( 5% )</h6>
                            <h5>{props.spending.platform_fee || 0}</h5>
                        </li>
                    </ul>
                </div>
                <hr className='mb-0' />
            </div>

            <div className='account-block mt-4 mb-3 mb-lg-5'>
                <div className='d-flex align-items-center justify-content-between'>
                    <h6>All Spending</h6>
                    <div className="table_drop_down">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" style={{ border: "1px solid #c7c7c747", fontSize:"14px" }}>
                                {filter || "Select Filter"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{fontSize:"14px"}}>
                                <Dropdown.Item onClick={() => setFilter("Week")}>This Week</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter("Month")}>This Month</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter("Year")}>This Year</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <div className='table-responsive mt-4'>
                    {props.loader.fetch ? <FaSpinner className='spin ms-1 w-100 d-flex mt-4' style={{ fontSize: "40px" }} /> :
                        <table className='table theme-table'>
                            <thead>
                                <tr>
                                    <th>Task ID</th>
                                    <th>Task Title</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {currentTodo.length > 0 ?
                                    currentTodo.map((data, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <tr >
                                                    <td data-label="Task ID"># {data.id}</td>
                                                    <td data-label="Task Title">{data.title}</td>
                                                    <td data-label="Date"><IoCalendarClearOutline /> {data.created_at}</td>
                                                    <td data-label="Amount"><MdCurrencyRupee /> {data.price}</td>
                                                    <td data-label="Status">
                                                        <span className={`table-stat ${data.status === "1" ? "active" : data.status === "0" ? "inactive" : "banned"}`}>
                                                            {data.status === "1" ? "Complete" : data.status === "0" ? "Pending" : "Reject"}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    }

                                    )
                                    :
                                    <tr>
                                        <td className="text-center mt-4 w-100" colSpan="5">There is no spending here.</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>

                <Pagination
                    listData={filteredData}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentTodo={currentTodo}
                    listArr={props.all_spending}
                    loader={props.loader.fetch}
                />
            </div>
        </>
    );
}

export default MySpending;

import React, { useEffect, useState } from 'react'
import TitleBar from '../../Common/TitleBar'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import DashboardHeader from '../../Common/DashboardHeader'
import { ScrollPanel } from 'primereact/scrollpanel'
import { IoSearchOutline } from 'react-icons/io5'
import DashboardFooter from '../../Common/DashboardFooter'
import { Dropdown } from 'primereact/dropdown';
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { useDispatch } from 'react-redux'
import { onBordingFetchData, onBordingSave } from '../../../Redux/Actions/AdminNotificationList'
import { FaSpinner } from 'react-icons/fa'
const AdminOnboarding = () => {
    const dispatch = useDispatch()
    const [language, setLanguage] = useState({ name: 'Hindi', code: 'Hindi' })
    const [data, setData] = useState([])
    const [url, setUrl] = useState("")
    const [description, setDescription] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        save: false
    })
    const Langauge = [
        { name: 'Hindi', code: 'Hindi' },
        { name: 'English', code: 'English' },
    ]
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const fetchOnbording = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onBordingFetchData("", setData, setLoader, loader))

    }
    useEffect(() => {
        fetchOnbording()
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault()
        if (url === "") {
            dispatch(setAlert("Please enter video url", "danger"))
            return;
        }
        if (description === "") {
            dispatch(setAlert("Please enter description", "danger"))
            return;
        }
        setLoader({
            ...loader,
            save: true
        })
        let formData = {
            "url": url,
            "description": description,
            "language": language.code,
        }
        dispatch(onBordingSave("", formData, setLoader, loader, fetchOnbording, setDescription, setUrl))

    }
    return (
        <>
            <TitleBar title="onboarding" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="RaisedQuery" />
                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="content-area-in" style={{ padding: "22px 35px" }}>
                                                <div className='creator_new_task h-100'>
                                                    <div className='account-block phone-emails mb-4'>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-lg-10 mb-4'>
                                                                    <div className="submission_wrap submission_bg ">
                                                                        <label for="" className="form-label">Url</label>
                                                                        <input type="url" className="form-control border-0" id="exampleFormControlInput1" placeholder="Video url" name="url" value={url} onChange={(e) => setUrl(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 mb-4'>
                                                                    <div className="submission_wrap submission_bg placeholder-grow">
                                                                        <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} options={Langauge} optionLabel="name"
                                                                            value={language} onChange={(e) => setLanguage(e.value)}
                                                                            placeholder="Language" className="rounded-2 fs-2 border-0" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 mb-4'>
                                                                    <div className='submission_wrap submission_bg py-3'>
                                                                        <div className='theme-focus-bg'>
                                                                            <h6 className='mb-2'>Description</h6>
                                                                            <textarea className="form-control border-0" id="" rows="6" placeholder='Description' name='description'
                                                                                value={description} onChange={(e) => setDescription(e.target.value)}
                                                                            ></textarea>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 d-flex'>
                                                                    <button className='theme-btn ms-auto' type="submit">Save {loader.save ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="refer-table mt-3">
                                                    <div className="row">
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <h6>Onboarding list
                                                            </h6>
                                                        </div>
                                                        <div className="table-responsive mt-2">

                                                            <table className="table theme-table">
                                                                <tr>
                                                                    <th>S.No
                                                                    </th>
                                                                    <th>Langauge</th>
                                                                    <th>Url</th>
                                                                    <th>Description</th>
                                                                </tr>
                                                                {
                                                                    loader.fetch ? <td className='text-center pt-3'><FaSpinner className="spin ms-1" style={{ fontSize: "42px" }} /></td> :
                                                                        data?.length > 0 ? data.map((curElem, i) => {
                                                                            return (

                                                                                < tr key={i} >
                                                                                    < td data-label="S.No">{i + 1}</td>
                                                                                    <td data-label="Langauge"></td>
                                                                                    <td data-label="Url">
                                                                                    </td>
                                                                                    <td data-label="Description"></td>
                                                                                </tr>
                                                                            )
                                                                        }) : <td className='text-center pt-3' colSpan={7}>No data found</td>
                                                                }

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollPanel>

                                        <div>
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                            <RecommendTasks />
                        </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* <RaisedQueryModal
                show={show}
                handleClose={handleClose}
            /> */}


        </>
    )
}

export default AdminOnboarding

import React, { useState } from 'react'
import CreatorTaskDetails from './CreatorTaskDetails'
import DashboardHeader from '../../../Common/DashboardHeader'
import { Nav, Tab } from 'react-bootstrap'
import CreatorTaskApplications from './CreatorTaskApplications'
import CreatorTaskSubmissions from './CreatorTaskSubmissions'
import CreatorTaskReviews from './CreatorTaskReviews'
import SidePanelTaskCreator from '../../../Common/SidePanelTaskCreator'
import CreateNewTask from '../CreateNewTask'
import DashboardFooter from '../../../Common/DashboardFooter'
import EditCreatorTask from './EditCreatorTask'

const CreatorNavDetails = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    return (
        <>
            <div className="dashboard creator_new_task review-footer container-fluid overflow-hidden">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelTaskCreator Type="url" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>

                        <div className="dashboard-in  ">

                            <DashboardHeader title="Edit Task" toggleSidebar={toggleClass} />
                            <div className="row">
                                <div className="col-12 pe-0">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" style={{ padding: "15px 18px 0 18px" }} >
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" >Task Details</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="second">Applications</Nav.Link>
                                            </Nav.Item> */}

                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth">Reviews</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Submissions</Nav.Link>
                                            </Nav.Item>

                                        </Nav>


                                        <div className="setting_right_side_wrap">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    {/* <CreatorTaskDetails /> */}
                                                    <CreateNewTask />
                                                    {/* <EditCreatorTask /> */}
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="second">
                                                    <CreatorTaskApplications />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="fourth">
                                                    <CreatorTaskReviews />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    <CreatorTaskSubmissions />
                                                </Tab.Pane>


                                            </Tab.Content>
                                        </div>
                                    </Tab.Container >
                                    {/* <DashboardFooter /> */}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default CreatorNavDetails

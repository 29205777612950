import React, { useState, useEffect } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel'
import listImg from '../../../images/PPImage.png'
import usd from '.././../../images/icon/CurrencyDollar.svg'
import { BsCalendar4Event } from "react-icons/bs";
import { IoLocationOutline, IoPersonCircleOutline, IoSearchOutline } from 'react-icons/io5'
import { IoIosHeartEmpty, IoIosStarOutline } from 'react-icons/io';
import { TbPointFilled } from "react-icons/tb";
import Submission from '../../../images/icon-2/Submission.svg'
import application from '../../../images/icon-2/application.svg'
import CursorClick from '../../../images/icon-2/CursorClick.svg'
import MoneyWavy from '../../../images/icon-2/MoneyWavy.svg'
import click from '../../../images/icon-2/CheckCircle.svg'
import Xeon from '../../../images/icon-2/XCircle.svg'
import { FaSpinner } from 'react-icons/fa';
import AdminAPproveModal from '../AdminModal/AdminApproveModal';
import AdminRejectModal from '../AdminModal/AdminRejectModal';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { MdOutlineEdit } from 'react-icons/md';
import { GoDuplicate } from "react-icons/go";
import { useDispatch } from 'react-redux';
import CusstomPagination from '../../Common/CusstomPagination';
import { FetchAdminManager } from '../../../Redux/Actions/AdminManagerNavAction';
import { Dropdown } from 'primereact/dropdown';
const AdminAllTasks = () => {
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })

    const [filteredData, setFilteredData] = useState([])
    const [data, setData] = useState([])
    const navigation = useNavigate()
    const [searchInput, setSearchInput] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');
    const [producatId, setProducatId] = useState('')
    const [showModel, setShowModel] = useState(false)
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const [showModel1, setShowModel1] = useState(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)
    const navigate = useNavigate();
    const [totalLength, setTotalLength] = useState(0);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: Number(limit?.code) || 20
    });
    const handleSort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    const fetchdata = () => {
        setLoader({
            ...loader,
            fetch: true

        })
        let formData = {
            "page": pagination.currentPage,
            "limit": limit.code
        }
        dispatch(FetchAdminManager("fetch-tasklist", formData, loader, setLoader, setData))
    }

    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data?.tasklist)
            setTotalLength(data?.total_count)

        } else {
            const filterSerach = data?.tasklist?.filter(item =>

                item.title.toLowerCase().includes(searchInput.toLowerCase())

            );
            setFilteredData(filterSerach)
        }

    }, [data?.tasklist, searchInput])


    const handleAccept = (pId) => {

        handleShow()
        setProducatId(pId)
    }
    const handleDecline = (pId) => {
        handleShow1()
        setProducatId(pId)
    }
    const handleview = (slug) => {

        navigation("/admin/Task/details", { state: { "slug": slug } })
    }

    useEffect(() => {
        fetchdata()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })

    }, [pagination.currentPage, limit?.code])
    const calculateDateDifference = (createdDate) => {
        const now = new Date();
        const created = new Date(createdDate);
        const diffInMs = now - created;

        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInDays > 0) {
            return `${diffInDays} days ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInMinutes} minutes ago`;
        }
    };


    return (
        <>
            {
                loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4" style={{ height: "100vh" }}><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                    <ScrollPanel className="custombar2 creator_task content-area">
                        <div className="content-area-in task_final pt-0">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="main_task_details  pb-2">
                                            <div className="do_task_wrapper ">
                                                <div className="table-bar mb-4">
                                                    <div className="table-bar-left">
                                                        <div className="table-bar-icon">
                                                            <div className="table-nav">
                                                                <div className="profile-menu">

                                                                </div>
                                                                {/* <div className="profile-menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet
                                                                    </li>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet.
                                                                    </li>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> */}
                                                            </div>


                                                            <ul>
                                                                <li title="Sort by Date" onClick={handleSort}>
                                                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="table-bar-right">
                                                        <div className='d-flex align-items-center gap-3 bar-btns'>
                                                            <div className='task-dropdown' style={{ width: "40%" }}>
                                                                <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                                                    placeholder="Select a Limit" className="rounded-2 border-0" />
                                                            </div>
                                                            <div className="header-search">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Search"
                                                                    name="searchInput"
                                                                    value={searchInput}
                                                                    onChange={(e) => setSearchInput(e.target.value)}

                                                                />

                                                                <span className="left-icon"><IoSearchOutline
                                                                /></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                {
                                                    filteredData?.length > 0 ? filteredData?.map((curElem, i) => {

                                                        return (

                                                            <div className="task_section mt-3" key={i}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className="task_list_wrap flex-wrap flex-md-row align-items-center w-100">
                                                                        <div className="task_list_img">
                                                                            <img src={curElem?.profile ? curElem.profile : "https://backend.dotasks.in/public/uploads/profile/user.png"} alt="" />
                                                                        </div>
                                                                        <div className="list_head_wrapper">
                                                                            <div className="list_head">

                                                                                <h3>{curElem.title}</h3>
                                                                                <span><TbPointFilled style={{ fontSize: "10px" }} /> {calculateDateDifference(curElem.created)}</span>
                                                                                <div className='earn_status card-badges ' style={{ backgroundColor: curElem.status === "1" ? "rgb(222, 248, 238)" : curElem.status === "0" ? "rgb(255, 243, 205)" : "rgb(248, 215, 218)", color: curElem.status === "1" ? "rgb(74, 167, 133)" : curElem.status === "0" ? "rgb(133, 100, 4)" : "rgb(114, 28, 36)" }}>{curElem.status === "1" ? "Approved" : curElem.status === "0" ? "Pending" : "Rejected"} </div>
                                                                            </div>
                                                                            <div className="list_head_bottom">
                                                                                <div className="head_info">
                                                                                    <img src={usd} alt="" />
                                                                                    <span className='me-4'>Fixed Price -  ₹{curElem.price}</span>
                                                                                    <span className='d-flex align-items-center' style={{ gap: "8px" }}><BsCalendar4Event />Due Date: {curElem.due_date}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex ms-auto btn-list-wrap flex-row flex-md-row flex-lg-column mt-3 mt-lg-0 gap-3'>
                                                                            <span className='ms-auto d-flex align-items-center view-btn' style={{ cursor: "pointer", gap: "3px" }} onClick={() => handleview(curElem?.slug)}><FiEye />
                                                                                View</span>
                                                                            <span className='d-flex align-items-center gap-1' style={{ cursor: "pointer" }} onClick={() => { navigate("/admin/create/details", { state: { "id": curElem } }) }} >
                                                                                <MdOutlineEdit />
                                                                                Edit Details
                                                                            </span>
                                                                            <span className='d-flex align-items-center gap-1' style={{ cursor: "pointer" }} onClick={() => { navigate("/admin/create/details", { state: { "id": curElem, "duplicate": "yes" } }) }} >
                                                                                <GoDuplicate style={{ fontSize: "16px" }} />

                                                                                Duplicate
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="head_para ps-0">
                                                                    <p dangerouslySetInnerHTML={{ __html: curElem.description }}>

                                                                    </p>
                                                                </div>
                                                                <div className="task_tag_wrap">
                                                                    <div className="task_tag">
                                                                        <span>{curElem.category_name}</span>

                                                                    </div>
                                                                    {/* <div className="task_tag">
                                                                <span>Online Survey</span>
                                                            </div> */}
                                                                    <div className="task_tag">
                                                                        <span>{curElem.level_name}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="d-flex justify-content-between align-content-center flex-wrap">
                                                                    <div className="task_person_info">
                                                                        <div className="task_verification">
                                                                            <img src={application} alt="" />
                                                                            <span> 50 Applications</span>
                                                                            {/* <span> </span> */}
                                                                        </div>
                                                                        <div className="task_verification">
                                                                            <img src={Submission} alt="" />
                                                                            <span>{curElem.max_allowed_submission} </span>

                                                                            <span>Submissions</span>
                                                                        </div>
                                                                        <div className="task_verification">
                                                                            <img src={MoneyWavy} alt="" />
                                                                            <span> 1500$ Spent</span>
                                                                            {/* <span> </span> */}
                                                                        </div>
                                                                        <div className="task_verification">
                                                                            <img src={CursorClick} alt="" />
                                                                            <span> 50 Clicks</span>
                                                                            {/* <span> </span> */}
                                                                        </div>

                                                                    </div>
                                                                    <div className="task_person_approve">
                                                                        <img src={click} alt="" onClick={() => handleAccept(curElem.slug)} />
                                                                        <img src={Xeon} alt="" onClick={() => handleDecline(curElem.slug)} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }) : <div className='d-flex aligm-items-center justify-content-center'> {data?.tasklist?.length === 0 ? "No Task Found" : ""}</div>
                                                }

                                                <div className='my-4'>
                                                    <CusstomPagination
                                                        pagination={pagination}
                                                        setPagination={setPagination}
                                                        totalLength={totalLength}
                                                        loader={loader.fetch}
                                                    />
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                    <AdminAPproveModal show={showModel} handleClose={handleClose} producatId={producatId} type="allTasks" fetchdata={fetchdata} />
                                    <AdminRejectModal show={showModel1} handleClose={handleClose1} producatId={producatId} type="allTasks" fetchdata={fetchdata} />

                                </div>
                            </div>
                        </div>

                    </ScrollPanel >
            }
        </>
    )
}

export default AdminAllTasks

import React from "react";
import { Link } from "react-router-dom";

const DashboardFooter = () => {

    return (
        <>
            <div className="dashboard-footer">
                <div className="dashboard-footer-left">
                    <p className="text-center">© 2024, All Rights Reserved to<a target="_blank" href="https://v2.dotasks.in/"> DoTasks</a></p>
                </div>
                <div className="dashboard-footer-right">
                    <div className="footer-link">
                        <ul>
                            <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
                            <li><a href="/Termsofservices" target="_blank">Terms of Service</a></li>
                            <li><a href="/disclaimer" target="_blank">Disclaimer</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardFooter;
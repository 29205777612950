import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";

export const fetchAchivementData = (setData,setLoader,loader) => (dispatch, getState) => {

    commonAxios("fetch-user-review", {}, dispatch,getState().auth.token)
        .then((res) => {
            if (res.status) {
                
                setData(res.data)
                //  dispatch({ type:'Fetch_Task_detail', payload: res.data });
               } 
               else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch:false
            })
        }).catch((err) => {
         console.log(err)
        
        })
}
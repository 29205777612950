import React, { useState } from 'react'
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { updateAdminCreatorMobileEmail } from '../../../Redux/Actions/AdminDashboardActions';
import DashboardFooter from '../../Common/DashboardFooter';
import { ScrollPanel } from 'primereact/scrollpanel';
import PhoneNumber from '../../Auth/PhoneNumber';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { Dropdown } from 'primereact/dropdown';
const AdminTaskerEmail = (props) => {
    const [selectedCountry, setSelectedCountry] = useState({ name: 'India', code: 'IN', phoneCode: '+91' });
    const [countries, setCountries] = useState([])
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")


    const regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const [loader, setLoader] = useState({
        fetch: false,
        email: false,
        mobile: false,
    })



    const handelEmail = () => {

        setLoader({
            ...loader,
            email: true
        })
        if (regEmail.test(email) === false) {
            dispatch(setAlert("Incorrect Email", "danger"))
            setLoader({
                ...loader,
                email: false
            })
        } else {
            setLoader({
                ...loader,
                email: true
            })
            let data = {
                "type": "email",
                "email": email,
                "id": props?.data?.id
            }

            dispatch(updateAdminCreatorMobileEmail(data, loader, setLoader))

        }
    }
    const handelMobile = () => {
        setLoader({
            ...loader,
            mobile: true
        })
        if (mobileNumber.length < 10) {
            setLoader({
                ...loader,
                mobile: false
            })
            dispatch(setAlert("Please enter a valid 10-digit mobile number", "danger"))
        } else {
            setLoader({
                ...loader,
                mobile: true
            })

            let data = {
                "type": "mobile",
                "mobile": selectedCountry.phoneCode + mobileNumber,
                "id": props?.data?.id
            }

            dispatch(updateAdminCreatorMobileEmail(data, loader, setLoader))
        }
    }
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="d-flex align-items-center ">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div className='ms-2'>{option.name} </div>

                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="d-flex align-items-center ">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div className='ms-2'>{option.name}  ({option.phoneCode})</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };
    return (
        <>
            <ScrollPanel className="custombar2 content-area manage-users">
                <div className='creator_new_task h-100 '>
                    <div className='account-block phone-emails mb-3'>
                        <div className="submission_wrap submission_bg mt-3 mb-3">
                            <label for="" className="form-label">Email</label>
                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div class="modal-button-bottom" style={{ background: "unset" }}><button class="theme-btn px-4" type="button" onClick={handelEmail} >Update{loader.email ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null} </button></div>
                        <div className='phone-emails'>
                            <div className="submission_wrap submission_bg mt-3 mb-3">
                                <label for="" className="form-label">Mobile No.</label>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <Dropdown
                                            style={{
                                                // border: error?.status === false && error?.data?.type === "mobile" ? "1px solid red" : "",
                                                borderRight: "0",
                                                // borderRadius: "8px",
                                                borderTopRightRadius: "0",
                                                borderBottomRightRadius: "0"
                                            }}

                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.value)}
                                            options={countries}
                                            optionLabel="name"
                                            placeholder="Country"
                                            valueTemplate={selectedCountryTemplate}
                                            itemTemplate={countryOptionTemplate}
                                            className="w-full md:w-14rem border-0"
                                            panelFooterTemplate={panelFooterTemplate}
                                            dropdownIcon={(opts) => {
                                                return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                                            }} />
                                    </div>
                                    <input type="tel" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Mobile Number" name="mobileNumber" value={mobileNumber}
                                        onChange={(e) => {
                                            const newValue = e.target.value.replace(/[^0-9]/g, '');

                                            setMobileNumber(newValue);
                                        }}

                                        maxLength={10}
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                    />
                                </div>
                            </div>
                            <div class="modal-button-bottom" style={{ background: "unset" }}><button class="theme-btn px-4" type="button" onClick={handelMobile}>Update{loader.mobile ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null} </button></div>

                        </div>
                    </div>
                </div>
                <PhoneNumber setCountryInput={setCountries} />
            </ScrollPanel>
 
        </>
    )
}

export default AdminTaskerEmail

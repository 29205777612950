import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";


export const fetchAdminlistAction = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("fetch-admin-user", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data)

            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const addAdminuserAction = (data, setLoader, loader, handleClose, fetchData) => (dispatch, getState) => {

    commonAxios("add-user", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));

            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            fetchData()
            handleClose()
            setLoader({
                ...loader,
                addUser: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const taskSubmissionDetailsAdmin = (formData, setData, loader, setLoader) => (dispatch, getState) => {

    commonAxios("view-task-submission", formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data)

            }
            else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}

export const fetchAdminTaskPrefanceAction = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("category-list", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data)

            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}


export const addAdminTaskPrefanceAction = (data, setLoader, loader, fetchData, handleClose) => (dispatch, getState) => {

    commonAxios("add-category", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));
                fetchData()

            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose()
            setLoader({
                ...loader,
                add: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const editAdminTaskPrefanceAction = (data, setLoader, loader, handleClose1, fetchData) => (dispatch, getState) => {

    commonAxios("edit-category", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));
                fetchData()

            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose1()
            setLoader({
                ...loader,
                edit: false
            })

        }).catch((err) => {
            console.log(err)

        })
}
export const deleteAdminTaskPrefanceAction = (data, setLoader, loader, handleClose2, fetchData) => (dispatch, getState) => {

    commonAxios("delete-category", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));

                fetchData()
            }
            else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose2()
            setLoader({
                ...loader,
                delete: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
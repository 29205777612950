import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import OTPInput, { ResendOTP } from "otp-input-react";
import { setAlert } from '../../Redux/Actions/AlertActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import varifImg from '../../images/social icon/EnvelopeSimple.svg'
import lessThen from '../../images/icon/lessthen.svg'
import { FaMobileAlt } from "react-icons/fa";


import SetPasswordModal from './SetPasswordModel';
import { ForgetOtpVerify, ForgetReSendOtpAction, otpMobileLoginVerfication } from '../../Redux/Actions/AuthActions';
import VerifcationSuccess from './VerificationSuccess';

// import { FaSpider, FaSpinner } from "react-icons/fa";

const MobileLogInOtpModal = (props) => {

    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()
    const [loader, setLoader] = useState(false)


    const dispatch = useDispatch()

    const [isDisabled, setIsDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false)
    const handleverify = () => {

        setLoader(true)
        if (OTP.length < 4) {

            setLoader(false)
            dispatch(setAlert("Please enter OTP", "danger"))
        }
        else {
            let data = {
                "mobile": props.mobile,
                "otp": OTP,
                "session_id": props.session_id
            }

            dispatch(otpMobileLoginVerfication(data, setLoader, navigation, setShowModal, setOTP))



        }

    }
    // const handleResendOtp = () => {
    //     setIsDisabled(true)
    //     setTimeout(() => {
    //         setIsDisabled(false);
    //     }, 20000)
    //     let fromData = {

    //         "hash": props?.session_id
    //     }

    //     dispatch(ForgetReSendOtpAction(fromData))

    // }
    return (
        <>
            <Modal className="theme-modal forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span> */}
                        <h3 className="m-auto" style={{ fontSize: "30px" }}>Verify Your Mobile Number</h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span> */}

                    </div>

                    <div className="modal-card mt-4 ">
                        <div className='otp-wrapper d-flex flex-column align-items-center mb-4'>
                        <span className='d-flex align-items-center justify-content-center mb-3' style={{ backgroundColor: "#fbe4e4", borderRadius: "50px", width: "60px", height: "60px", padding: "10px" }}>
                        <FaMobileAlt 

                                                style={{ fontSize: "35px", color:"#000" }} />
                                                </span>
                            <h3 style={{ fontSize: "20px" }}>Check your Mobile box</h3>
                            <p className='' style={{ fontSize: "14px", textAlign: "center" }}>A verification code has been sent to your Mobile Number. Please enter the code received in your Mobile

                            </p>
                            <h5 style={{ fontSize: "18px" }}>{props.mobile}</h5>

                        </div>
                        <div className="d-flex align-items-center justify-content-center m-auto modal-otp flex-column text-center" style={{ width: "50%" }}>
                            <div>

                                <OTPInput className="p-0" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                {/* <div className="resend_code mt-2 text-center">
                                    <button type="submit" className=" btn m-auto" onClick={handleResendOtp} disabled={isDisabled}>Resend OTP </button>
                                </div> */}
                            </div>
                        </div>

                        <div className="link_btn mb-2 text-end">
                            <button className='theme-btn saveBtn' type="submit" style={{ padding: "12px 24px" }} onClick={handleverify}>
                                Verify  {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal >
            <VerifcationSuccess show={showModal} close={handleClose} type="Mobile" />
        </>
    )
}

export default MobileLogInOtpModal

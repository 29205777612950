import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../Common/Pagination";
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from "primereact/rating";

function ReviewsReceivedTabs(props) {
    const [reviewData, setReviewData] = useState([])

    useEffect(() => {
        if (props.data) {
            setReviewData(props?.data)
        }
        else {
            setReviewData([])
        }
    }, [props.data])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = reviewData.slice(indexofFirstTodo, indexofLastTodo);






    return (
        <>

            <div className="account-block ex mb-5">
                <h6 className="d-flex align-items-center pb-3">
                    {/* <img src={require("../../../images/icon/star2.svg").default} alt="" /> <span className="ps-2">5.0 - 7 reviews</span> */}
                    <Rating className='rating-color pe-2' value={props?.averagerating || 0} readOnly cancel={false} allowHalf />
                    <div style={{ fontSize: "16px" }} className="ps-2">{props?.averagerating || 0} - {props?.totalreview || 0} reviews </div>
                </h6>


                <div className="review-list mt-2">
                    <ul>
                        {
                            currentTodo?.length > 0 ? currentTodo?.map((curElem, i) => {
                                const reviewRating = JSON.parse(curElem.reviewratings)
                                return (
                                    <div className="border-bottoms" key={i}>
                                        <li>
                                            <div className="review-list-single">
                                                <div className="review-list-single-top">
                                                    <div className="review-list-single-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                                                    <div className="review-list-single-txt">
                                                        <h4 style={{marginBottom:"13px"}}>{curElem.name}</h4>
                                                        <p style={{lineHeight:".9"}}>{curElem.title}</p>
                                                        <h6>{curElem.created}</h6>
                                                    </div>
                                                </div>
                                                <p className="pt-3">{curElem.message}</p>
                                            </div>

                                        </li>
                                        <div className="rev-card mb-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Availability</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">

                                                                <ProgressBar value={20 * reviewRating.availability} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.availability}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Cooperation</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * reviewRating.copperation} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.copperation}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Communication</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * reviewRating.communication} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.communication}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Quality of work</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * reviewRating.work_quality} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.work_quality}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Skills</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * reviewRating.skillset} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.skillset}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="rev-card-single">
                                                        <h6>Adhere to deadline</h6>
                                                        <div className="rev-card-stat">
                                                            <div className="rev-card-percentage">
                                                                <ProgressBar value={20 * reviewRating.deadline} style={{ height: "20px" }} />
                                                            </div>
                                                            <div>{reviewRating.deadline}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                )
                            }) : <div className="d-flex justify-content-center align-items-center m-auto">No Review Received</div>
                        }


                    </ul>
                </div>
                <Pagination

                    listData={reviewData}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentTodo={currentTodo}
                    listArr={reviewData}
                    loader={props.loader.fetch}
                />
                {/* <div className="text-center mt-4">
                    <button className="theme-btn white">Show all 12 reviews</button>
                </div> */}

            </div>


        </>
    )
}

export default ReviewsReceivedTabs;
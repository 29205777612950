import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import TrainingNav from "./TrainingNav";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string"
import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchVideos } from "../../Redux/Actions/HelpActions";
import { FaSpinner } from "react-icons/fa";
import SidePanelAdmin from "../Common/SidePanelAdmin";
import SidePanelTaskCreator from "../Common/SidePanelTaskCreator";

const HelpDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const articleId = queryString.parse(location.search).id;
    const article = useSelector(state => state.help.article);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <div className="dashboard creator_new_task privacy-footer container-fluid overflow-hidden">
                <div className="row">

                    <div className="col-lg-2 col-md-3 col-sm-4 col-12 pe-0 ps-0">
                        {auth?.user?.role === "Tasker" ? <SidePanel /> : auth?.user?.role === "admin" ? <SidePanelAdmin /> : <SidePanelTaskCreator />}
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-8 col-12 pt-0 pb-0 ps-0">
                        <div className="dashboard-in  ">
                            <DashboardHeader title="Help" />
                            <div className="row">
                                <ScrollPanel className="custombar2 content-area">
                                    <div className="content-area-in">
                                        <div className="page-title">Upgrade</div>
                                        <div className="containerN">
                                            <Tab.Container id="left-tabs-example" >
                                                <div className="upgrade-tab large">
                                                    <div className="upgrade-tab-left">
                                                        <TrainingNav
                                                            article={article}
                                                            articleId={articleId}
                                                        />
                                                    </div>
                                                    <div className="upgrade-tab-right">
                                                        <Tab.Content>
                                                            {article.length > 0 ?
                                                                article.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <Tab.Pane className='fade show active'>
                                                                                {+curElem.id === +articleId ?
                                                                                    curElem.items.map((item, ind) => {
                                                                                        return (
                                                                                            <div className="upgrade-tab-in" key={ind}>
                                                                                                <Link to={`/support-article?id=${articleId}&art_id=${item.id}`}>
                                                                                                    <div className="upgrade-tab-block">
                                                                                                        <h6> {item.title}</h6>
                                                                                                        <hr />
                                                                                                        <div className="upgrade-img">
                                                                                                            <img src={item.feature_url} className="img-fluid" alt="" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        )
                                                                                    }) : ""}
                                                                            </Tab.Pane>
                                                                        </React.Fragment>
                                                                    )
                                                                }) :

                                                                <div className="spinner d-flex justify-content-cneter" style={{ width: '100%', height: "900px" }}>
                                                                    <div className="spinner_tag">
                                                                        <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </ScrollPanel>
                                <div>
                                    <DashboardFooter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpDetails;
import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useDispatch } from 'react-redux';

const SummerNote = ({ state, setState, editing }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const editorRef = useRef(null);

    useEffect(() => {

        if (state.description !== undefined) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [state.description]);

    const handleChange = (val) => {
        setState({
            ...state,
            description: val
        });
    };

    const editorConfiguration = {
        toolbar: ['heading', '|', 'fontSize', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo']
    };

    return (
        <>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <CKEditor
                    editor={DecoupledEditor}
                    data={state.description || ''}
                    config={editorConfiguration}
                    disabled={!editing}
                    onReady={editor => {

                        if (!editorRef.current) {
                            editorRef.current = editor;

                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                        }
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChange(data);
                    }}
                    onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                            editorRef.current.ui.view.toolbar.element.remove();
                        }
                    }}
                />
            )}
        </>
    );
};

export default SummerNote;

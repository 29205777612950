import React, { useState } from 'react'
import TitleBar from '../../../Common/TitleBar';
import SidePanelAdmin from '../../../Common/SidePanelAdmin';
import DashboardHeader from '../../../Common/DashboardHeader';
import NavAdminCreateTask from './NavAdminCreateTask';





const AdminCreateTask = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <TitleBar title="Task" />
            <div className="dashboard creator_task_container container-fluid overflow-hidden tab-height">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin type="url" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in ">

                            <DashboardHeader toggleSidebar={toggleClass} title="Tasks" />

                            <NavAdminCreateTask />

                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default AdminCreateTask

import React, { useState, useEffect } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel'
import { LuDownload } from 'react-icons/lu'
import event from '../../../images/icon/events.svg'
import { Dropdown } from 'primereact/dropdown';
import { IoSearchOutline } from 'react-icons/io5'
import Pagination from '../../Common/Pagination'
import { FaSpinner } from 'react-icons/fa'
import CusstomPagination from '../../Common/CusstomPagination'
import { FetchAdminPayOut } from '../../../Redux/Actions/AdminPayOutAction'
import { useDispatch } from 'react-redux'

const AdminPayoutHistory = () => {
    const [searchInput, setSearchInput] = useState('')
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const dispatch = useDispatch()
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])
    const [totalLength, setTotalLength] = useState(0);
    const [loader, setLoader] = useState({
        fetch: false
    })
    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data?.pending_request) {
            if (searchInput) {

                const newFilteredData = filteredData.filter(val => {

                    const name = (val.name || '').toLowerCase();
                    const amount = String(val.amount || '').toLowerCase();
                    const id = String(val.id || '').toLowerCase();
                    const searchTerm = searchInput.toLowerCase();

                    return name.includes(searchTerm) ||
                        amount.includes(searchTerm) ||
                        id.includes(searchTerm);
                }
                );




                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data.pending_request);
                setTotalLength(data?.total_count)
            }
        }
    }, [searchInput, data?.pending_request]);


    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 20
    });
    const fetchdata = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1,
            "limit": limit.code
        }
        dispatch(FetchAdminPayOut("fetch-payout-history", formData, loader, setLoader, setData))
    }
    useEffect(() => {
        fetchdata()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })
    }, [pagination.currentPage, limit?.code])

    return (
        <>
            {
                loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div> :

                    <ScrollPanel className="custombar2 earnings_payouts content-area">

                        <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>

                            <div className="account-block bg-transparent ">
                                <div className="table-bar">
                                    <div className="table-bar-left">
                                        <div className="table-bar-icon">
                                            <div className="table-nav">
                                                <div className="profile-menu">

                                                </div>
                                                {/* <div className="profile-menu">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet
                                                    </li>
                                                    <li className="drop-list">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                name="agentType"
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                        dolor sit amet.
                                                    </li>


                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                            </div>


                                            <ul>
                                                <li title="Sort by Date" onClick={handlesort}>
                                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="table-bar-right">
                                        <div className='d-flex align-items-center gap-3 bar-btns'>
                                            <div className='task-dropdown' style={{ width: "40%" }}>
                                                <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                                    placeholder="Select a Limit" className="rounded-2 border-0" />
                                            </div>
                                            <div className="header-search">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    name="searchInput"
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}

                                                />

                                                <span className="left-icon"><IoSearchOutline
                                                /></span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive badge-mob earnings_table mt-3">
                                    <table className="table theme-table">
                                        <thead>
                                            <tr>
                                                {/* <th>
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </th> */}
                                                <th>Tansactoion ID</th>

                                                <th>Description</th>
                                                <th>Date</th>
                                                <th>Payout Amount</th>
                                                <th>Remaining Balance</th>
                                                <th className='text-center'>Status</th>
                                                <th className='text-center'> Paid Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.length > 0 ? filteredData.map((curElem, i) => {
                                                const bankDetails = JSON.parse(curElem.payment_detail || null)



                                                return (
                                                    <tr key={i}>
                                                        {/* <td data-label="">
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </td> */}

                                                        <td data-label="Transaction ID">{curElem.id}</td>
                                                        <td data-label="Description">Bank Transfer for ₹{curElem.amount} form {curElem?.payment_mode}</td>
                                                        <td data-label="Date"><img src={event} alt="" />{curElem.created}</td>
                                                        <td data-label="Payout Amount">₹{curElem.amount}</td>
                                                        <td data-label="Remaining Balance">₹{curElem.balance}</td>
                                                        <td data-label="Status">
                                                            {curElem.status === '2' ? <div className='earn_status complete m-auto badges' style={{ width: "fit-content", background: "#d4edda", color: "#155724" }}>Approved</div> : curElem.status === '3' ? <div className='earn_status m-auto' style={{ width: "fit-content", background: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status m-auto' style={{ width: "fit-content", background: "rgb(255, 191, 0)" }}>Pending</div>}
                                                        </td>

                                                        <td data-label="Paid Status">
                                                            {curElem.is_payed === '1' ? <div style={{ width: "fit-content" }} className='earn_status complete m-auto'>Paid</div> : <div className='earn_status m-auto badges' style={{ width: "fit-content", background: "#fff3cd", color: "#856404" }}>UnPaid</div>}
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <td className='text-center py-3' colSpan={7}>{data.length === 0 ? "No tasks Found" : ""}</td>}


                                        </tbody>
                                    </table>
                                    <CusstomPagination
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        totalLength={totalLength}
                                        loader={loader.fetch}
                                    />
                                </div>
                            </div>


                        </div>

                    </ScrollPanel >
            }
        </>
    )
}

export default AdminPayoutHistory

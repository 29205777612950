import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import AdminAllTasks from './AdminAllTasks'
import ApproveTasks from './ApproveTasks'
import AdminTaskSubmissions from './AdminTaskSubmissions'
import DashboardHeader from '../../Common/DashboardHeader'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import { useScrollTrigger } from '@mui/material'
import { useDispatch } from 'react-redux'
import { FetchAdminManager } from '../../../Redux/Actions/AdminManagerNavAction'
import TitleBar from '../../Common/TitleBar'
import DashboardFooter from '../../Common/DashboardFooter'
import { useNavigate } from 'react-router-dom'
import PendingTasks from './PendingTasks'
import RejectedTasks from './RejectedTasks'
const NavTaskManager = () => {
    const [isActive, setIsActive] = useState(true);
    const navigation = useNavigate()
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <TitleBar title="Tasks" />
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in  ">

                            <DashboardHeader toggleSidebar={toggleClass} title="Manage Tasks" />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="p-3 ps-4">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">All Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Approve Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="4">Pending Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="5">Rejected Tasks</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                {/* <Nav.Link eventKey="third">Approve Submissions</Nav.Link> */}
                                            </Nav.Item>
                                            <div className='theme-btn ms-lg-auto mt-3 mt-md-0 nav-theme-btn me-4' onClick={() => { navigation("/admin/create/task") }}>
                                                Create Task
                                            </div>
                                        </Nav>

                                        <div className="setting-right-side-wrap admin-custom-footer">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <AdminAllTasks />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <ApproveTasks />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                                    {/* <AdminTaskSubmissions data={managerData.task_submission} loader={loader} fetchdata={fetchdata} /> */}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="4">
                                                    <PendingTasks />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="5">
                                                    <RejectedTasks />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                        <DashboardFooter />
                    </div>
                </div>

            </div >

        </>
    )
}

export default NavTaskManager

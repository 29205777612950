import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack } from "react-icons/io5";

function VerificationThirdStep() {
    return(
        <>
          <div className="verification-wrap">
            <div className="verification-outer">
                   <div className="verification-title">
                        <button className="nextBtn"><IoChevronBack /></button>
                        <h2>Add phone number</h2>
                        <button className="nextBtn"><IoClose /></button>
                    </div>
                <div className="verification-main">
                    <div className="input-style-wrap">
                        <input className="input-style" type="text" placeholder="Enter your phone number" />
                    </div>
                    <div className="input-style-wrap">
                        <button className="theme-btn full large">Next</button>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default VerificationThirdStep;
import React, { useEffect, useState } from 'react';

import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';

import { Link, useLocation } from 'react-router-dom';

import PersonalDetailsTabs from "./PersonalDetailsTabs";
import OngoingTasksTabs from './OngoingTasksTabs';
import CompletedTasksTabs from './CompletedTasksTabs';
import EarningsTabs from './EarningsTabs';
import PaymentMethodsTabs from './PaymentMethodsTabs';
import ReviewsTabs from './ReviewsTabs';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import { useDispatch } from 'react-redux';
import { activeAccountUserDetails, banAccoutUserDetails, FetchAdminTaskerUserDetails } from '../../../Redux/Actions/AdminManagerUserAction';
import ProfileInfo from './ProfileInfo';
import AdminBanModel from '../AdminModal/AdminBanModel';
import AdminActiveModel from '../AdminModal/AdminActiveModel';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from 'react-icons/fa';
import AdminTaskerPassword from './AdminTaskerPassword';
import AdminTaskerEmail from './AdminTaskerEmail';
import AdminKycTasker from './AdminKycTasker';
import AdminSecurity from './AdminSecurity';
import AdminTaskerSocialLink from './AdminTaskerSocialLink';


function ProfileDetailsAdmin() {
   const location = useLocation()

   const userId = location?.state?.slug
   const dispatch = useDispatch()
   const [userData, setUserData] = useState({})
   const [loader, setLoader] = useState({
      fetch: true,
      active: false,
      banAccount: false
   })
   const [reason, setReason] = useState('')
   const [showModel, setShowModel] = useState(false)
   const [showModel1, setShowModel1] = useState(false)
   const [isActive, setIsActive] = useState(false);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);
   const toggleNotification = () => {
      setIsActive2(!isActive2);
   };

   const fetchDataTasker = () => {
      let formData = {
         "id": userId
      }
      dispatch(FetchAdminTaskerUserDetails(formData, loader, setLoader, setUserData))

   }
   useEffect(() => {
      if (userId) {
         fetchDataTasker()
      }

   }, [userId])
   const handleShow = () => setShowModel(true)
   const handleClose = () => setShowModel(false)
   const handleShow1 = () => setShowModel1(true)
   const handleClose1 = () => setShowModel1(false)


   const handleBanAccount = () => {
      setLoader({
         ...loader,
         banAccount: true

      })
      let data = {
         id: userData?.profiledata?.id
      }
      dispatch(activeAccountUserDetails(data, loader, setLoader, handleClose1, fetchDataTasker))
   }
   const handleActive = () => {
      setLoader({
         ...loader,
         active: true

      })
      let data = {
         id: userData?.profiledata?.id,
         "ban_reason": reason
      }
      if (reason === '') {
         setLoader({
            ...loader,
            active: false

         })
         dispatch(setAlert("Reason  is requried", "danger"))
      }
      else {
         dispatch(banAccoutUserDetails(data, loader, setLoader, handleClose, fetchDataTasker))
      }

   }

   return (
      <>
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelAdmin type="creator" />
            {
               loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div>

                  : <div className="dashboard-in">
                     <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="User" />
                     <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in tab-style">
                           <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                              <div className="tab-bar flex-wrap">
                                 <Nav variant="pillsb">
                                    <Nav.Item><Nav.Link eventKey="tab-1">Profile Details</Nav.Link></Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="7" >Email & Phone Number</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="8" >Password</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="9" >KYC</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="10" >Security</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                       <Nav.Link eventKey="11" >Social</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-2">Ongoing Tasks</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-3">Completed Tasks</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-4">Earnings</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-5">Payment methods</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-6">Reviews</Nav.Link></Nav.Item>
                                 </Nav>

                                 <div className="tab-bar-right">
                                    <ul>

                                       {
                                          userData?.profiledata?.isSuspended === "0" ? <li className='mt-2 mt-lg-0'><a className='red' onClick={handleShow} style={{ cursor: "pointer" }}>Ban Account</a></li> : <li><a className='red' onClick={handleShow1} style={{ cursor: "pointer" }}>Activate</a></li>
                                       }

                                    </ul>
                                 </div>

                              </div>
                              {/* <div className='mt-3'>
                                 <ProfileInfo data={userData.profiledata} />
                              </div> */}

                              <Tab.Content>
                                 <Tab.Pane eventKey="tab-1">
                                    <PersonalDetailsTabs data={userData.profiledata} loader={loader} />
                                 </Tab.Pane>

                                 <Tab.Pane eventKey="tab-2">
                                    <OngoingTasksTabs data={userData.ongoing_task} loader={loader} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="tab-3">
                                    <CompletedTasksTabs data={userData.completedtask} loader={loader} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="tab-4">
                                    <EarningsTabs data={userData.payout} loader={loader} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="tab-5">
                                    <PaymentMethodsTabs data={userData.payment_method} loader={loader} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="tab-6">
                                    <ReviewsTabs data={userData.review} loader={loader} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="7">
                                    <AdminTaskerEmail data={userData.profiledata} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="8">
                                    <AdminTaskerPassword data={userData.profiledata} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="9">
                                    <AdminKycTasker data={userData.profiledata} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="10">
                                    <AdminSecurity data={userData.profiledata} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="11">
                                    <AdminTaskerSocialLink data={userData.profiledata} />
                                 </Tab.Pane>
                              </Tab.Content>
                           </Tab.Container>
                        </div>
                        <AdminBanModel show={showModel} handleClose={handleClose} setReason={setReason} reason={reason} loader={loader} handleBanAccount={handleActive} />
                        <AdminActiveModel show={showModel1} handleClose={handleClose1} loader={loader} handleBanAccount={handleBanAccount} />
                     </ScrollPanel>
                     <DashboardFooter />
                  </div>
            }
         </div>

      </>
   )
}
export default ProfileDetailsAdmin;
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";


export const fetchAdminDashboardAction = (setSubmissionData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("admin-dashboard", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setSubmissionData(res.data)

            }
            else {

                console.log(res)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const fetchAdminDashboardActionMessage = (setSubmissionData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("fetch-submission-status", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setSubmissionData(res.data)

            }
            else {

                console.log(res)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const fetchSumissionDetails = (fromData, setData, loader, setLoader, setTask_detail, setUser_detail) => (dispatch, getState) => {

    commonAxios("fetch-submission-proof", fromData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data.proof)
                setTask_detail(res.data.task_detail[0])
                setUser_detail(res.data.user_detail[0])
            }
            else {
                setData([])

            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
        })
}
export const onFetchCreatordetailAdmin = (fromData, setData, loader, setLoader,) => (dispatch, getState) => {

    commonAxios("admin-task-details", fromData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data[0])

            }
            else {

                console.log(res)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            //  setLoader(false)
        })
}
export const updateAdminCreatorMobileEmail = (data, loader, setLodder) => (dispatch, getState) => {
    commonAxios("update-user-details", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }

            if (data?.type === "email") {
                setLodder({
                    ...loader,
                    email: false,
                })
            }
            else {
                setLodder({
                    ...loader,
                    mobile: false,
                })
            }

        }).catch((err) => {
            console.log(err)
        })
}

export const onUpdatePasswordCreatorAdmin = (data, setLoader) => (dispatch, getState) => {
    commonAxios("update-user-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}
export const onUpdateTaskDetails = (data, setLoader) => (dispatch, getState) => {
    commonAxios("edit-task", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const onKycUpdateCreatorAdmin = (data, loader, setLoader) => (dispatch, getState) => {
    let config = {
        headers: {
            'Authorization': getState().auth.token,
            "Content-Type": "multipart/form-data",
        }
    }

    commonAxios("update-user-kyc", data, dispatch, getState().auth.token, config)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                update: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                update: false
            })
            console.log(err)
            // dispatch(setAlert(err.msg, "danger"))
        })
}
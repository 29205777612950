import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoCloseOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { submitUserReview } from '../../../Redux/Actions/TaskCreatorAction';
import { FaSpinner } from 'react-icons/fa';
const ReviewModal = (props) => {
    const dispatch = useDispatch()
    const [message, setMessage] = useState("")
    const [reviewratings, setReviewratings] = useState("0")
    const [loader, setLoader] = useState(false)
    const [rating, setRating] = useState({
        "availability": 0,
        "communication": 0,
        "copperation": 0,
        "work_quality": 0,
        "deadline": 0,
        "skillset": 0,
    })
    const handleRating = (newRating, name) => {
        setRating(
            {
                ...rating, [name]: newRating
            }
        )

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            "id": props.id,
            "message": message,
            "rating": rating,
            "reviewratings": reviewratings
        }
        dispatch(submitUserReview(data, setLoader, props.handleModalFalse))
    }
    return (
        <>
            <Modal className="theme-modal small-modal" show={props.handleModalShow} onHide={props.handleModalFalse} centered>
                <Modal.Body>
                    <span className="modal-close" onClick={props.handleModalFalse}><IoCloseOutline /></span>
                    <div className="text-center">
                        <img src={require("../../../images/icon/chat.svg").default} alt="" />
                        <h3 className="pt-4">Submit Review</h3>
                        <p className="pt-2">You can also provide an overall rating here and write a detailed review describing your experience working with the Client</p>
                        <hr className="mt-4" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="account-block">
                            <h6>Feedback to the client</h6>
                            <div className="feedBlock">
                                <div className="feedBlock-left">availability of Requirements</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.availability}
                                        changeRating={handleRating}
                                        starRatedColor="gold"
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='availability'

                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">communication</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.communication}
                                        starRatedColor="gold"
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        changeRating={handleRating}
                                        name='communication'

                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">copperation Feedback</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.copperation}
                                        starRatedColor="gold"
                                        starDimension="20px"
                                        changeRating={handleRating}
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='copperation'


                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">work_quality</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.work_quality}
                                        changeRating={handleRating}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='work_quality'
                                        starRatedColor="gold"

                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">deadline</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.deadline}
                                        changeRating={handleRating}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='deadline'
                                        starRatedColor="gold"

                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Clear instructions</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.skillset}
                                        changeRating={handleRating}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='skillset'

                                    />
                                </div>
                            </div>
                            <hr className="mt-4" />
                            <h6>Write a review</h6>
                            <textarea className="input-style mt-2" name="message" value={message} onChange={(e) => setMessage(e.target.value)} id="" rows="5"></textarea>
                            <div className="text-center mt-2">
                                <button className="theme-btn" type="submit">Submit Review {loader ? <FaSpinner className='spin ms-1' style={{ color: "#fff" }} /> : ""}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ReviewModal

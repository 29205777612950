import React, { useState } from 'react';

import SidePanelAdmin from '../../Common/SidePanelAdmin';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import TitleBar from '../../Common/TitleBar';
import AdminUsersTabs from '../Profile-Settings/AdminUsersTabs';
import TaskPreferenceTabs from '../Profile-Settings/TaskPreferenceTabs';
import TaskAssign from './TaskAssign';
import AssignTaskModal from './AssignTaskModal';
import AssignNameList from './AssignNameList';

function AssignTask() {

    const [isActive, setIsActive] = useState(false);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const [isActive2, setIsActive2] = useState(false);
    const toggleNotification = () => {
        setIsActive2(!isActive2);
    };

    return (
        <>
            <TitleBar title="Assign Task" />
            <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
                <SidePanelAdmin />
                <div className="dashboard-in admin-roles-footer">
                    <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Assign Task" />
                    <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in tab-style">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="tab-bar">
                                    <Nav variant="pillsb">
                                        {/* <Nav.Item><Nav.Link eventKey="second">Admin User</Nav.Link></Nav.Item> */}
                                        <Nav.Item><Nav.Link eventKey="first">Tasks Assign</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="second"> Assign Tasks</Nav.Link></Nav.Item>

                                    </Nav>

                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="second">
                                        {/* <AdminUsersTabs /> */}
                                        <AssignNameList />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="first">
                                        <TaskAssign />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </ScrollPanel>
                    <DashboardFooter />
                </div>

            </div>

        </>
    )
}
export default AssignTask;


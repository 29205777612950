import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack } from "react-icons/io5";

function VerificationFirstStep() {
    return(
        <>
          <div className="verification-wrap">
            <div className="verification-outer">
                   <div className="verification-title">
                        <button className="nextBtn in-active"><IoChevronBack /></button>
                        <h2>2-step Verification</h2>
                        <button className="nextBtn"><IoClose /></button>
                    </div>
                <div className="verification-main">
                    <p className="text-center">To continue, we need to verify your identity</p>
                    <h4 className="text-center pt-3">byewind@twitter.com</h4>
                    <div className="input-style-wrap">
                        <div className="inp-with-icon">
                           <input className="input-style" type="password" placeholder="Password" />
                           <span className="inp-icon"><AiOutlineEyeInvisible /></span>
                        </div>
                    </div>
                    <div className="input-style-wrap">
                        <button className="theme-btn full large">Next</button>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default VerificationFirstStep;
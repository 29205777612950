import React, { useEffect, useState } from 'react'
import MyTasks from './MyTasks'
import { Dropdown, Nav, Tab } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import CreateNewTask from './CreateNewTask'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { onFetchPublishTask } from '../../../Redux/Actions/TaskCreatorAction'
import DashboardFooter from '../../Common/DashboardFooter'

const NavCreatorTask = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false
    })

    const [createTask, setCreateTask] = useState([
        // {
        //     id: 1,
        //     title: 'Comment on my Instagram Reel',
        //     time: '2 Hours ago',
        //     status: 'Pending',
        //     price: '1,000$',
        //     dueDate: '12 July, 2024',
        //     description: 'We are seeking a talented Social Media Manager with a knack for creating engaging and captivating Instagram Reels...',
        //     tags: ['Data Entry', 'Online Survey', 'Intermediate'],
        //     stats: {
        //         applications: 50,
        //         submissions: 200,
        //         spent: '1500$',
        //         clicks: 50
        //     }
        // },


    ]);

    const ongoingTasks = createTask.filter(task => task.task_status === '0');
    const PendingTasks = createTask.filter(task => task.status === '0');
    const ApprovedTasks = createTask.filter(task => task.status === '1');
    const completedTasks = createTask.filter(task => task.task_status === '1');
    const RejectTasks = createTask.filter(task => task.status === '2');
    const fetchPublishTask = () => {

        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchPublishTask(setCreateTask, loader, setLoader))
    }
    useEffect(() => {
        fetchPublishTask()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <Nav variant="pills" style={{ padding: "15px 28px 15px 28px" }} >
                                <Nav.Item>
                                    <Nav.Link eventKey="first" >All Task</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Pending Tasks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Approved Tasks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">Rejected Tasks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">Ongoing Tasks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sixth">Completed Tasks</Nav.Link>
                                </Nav.Item>


                            </Nav>
                            <div className="add_new_task" style={{ padding: "0px 28px 0 28px" }}>
                                <NavLink to='/create/task' className='theme-btn me-3'>Create Task</NavLink>
                            </div>
                        </div>


                        <div className="setting_right_side_wrap creater-custom-footer">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <MyTasks
                                        cardData={createTask}
                                        loader={loader}
                                        type="createTask"
                                        fetchPublishTask={fetchPublishTask}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <MyTasks
                                        cardData={PendingTasks}
                                        loader={loader}
                                        type="PendingTasks"
                                        fetchPublishTask={fetchPublishTask}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <MyTasks
                                        cardData={ApprovedTasks}
                                        loader={loader}
                                        type="ApprovedTasks"
                                        fetchPublishTask={fetchPublishTask}
                                    />

                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <MyTasks
                                        cardData={RejectTasks}
                                        loader={loader}
                                        type="RejectTasks"
                                        fetchPublishTask={fetchPublishTask}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <MyTasks
                                        cardData={ongoingTasks}
                                        loader={loader}
                                        type="ongoingTasks"
                                        fetchPublishTask={fetchPublishTask}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixth">
                                    <MyTasks
                                        cardData={completedTasks}
                                        loader={loader}
                                        type="completedTasks"
                                        fetchPublishTask={fetchPublishTask}
                                    />
                                </Tab.Pane>

                            </Tab.Content>
                        </div>
                    </Tab.Container >
                    <DashboardFooter />
                </div>
            </div>

        </>
    )
}

export default NavCreatorTask

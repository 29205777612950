import React, { useEffect, useRef, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import SidePanel from './SidePanel';
import TitleBar from './TitleBar';
import DashboardHeader from './DashboardHeader';
import DashboardFooter from './DashboardFooter';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button, Modal } from 'react-bootstrap';
import CommonStepperContent from './CommonStepperContent';
function UserStepper(props) {
    const stepperRef = useRef(null);
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <Modal className="theme-modal Payout_request_wrap step-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            {/* <Modal.Header closeButton style={{ borderBottom: "0" }}>
            </Modal.Header> */}
            <Modal.Body>
                <div className="p-3 theme-stepper">
                    <Stepper ref={stepperRef}>
                        <StepperPanel header="Step-1">
                            <CommonStepperContent />
                        </StepperPanel>
                        <StepperPanel header="Step-2">
                            <CommonStepperContent />
                        </StepperPanel>
                        <StepperPanel header="Step 3">
                            <CommonStepperContent />
                        </StepperPanel>
                    </Stepper>
                </div>
            </Modal.Body>
        </Modal >

    )
}

export default UserStepper;


import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
const VerifcationSuccess = (props) => {
  return (
    <>
      <Modal
        className="theme-modal verfication-success application_submit_modal"
        show={props.show}
        onHide={props.close}
        backdrop="static"
        centered
      >
        <Modal.Body style={{paddingBlock:"75px"}} className='text-center d-flex flex-column align-items-center gap-3'>
          <img src="/success.gif" alt="success-gif" />
          <h5>{props.type} Verified Successfully</h5>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifcationSuccess;

import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { FaCloudUploadAlt, FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { onKycRejectQuery, onKycUpdate } from '../../../Redux/Actions/AuthActions';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import AadharFront from "../../../images/aadhar-card-front.png"
import AadharBack from "../../../images/aadhar-back-img.png"
import Pancard from "../../../images/pan-card-img.webp"
import { HiOutlineBellAlert } from 'react-icons/hi2';
import { Alert } from "react-bootstrap";
import KycModel from './KycModel';
import { IoAlertCircleOutline } from "react-icons/io5";



const TaskerKyc = () => {
    const dispatch = useDispatch()
    const kyc = useSelector(state => state.auth.user.kyc)
    const kycVerified = useSelector(state => state.auth.user.is_kyc_verified)
    const kycVerifiedAgency = useSelector(state => state.auth.user.is_kyc_agency_verified)
    const [showKyc, setShowKyc] = useState(false)
    const handleClose = () => setShowKyc(false)
    const [show, setShow] = useState(true);
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        update: false,
        submit: false
    })
    const [aadhar_card_frontUrl, setAadhar_card_frontUrl] = useState(null);
    const [aadhar_card_backUrl, setAadhar_card_backUrl] = useState(null);
    const [pan_cardUrl, setPan_cardUrl] = useState(null);
    const [aadhar_card_front, setAadhar_card_front] = useState(null);
    const [aadhar_card_back, setAadhar_card_back] = useState(null);
    const [pan_card, setPan_card] = useState(null);
    const [disabled, setDisabled] = useState(false)
    const handleSubmit = () => {
        // e.preventDefault();
        setLoader({
            ...loader,
            update: true
        })
        const formData = new FormData();
        if (aadhar_card_front) formData.append(`aadhar_card_front`, aadhar_card_front);
        if (aadhar_card_back) formData.append(`aadhar_card_back`, aadhar_card_back);
        if (pan_card) formData.append(`pan_card`, pan_card);
        setDisabled(true)
        // const kycData = {
        //     aadhar_card_front: aadhar_card_front,
        //     aadhar_card_back: aadhar_card_back,
        //     pan_card: pan_card
        // };

        // // Append each file in the kycData object to FormData
        // for (const key in kycData) {
        //     if (kycData[key]) {
        //         formData.append(key, kycData[key]);
        //     }
        // }
        // for (let pair of formData.entries()) {
        //     console.log(pair[0], pair[1]);
        // }
        // console.log('Form submitted with images:', aadhar_card_front, aadhar_card_back, pan_card);
        dispatch(onKycUpdate(formData, loader, setLoader, setDisabled))
    };


    const handleDrop = (e, setFile) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleFileChange = (setter, fileSetter) => (e) => {
        const file = e.target.files[0];
        if (file?.size > 200 * 1024 * 1024) {

            dispatch(setAlert("File size exceeds 200MB. Please upload a smaller file.", "danger"));
            return;
        }
        if (file) {
            setter(URL.createObjectURL(file));
            fileSetter(file);
        }
    };
    useEffect(() => {
        setAadhar_card_frontUrl(kyc?.aadhar_card_front);
        setAadhar_card_backUrl(kyc?.aadhar_card_back);
        setPan_cardUrl(kyc?.pan_card);
    }, [])
    const handleQuerySubmit = (e) => {
        e.preventDefault();
        if (text === "") {
            dispatch(setAlert("Please enter comment", "danger"));
            return;
        }
        console.log(text)
        setLoader({
            ...loader,
            submit: true
        })
        let formData = {
            "comment": text,
            "id": kyc?.id
        }
        dispatch(onKycRejectQuery(formData, loader, setLoader, handleClose))
    }
    return (
        <div className='creater-new-task'>
            <div className='account-block mb-5'>
                <div className='d-flex gap-2 '>
                    <p> Your KYC verfication status is</p>
                    <p>{kyc?.is_verified === "2" ? <span className="verBadge" style={{ backgroundColor: "#DEF8EE", color: "#4AA785", padding: "5px 10px", borderRadius: "50px" }}> Verified</span> : kyc?.is_verified === "1" ? <span className="verBadge" style={{ backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)", padding: "5px 10px", borderRadius: "50px" }}>Pending</span> : <span className="verBadge" style={{ backgroundColor: "#f8d7da", color: " #721c24", padding: "5px 10px", borderRadius: "50px" }}>Rejected</span>} </p>

                </div>

                {kyc.is_verified === "3" && show === true ?
                    <div>
                        <Alert
                            className="theme-alert"
                            style={{ backgroundColor: "#f8d7da", borderLeft: "6px solid #721c24" }}
                        // onClose={() => handleAlertClose(i)}
                        // dismissible
                        >
                            <div className="d-flex w-100 align-items-start align-items-md-center flex-column flex-md-row">
                                <div className="col-auto ps-2 pe-0">
                                    <IoAlertCircleOutline
                                        style={{ fontSize: "25px", color: "#721c24" }} />
                                </div>
                                <div className="col-auto ps-2 ps-lg-1">
                                    <p style={{ color: "#721c24" }}>Your kyc document is not verified due to {kyc?.reason} </p>
                                </div>
                                <div className='ms-auto'>
                                    <button class="theme-btn" type="button" onClick={() => setShowKyc(true)} >Message</button>
                                </div>
                            </div>
                        </Alert>
                    </div>
                    : ""}
                <div className="submission_wrap submission_bg py-4">

                    <div className="row align-items-center  mb-2 mb-md-5">
                        <div className="col-lg-6 col-sm-7 col-md-7 mb-4">
                            <h3 className='mb-3' style={{ fontSize: "18px" }}>Pan card</h3>
                            <div className=" create_upload mt-3 mb-3 mb-lg-4"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, setPan_card)}>
                                <FaCloudUploadAlt
                                    style={{ fontSize: "50px", color: "#c7006d", marginBottom: "5px" }} />
                                <span style={{ fontWeight: "500", fontSize: "16px" }}>Choose a file or drag & drop it here</span>
                                {/* {/ <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span > /} */}
                                <input type="file" accept=".jpg, .jpeg, .png, .gif"
                                    // onChange={(e) => setPan_card(e.target.files[0])}
                                    onChange={handleFileChange(setPan_cardUrl, setPan_card)}

                                />

                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-5 col-md-4 m-auto mb-4'>
                            <div className='account-block my-account-block'>
                                <img src={pan_cardUrl ? pan_cardUrl : Pancard} alt="" className='img-fluid' />
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                        <h3 style={{fontSize:"18px"}}>Aadhar card</h3>
                            <div className=" create_upload mt-3 h-100" style={{padding:"40px"}}>
                            <FaCloudUploadAlt
                            style={{ fontSize: "50px", color:"#c7006d", marginBottom:"5px" }} />
                                <span style={{fontWeight:"500", fontSize:"16px"}}>Choose a file or drag & drop it here</span>
                                <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span>
                                <input type="file" />

                            </div>
                        </div> */}
                        {/* <div className="col-2">
                            <button className='theme-btn' type='submit'>
                                Add
                            </button>
                        </div> */}
                    </div>
                    <div className='row align-items-center mt-4 '>
                        <div className="col-lg-6 col-sm-7 col-md-7">
                            <h3 style={{ fontSize: "18px" }}>Aadhar card</h3>
                            <div className=" create_upload mt-3 mb-3"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, setAadhar_card_front)}
                            >
                                <FaCloudUploadAlt
                                    style={{ fontSize: "50px", color: "#c7006d", marginBottom: "5px" }} />
                                <span style={{ fontWeight: "500", fontSize: "16px" }}>Choose a file or drag & drop it here Aadhar front side</span>
                                {/* {/ <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span > /} */}
                                <input type="file" accept=".jpg, .jpeg, .png, .gif"
                                    // onChange={(e) => setAadhar_card_front(e.target.files[0])}
                                    onChange={handleFileChange(setAadhar_card_frontUrl, setAadhar_card_front)}


                                />

                            </div>
                            <div className=" create_upload mb-3 mb-lg-0"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, setAadhar_card_back)}
                            >
                                <FaCloudUploadAlt
                                    style={{ fontSize: "50px", color: "#c7006d", marginBottom: "5px" }} />
                                <span style={{ fontWeight: "500", fontSize: "16px" }}>Choose a file or drag & drop it here Aadhar back side</span>
                                {/* {/ <span style={{color:"#A9ACB4"}}>JPEG, PNG, PDG and MP4 formats, up to 50MB</span > /} */}
                                <input type="file" accept=".jpg, .jpeg, .png, .gif"
                                    // onChange={(e) => setAadhar_card_back(e.target.files[0])}
                                    onChange={handleFileChange(setAadhar_card_backUrl, setAadhar_card_back)}


                                />

                            </div>
                        </div>
                        <div className='col-lg-3 col-sm-5 col-md-4 m-auto'>
                            <div className='account-block my-account-block'>
                                {/* {/ <h5 className='mb-3'>Front Side</h5 > /} */}
                                <img src={aadhar_card_frontUrl ? aadhar_card_frontUrl : AadharFront} alt="" className='img-fluid' />
                            </div>
                            <div className='account-block mt-4 my-account-block'>
                                <img src={aadhar_card_backUrl ? aadhar_card_backUrl : AadharBack} alt="" className='img-fluid' />
                            </div>
                            <div className='col-lg-12'>

                                <div class="modal-button-bottom my-4" style={{ background: "unset" }}>
                                    {kycVerified === "1" || kyc?.is_verified === "1" || kyc?.is_verified === "2" ? "" :
                                        <button class="theme-btn px-4" type="button" disabled={disabled} onClick={handleSubmit} >Update{loader.update ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null} </button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <KycModel
                        show={showKyc}
                        handleClose={handleClose}
                        text={text}
                        setText={setText}
                        loader={loader}
                        handleSubmit={handleQuerySubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default TaskerKyc

import React, { useEffect, useState } from 'react'
import Navbar from '../Common/Navbar'
import varifImg from '../../images/social icon/EnvelopeSimple.svg'
import lessThen from '../../images/icon/lessthen.svg'
import { IoMdClose } from "react-icons/io";
import OTPInput, { ResendOTP } from "otp-input-react";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { otpVerfication, ReSendOtpAction } from '../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../Redux/Actions/AlertActions';
import VerifcationSuccess from './VerificationSuccess';
import TitleBar from '../Common/TitleBar';

const Verification = () => {
    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false)
    const handleverify = () => {

        setLoader(true)
        if (OTP.length < 4) {

            setLoader(false)
            dispatch(setAlert("Please enter OTP", "danger"))
        }
        else {
            let Data = {
                "email": location?.state?.email,
                "otp": OTP,
                type: "email",
                "session_id": location?.state?.Id
            }

            dispatch(otpVerfication(Data, setLoader, navigation, setShowModal))
        }

    }
    const handleResendOtp = () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false);
        }, 20000)

        let fromData = {
            type: "email",
            "session_id": location?.state?.Id
        }
        dispatch(ReSendOtpAction(fromData))

    }

    return (
        <>
            <TitleBar title="Verification" />
            <Navbar />

            <section className="verification_wrapper login_wrapper">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-12">
                            <div className="sign_from_main">
                                <div className="col-12">
                                    <div className="sign_form verifi_from align-items-center">
                                        <div className="top_wrapper">
                                            {/* <div className="back_wrap">
                                                <img src={lessThen} alt="" />
                                            </div> */}

                                            {/* <div className="close_wrap">
                                                <IoMdClose />
                                            </div> */}
                                        </div>
                                        <div className="verification-content mt-5">
                                            <div className="verif_icon mb-4">
                                                <span className='d-flex' style={{ backgroundColor: "#fbe4e4", width: "60px", height: "60px", padding: "10px", borderRadius: "50px" }}>
                                                    <img src={varifImg} alt="" />
                                                </span>
                                            </div>
                                            <div className="verif_para">
                                                <h3>
                                                    Check your inbox
                                                </h3>
                                                <p>
                                                    A verification code has been sent to your email address. Please enter the code you received in your email.
                                                </p>
                                                <h4 style={{fontSize:"17px"}}>{location?.state?.email}</h4>
                                            </div>
                                        </div>
                                        <div className="verification_code">
                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
                                            {/* <div className="verifi_number me-2">
                                                <span>5</span>
                                            </div>
                                            <div className="verifi_number me-2">

                                            </div>
                                            <div className="verifi_number me-2 ">

                                            </div>
                                            <div className="verifi_number ">

                                            </div> */}
                                        </div>
                                        <div className="resend_code">
                                            {/* <span>Resend (60s)</span> */}
                                            {/* <ResendOTP onClick={handleResendOtp} /> */}
                                            <button type="button" className=" btn " onClick={handleResendOtp} disabled={isDisabled}>Resend OTP </button>
                                        </div>
                                        <div className="common_btn mb-4" onClick={handleverify}>
                                            <button type="button" className=" btn ">Verify {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VerifcationSuccess show={showModal} close={handleClose} type="Email" />
            </section>

        </>
    )
}

export default Verification

import React, { useEffect, useState } from "react";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoCalendarClearOutline } from "react-icons/io5";

import { FaSpinner } from "react-icons/fa";
import DashboardFooter from "../../Common/DashboardFooter";
import ProfileInfo from "./ProfileInfo";

function PersonalDetailsTabs(props) {

  const [education, seteducation] = useState([])
  const [notification, setNotification] = useState([])
  useEffect(() => {
    if (props.data) {
      seteducation(props.data.educational ? props.data.educational[0] : [])
      setNotification(props.data?.notification ? props.data?.notification : [])
    }
  }, [props?.data])

  return (
    <>
      {props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
        <>
          <div className='mb-3'>
            <ProfileInfo data={props.data} />
          </div>
          <div className="account-block mt-1">
            <div className="d-flex align-items-center justify-content-between">
              <h6>Profile Details</h6>
            </div>
            <div className="profileDetails">
              <ul>
                <li><span>Name</span><span>{props?.data?.name}</span></li>
                <li><span>Phone number <span className="tooltipN"><AiOutlineExclamationCircle /><span className="tooltiptext">Tooltip text</span></span></span><span>{props?.data?.mobileNumber}</span>{props?.data?.is_mobile_verified === "1" ? <span className="verBadge">Verified</span> : ""}</li>
                <li><span>Email</span><span>{props?.data?.email}</span> {props?.data?.is_email_verified === "1" ? <span className="verBadge">Verified</span> : ""}</li>

                {/* <li><span>Language</span>{props?.data?.language_name.length>0?props?.data?.language_name.map((curElem,i)=>{
             
              return(
                <span key={i}>{curElem.name},</span>
               )
            }):""}</li> */}
                <li><span>Age</span><span>{props?.data?.age}</span></li>
                <li className="flex-nowrap"><span>Kyc</span>{props?.data?.is_kyc_updated === "1" ? <span className="verBadge">Done</span> : <span className="verBadge" style={{ backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</span>}</li>
              </ul>
            </div>
            {/* <div className="alert-box dark mt-5">
        <div className="alert-icon"><AiOutlineExclamationCircle /></div>
        <div className="alert-txt">
            <h6>We need your attention!</h6>
            <p>You don’t have any payment method added yet. To start receiving withdrawls, please <a href="">Add Payment Method</a>.</p>
        </div>
    </div> */}
          </div>
          {/* <div className="account-block mt-4">
     <h6 className="pb-3">Support</h6>
     <div className="supportBlock">
        <h6>Log out of all devices</h6>
        <p>Log out of all other active sessions on other devices besides this one.</p>
     </div>
     <div className="supportBlock">
        <h6 className="txt-red">Delete my account</h6>
        <p>Permanently delete the account and remove access from all devices.</p>
     </div>
  </div> */}


          {/* <div className="account-block mt-4 mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h6>Educational Background</h6>
            </div>
            <div className="profileDetails">
              <ul>
                <li><span>Name of School</span><span>{education?.school_name}</span></li>
                <li><span>Degree</span><span>{education?.degree}</span></li>
                <li><span>Start Date</span><span>{education?.start_date}</span></li>
                <li><span>End Date</span><span>{education?.end_date}</span></li>
                <li><span>Grade</span><span>{education?.grade}</span></li>
              </ul>
            </div>

            <hr className="mt-4 mb-4" />
            <h6>Work Experience</h6>
            <div className="profileDetails">
              <ul>
                <li><span>Company Name</span><span>{education?.company}</span></li>
                <li><span>Designation</span><span>{education?.designation}</span></li>
                <li><span>Employment Period</span><span>{education?.period}</span></li>
              </ul>
            </div>
          </div> */}


          <div className="account-block mt-4">
            <h6>Task Preferences</h6>
            <div className="row task-reference-list">
              {
                props?.data?.task_preference.length > 0 ? props?.data?.task_preference.map((curElem, i) => {

                  return (
                    <div className="col-sm-6" key={i}>
                      <div className="task-reference-block">
                        {/* <span className="custom-check">
                  <input type="checkbox" />
                  <span className="checkMark"></span>
              </span> */}
                        <div className="task-reference-info">
                          <h6>{curElem.name}</h6>
                          {/* <p>Tasks involving inputting, updating, and managing data in various formats</p> */}
                        </div>
                      </div>
                    </div>
                  )

                }) : <div className="d-flex align-items-center justify-content-center"> No Task Perference</div>
              }


            </div>
          </div>


          <div className="account-block mt-4 mb-3 mb-lg-5">
            <h6>Notification Settings</h6>
            <div className="block-white mt-4">
              <h6>Notifications</h6>
              <div className="notification-cardN">
                <div className="notification-card-single">
                  <div className="notification-card-txt">
                    <h6>Web push notifications</h6>
                    <p>Receive push notifications on your web app.</p>
                  </div>
                  <span className="custom-switch">
                    <input type="checkbox" checked={notification.web_notification === "1"} readOnly />
                    <span className="checkMark"></span>
                  </span>
                </div>

                <div className="notification-card-single">
                  <div className="notification-card-txt">
                    <h6>Email notifications</h6>
                    <p>Receive email updates.</p>
                  </div>
                  <span className="custom-switch">
                    <input type="checkbox" checked={notification.email_notification === "1"} readOnly />
                    <span className="checkMark"></span>
                  </span>
                </div>

                <div className="notification-card-single">
                  <div className="notification-card-txt">
                    <h6>SMS notifications</h6>
                    <p>Receive updates in your mobile number.</p>
                  </div>
                  <span className="custom-switch">
                    <input type="checkbox" checked={notification.sms_notification === "1"} readOnly />
                    <span className="checkMark"></span>
                  </span>
                </div>

                <div className="notification-card-single">
                  <div className="notification-card-txt">
                    <h6>Whatsapp notifications</h6>
                    <p>Receive updates in your whatsapp number.</p>
                  </div>
                  <span className="custom-switch">
                    <input type="checkbox" checked={notification.whatsapp_notification === "1"} readOnly />
                    <span className="checkMark"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>

  )
}

export default PersonalDetailsTabs;
import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import AdminTaskDetails from './AdminTaskDetails'
import SidePanelAdmin from '../../../Common/SidePanelAdmin'
import DashboardHeader from '../../../Common/DashboardHeader'
import AdminApplication from './AdminApplication'
import AdminSubmissions from './AdminSubmissions'
import AdminReviews from '../AdminReviews'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { onFetchCreatordetailAdmin } from '../../../../Redux/Actions/AdminDashboardActions'

const AdminNavTaskDetails = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const location = useLocation()
    const dispatch = useDispatch()
    const slug = location?.state?.slug;
    const [data, setData] = useState({})
    const [loader, setLoader] = useState({
        fetch: false
    })
    const userDetailsTask = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let fromData = {
            "slug": slug
        }
        dispatch(onFetchCreatordetailAdmin(fromData, setData, loader, setLoader))
    }
    useEffect(() => {
        userDetailsTask()
    }, [slug])

    return (
        <>
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>

                        <SidePanelAdmin type="url" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>

                        <div className="dashboard-in  ">

                            <DashboardHeader toggleSidebar={toggleClass} title="Manage Tasks" />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" className="p-3 ps-4">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Task Details</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="second">Applications</Nav.Link>
                                            </Nav.Item> */}
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"> Submissions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="forth">Reviews</Nav.Link>
                                            </Nav.Item>

                                        </Nav>

                                        <div className="setting-right-side-wrap admin-custom-footer">
                                            <Tab.Content className='pb-0'>
                                                <Tab.Pane eventKey="first">
                                                    <AdminTaskDetails data={data} fetchdata={userDetailsTask} loader={loader} />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="second">
                                                    <AdminApplication />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="third">
                                                    <AdminSubmissions />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="forth">
                                                    <AdminReviews data={data?.review?.allreview} loader={loader} averagerating={data?.review?.averagerating} totalreview={data?.review?.averagerating} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>

                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div >


        </>
    )
}

export default AdminNavTaskDetails

// PayoutPDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles

// Create Document Component
const PayoutPDF = ({ data }) => (
    <Document>
        <Page size="A4" style={styles.page}>
        <View style={styles.header}>
        <Text style={{ fontSize: 24, textAlign: 'center', marginBottom: 10 }}>Company Report</Text>
        <View style={styles.companyInfo}>
          <Text style={{ fontSize: 18, fontWeight: 'bold' }}>Vega6 webware</Text>
          <Text>cjkxhsbfvsdjcfvdsjfggsdugujfgsdhgfvsdjfdsjfydsgfjdsrfhjh</Text>
        </View>
      </View>
            <View style={styles.section}>
                <Text style={styles.title}>Payout History</Text>
                <View style={styles.table}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.tableCell}>Transaction ID</Text>
                        <Text style={styles.tableCell}>Description</Text>
                        <Text style={styles.tableCell}>Date</Text>
                        <Text style={styles.tableCell}>Amount</Text>
                    </View>
                    
                        <View  style={{ flexDirection: 'row' }}>
                            <Text style={styles.tableCell}>{data.id}</Text>
                            <Text style={styles.tableCell}>{data.description}</Text>
                            <Text style={styles.tableCell}>{data.date}</Text>
                            <Text style={styles.tableCell}>{data.amount}</Text>
                        </View>
                 
                </View>
            </View>
        </Page>
    </Document>
);

export default PayoutPDF;
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    section: {
        marginBottom: 10,
    },
    header: {
        marginBottom: 20,
      },
      companyInfo: {
        marginBottom: 10,
      },
    text: {
        fontSize: 12,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    table: {
        marginTop: 10,
        border: '1px solid black',
        borderCollapse: 'collapse',
    },
    tableCell: {
        border: '1px solid black',
        padding: 5,
    },
});

import { produce } from "immer"
const initialState = {
    data: false
}
export const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case 'Fetch_Bank_detail':
            return produce(state, (draft) => {


                draft.data = action.payload
            })
        case 'ADD_Bank_detail':
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case 'Edit_Bank_detail':
            return produce(state, (draft) => {
                draft.data = action.payload
            })
            case 'delete_Bank_detail':
                return produce(state, (draft) => {
                    draft.data = action.payload
                })

        default:
            return state

    }
}
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Actions/AuthActions";
import TitleBar from "../Common/TitleBar";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { GoBriefcase } from "react-icons/go";
import google from '../../images/social icon/google.svg'
import fb from '../../images/social icon/Facebook.svg'
import Navbar from "../Common/Navbar";
import ForgotEmailModal from "./ForgotEmailModal";
import DashboardFooter from "../Common/DashboardFooter";
import MobileLogIn from "./MobileLogIn";

const SignIn = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const [view, setView] = useState(false)
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);

  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const location = useLocation()

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
    userType: location.state ? location.state : "Tasker"
  })

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (auth.user.role === "Tasker") {
        navigate('/dashboard')
      }
      else if (auth.user.role === "admin") {
        navigate('/admin/dashboard')

      }
      else {
        navigate('/creator/dashboard')

      }

    }
  }, [auth])


  const handleChange = (e) => {
    const { name, value } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoader(true)
    dispatch(loginUser(loginInfo, setLoader))
  }
  const handleType = (type) => {

    setLoginInfo({
      ...loginInfo,
      userType: type
    })
  }

  return (
    <>
      <Navbar userType={loginInfo.userType} />
      <TitleBar title="Sign In" />

      <section className="login_wrapper">
        <div className="container-fluid h-100">
          <div className="col-12 h-100">
            <div className="row h-100 ">
              <div className="sign_from_main">
                <div className="col-12 ">
                  <div className="sign_form">
                    <form className="sign_form_top" onSubmit={handleSubmit}>

                      <div className="sign_form_content">

                        <span>Sign In</span>
                        <h3 className="mt-2">Sign in Your {loginInfo.userType} Account</h3>

                        <div className="sign_Account_wrap">

                          <div className="sign_Account" onClick={() => handleType("Tasker")} style={{ border: loginInfo.userType === "Tasker" ? "1px solid #000" : "" }}>

                            <div className="sign_acc_icon">
                              <IoPersonCircleOutline style={{ fontSize: "30px" }} />
                            </div>

                            <div className="sign_acc_name">
                              <span>Tasker Account</span>
                              <p>Sign in as a Tasker</p>
                            </div>
                          </div>

                          <div className="sign_Account" onClick={() => handleType("Creator")} style={{ border: loginInfo.userType === "Creator" ? "1px solid #000" : "" }}>
                            <div className="sign_acc_icon">
                              <GoBriefcase />
                            </div>

                            <div className="sign_acc_name">
                              <span>Creator Account</span>
                              <p>Sign in as a Creator</p>
                            </div>

                          </div>

                        </div>

                        <div className="separate_wrap">
                          <span></span>
                          <p>Sign in with email</p>
                          <span></span>
                        </div>
                        <div className="login-input-wrap">
                          <input
                            className="login-input"
                            type="email"
                            placeholder="Email "
                            value={loginInfo.email}
                            onChange={handleChange}
                            name="email"
                            required
                          />
                        </div>
                        <div className="login-input-wrap">
                          <div className="input-logo">
                            <input
                              className="login-input"
                              type={view ? "text" : "password"}
                              placeholder="Password"
                              required
                              onChange={handleChange}
                              value={loginInfo.password}
                              name="password"
                            />
                            <span
                              onMouseUp={() => setView(false)}
                              onMouseLeave={() => setView(false)}
                              onMouseDown={() => setView(true)}
                              className="inp-icon"
                            >
                              <IoEyeOffOutline />
                            </span>
                          </div>
                        </div>
                        <div className="sign-input-forget mt-2 text-end">
                          <p ><span onClick={handleShow} style={{ color: "#1769FF" }}>Forgot Password? </span> </p>
                        </div>
                        <div className="sign-input-wrap">
                          <button type="submit" className="login-btn btn ">Sign In {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                        <div className="separate_wrap ">
                          <span></span>
                          <p style={{ color: "#1769FF", fontWeight: "500", cursor: "pointer", fontSize: "14px" }} onClick={handleShow1}>Login With OTP</p>
                          <span></span>
                        </div>
                        <div className="sign-input-forget mt-4 text-center">
                          <p > <span >Not a member yet?</span > <span onClick={() => navigate("/sign-up", { state: loginInfo.userType })} style={{ color: "#1769FF" }}>Sign Up</span>  </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ForgotEmailModal show={show} handleClose={handleClose} />
      <MobileLogIn show={show1} handleClose={handleClose1} />
      <div style={{ background: "#f5f5f5" }}>
        <DashboardFooter />
      </div>

    </>
  )
}

export default SignIn;
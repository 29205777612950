import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { ScrollPanel } from "primereact/scrollpanel";
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { addAdminTaskPrefanceAction } from '../../../Redux/Actions/AdminSettingsActions';
import { FaSpider, FaSpinner } from "react-icons/fa";

const TaskPreferenceAddModal = (props) => {
    const[input,setInput]=useState('')
    const dispatch=useDispatch()
    const handleSubmit=(e)=>{
        e.preventDefault()
      props.setLoader({
            ...props.loader,
            add:true
        })
if(input===''){
    dispatch(setAlert("text field is requried","danger"))
    props.setLoader({
        ...props.loader,
        add:false
    })
}
else{
let data={
    "name":input
}
setInput('')
dispatch(addAdminTaskPrefanceAction(data,props.setLoader,props.loader,props.fetchData,props.handleClose))
}

    }
    return (
        <Modal className="modalRights theme-modal creator_new_task" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                     <Modal.Header closeButton style={{ borderBottom: "0" }}>
                     </Modal.Header>
            <Modal.Body className='pt-0'>
                <div className='modalTitle pb-3 ps-0 mb-3' style={{height:"unset"}}>
                    <h6>Add Category</h6>
                </div>

                <div className="account-block">
                    <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-3 alt">
                        <label className="label mb-2" htmlFor="">Name</label>
                        <input className="input border-0" type="text" placeholder="Enter category name" name="input" id="" value={input} onChange={(e)=>setInput(e.target.value)} />
                    </div>
                    <div className="modal-button-bottom" style={{background:"unset"}}>
                        <button className="theme-btn light" type="button" onClick={props.handleClose}>Cancel</button>
                        <button className="theme-btn" type="submit">Submit{props.loader.add? <FaSpinner className='spin ms-1'/>:""}</button>
                    </div>
                    </form>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default TaskPreferenceAddModal

import React, { useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpider, FaSpinner } from "react-icons/fa";
import BankModal from "../ModalComponents/BankModal";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import { ADDBankDetail, DeleteBankDetail, UpdateUpiVerify } from "../../../Redux/Actions/PaymentActions";
import { GoDotFill } from "react-icons/go";
import EditBankModel from "../ModalComponents/EditBankModel";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import StripModal from "../ModalComponents/StripModal";
import paypalimg from "../../../images/PayPal.png"
import upi from "../../../images/upi.png"
function PaymentMethodsTabs() {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth.user)
  const [id, setId] = useState("")

  const [upiId, setUpiId] = useState("")
  const [typeStripe, setTypeStripe] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [loader, setLoader] = useState(false)
  const [loader1, setLoader1] = useState(false)

  const [edit, setEdit] = useState("")
  const FetchBankDetail = useSelector(state => state.BankDetails.data)

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);

  const handleShow2 = () => setShow2(true);
  const handleClose2 = () => setShow2(false);
  const handleStripe = (curElem) => {
    handleShow2()
    setTypeStripe(curElem)
  }
  const upiPattern = /^[a-zA-Z0-9._-]{2,256}@[a-zA-Z]{2,64}$/;

  const handleUpiVerify = () => {

    if (upiPattern.test(upiId) === false) {
      // setLoader(true)
      dispatch(setAlert("Incorrect UPI ", "danger"))
    }
    else {
      let fromdata = {
        "upi": upiId,
        "type": "upi"
      }
      setLoader(true)
      setUpiId("")
      dispatch(ADDBankDetail(fromdata, setLoader))

    }

  }
  const handleEditdata = (curElem) => {
    handleShow1()

    setEdit(curElem)
  }
  const handleDelete = (pId) => {
    setLoader1(true)
    setId(pId)
    let formdata = {
      "id": pId
    }
    dispatch(DeleteBankDetail(formdata, setLoader1))
  }

  return (
    <>
      <div className="account-block mb-3">
        <h6>Select You Payment Method</h6>
        <div className="block-white mt-4">
          <div className="payment-method-single" onClick={() => handleStripe("Stripe")}>
            <div className="payment-method-img"><img src={require("../../../images/stripe.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Stripe</h6>
              <p>Connect your Stripe account.</p>
            </div>
          </div>
          <div className="payment-method-single mt-3" onClick={() => handleStripe("Paypal")}>
            <div className="payment-method-img"><img src={paypalimg} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Paypal</h6>
              <p>Connect your Paypal account.</p>
            </div>
          </div>

          <div className="payment-method-single mt-3" style={{ cursor: "pointer" }}>
            <div className="payment-method-img"><img src={require("../../../images/credit.svg").default} alt="" /></div>
            <div className="payment-method-txt" onClick={handleShow}>
              <h6>Bank card</h6>
              <p>Add a Bank card.</p>
            </div>
          </div>
          <div className="payment-method-single mt-3">
            <div className="payment-method-img"><img src={upi} alt="" /></div>
            <div className="payment-method-txt">
              <h6>UPI</h6>
              <p>Add UPI Id</p>

              <div className="creator_new_task" style={{ height: "100%" }}>
                <div className="account-block col-lg-8 ps-0 py-2 mb-0">
                  <div className="d-flex flex-row submission_wrap upi-mob submission_bg bank-details align-items-center gap-2" style={{ minHeight: "60px" }}>
                    <input type="text" className="form-control border-0" id="upiId" name="" value={upiId} placeholder="Myname@Kotak" onChange={(e) => setUpiId(e.target.value)} required />
                    <button onClick={handleUpiVerify} className="theme-btn" type="submit" style={{ cursor: "pointer", height: "30px" }}>Verify {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
       
      </div>
      <div className="col-12">
          <div className="campaign-stat-right w-100 ps-0 pe-0">
            <div className="account-block mb-5">
              <div className="d-flex justify-content-between">
                <h6>Payment Details List</h6>
                {/* <a href=""><span>Show All</span></a> */}
              </div>
              <div className="table-responsive mt-3">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>S.n</th>
                      <th>Type</th>
                      <th>Account</th>
                      <th>Data</th>
                      {/* <th>Acc. Holder Name</th>
                      <th>Account Number</th>
                      <th>IFSC Code</th>
                      <th>Bank Name</th>
                      <th>Branch Name</th>
                      <th>Status</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FetchBankDetail.length > 0 ? FetchBankDetail.map((curElem, i) => {
                      const parsedData = JSON.parse(curElem?.data || null);

                      return (
                        <tr key={i}>
                          <td data-label="S.n.">{i + 1}</td>
                          <td data-label="Type">{curElem.type}</td>
                          <td data-label="Account">{curElem.account}</td>
                          <td data-label="Data">{
                            parsedData ? (
                              Object.entries(parsedData).map(([key, value]) => (
                                <div key={key}>{`${key}: ${value}`}</div>
                              ))
                            ) : (
                              ""
                            )
                          }</td>
                          {/* <td data-label="Acc. Holder Name">{curElem?.account_holder_name}</td> */}
                          {/* <td data-label="Account Number">{curElem?.account_no}</td> */}
                          {/* <td data-label="IFSC Code">{curElem?.ifsc_code}</td> */}
                          {/* <td data-label="Bank Name">{curElem?.bank_name}</td> */}
                          {/* <td data-label="Branch Name">{curElem?.branch_name}</td> */}
                          {/* <td data-label="Status">
                            {
                              curElem?.is_primary === "0" ? "-" :


                                <p className="Status_complete"><GoDotFill className="Status_complete" style={{ marginRight: "3px" }} />Primary</p>
                            }
                          </td> */}

                          <td className="text-center" data-label="Action">
                            <p style={{ cursor: "pointer" }} className="common-btn badges" onClick={() => handleDelete(curElem.id)}>Delete{curElem.id === id ? loader1 ? <FaSpinner className="spin ms-1" /> : null : ""}</p>

                            {/* <BiEditAlt className="me-" onClick={() => handleEditdata(curElem)} style={{ cursor: "pointer", fontSize: "16px" }} /> */}
                            {/* <MdDeleteOutline className="text-center" style={{ cursor: "pointer", fontSize: "16px", color: "#fe0405" }} onClick={() => handleDelete(curElem.id)} /> */}

                          </td>
                        </tr>
                      )
                    }) : <td className="text-center py-2 pt-lg-3" colSpan={7}>No Payment Details Found</td>}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      {/* table */}
      <StripModal
        show={show2}
        handleClose={handleClose2}
        typeStripe={typeStripe}
      />
      <BankModal
        show={show}
        handleClose={handleClose}
      />
      {/* <EditBankModel
        show1={show1}
        handleClose1={handleClose1}
        edit={edit}
      /> */}
    </>
  )
}

export default PaymentMethodsTabs;
import React, { useEffect, useState } from 'react'

import { IoLocationOutline, IoSearchOutline } from 'react-icons/io5';
import { PiClockUser } from "react-icons/pi";
import picPro from '../../../../images/pp.png';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import pinset from '../../../../images/icon-2/pinset.svg';
import CheckCircle from '../../../../images/icon-2/CheckCircle.svg';
import BackCircle from '../../../../images/icon-2/BackCircle.svg';
import XCircle from '../../../../images/icon-2/XCircle.svg';
import FilePdf from '../../../../images/icon-2/FilePdf.svg';
import DashboardFooter from '../../../Common/DashboardFooter';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import CusstomPagination from "../../../Common/CusstomPagination"
import { FetchAdminTaskTaskerSubmissionlist } from '../../../../Redux/Actions/AdminManagerUserAction';



const AdminSubmissions = () => {
    const location = useLocation()
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const dispatch = useDispatch()
    const slug = location?.state?.slug;
    const navigation = useNavigate()
    const [searchInput, setSearchInput] = useState('')
    const [loader, setLoader] = useState({
        fetch: false
    })

    const [totalLength, setTotalLength] = useState(0);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [sortOrder, setSortOrder] = useState('asc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: Number(limit?.code) || 20
    });
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    const fetchData = () => {
        let formData = {
            "page": pagination.currentPage || 1,
            "slug": slug,
            "limit": +limit?.code,
        }
        setLoader({
            fetch: true
        })

        dispatch(FetchAdminTaskTaskerSubmissionlist(formData, loader, setLoader, setData))
    }

    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data?.list)
            setTotalLength(data?.total_count)

        } else {
            const filterSerach = data?.list?.filter(item => {
                return (
                    (item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (item.email && item.email.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (item.id && item.id.toString().includes(searchInput))
                )
            })

            setFilteredData(filterSerach)
        }
    }, [searchInput, data?.list, pagination.currentPage])

    useEffect(() => {
        fetchData()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })

    }, [pagination.currentPage, slug, limit.code])

    return (
        <>
            {
                loader?.fetch ?
                    <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                    <ScrollPanel className="custombar2 content-area pt-0">

                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    {/* <div className=' pe-1'>
                                        <div className="account-block mt-2 mb-4">
                                            <h6>Comment on my Instagram Reel</h6>

                                            <div className="d-flex task_main_wrap">
                                                <div className="task_process">
                                                    <span>Status</span>
                                                    <div className="task_process_bar">
                                                                        <p>Progress <span>/</span> 51%</p>
                                                                    </div>
                                                    <div className="progress_wrap" style={{ position: "relative" }}>
                                                        <h3>In Progress</h3>
                                                    </div>

                                                </div>
                                                <div className="task_process pe-3">
                                                    <span className="text-center w-100">Total Submission</span>
                                                    <div className="task_process_bar text-center">
                                                        <p>15 <span>/</span> 50%</p>
                                                    </div>
                                                </div>
                                                <div className="task_process ps-3 pe-3">
                                                    <span className="text-center w-100">Total Submission</span>
                                                    <div className="task_process_bar text-center">
                                                        <p>29 Jan, 2022</p>
                                                    </div>
                                                </div>
                                                <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                                    <span className="text-center w-100">Price</span>
                                                    <div className="task_process_bar text-center">
                                                        <p>$50  <span>/</span> Submission</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='ms-3 mb-2'>
                                        <div className="table-bar mb-2 me-3">
                                            <div className="table-bar-left">
                                                <div className="table-bar-icon">
                                                    <div className="table-nav">
                                                        <div className="profile-menu">

                                                        </div>
                                                        {/* <div className="profile-menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                    <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet
                                                                    </li>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet.
                                                                    </li>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> */}
                                                    </div>


                                                    <ul>
                                                        <li title="Sort by Date" onClick={handlesort}>
                                                            <img src={require("../../../../images/icon/sort.svg").default} alt="" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-bar-right">
                                                <div className='d-flex align-items-center gap-3 bar-btns'>
                                                    <div className="header-search">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            name="searchInput"
                                                            value={searchInput}
                                                            onChange={(e) => setSearchInput(e.target.value)}

                                                        />

                                                        <span className="left-icon"><IoSearchOutline
                                                        /></span>
                                                    </div>

                                                    <div className='task-dropdown' style={{ width: "50%" }}>
                                                        <Dropdown style={{ padding: "4px 10px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                                            placeholder="Select a Limit" className="rounded-2 border-0" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='mx-3 py-1'>
                                        <div className="table-responsive mt-3 mb-5">
                                            <table className="table theme-table">
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>User</th>
                                                        <th>Title</th>
                                                        <th>Price</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                {filteredData?.length > 0 ? filteredData?.map((curElem, i) => {

                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="S.NO">{curElem.id}</td>
                                                            <td data-label="User"><span className="userImg"><img src={curElem.profile ? curElem.profile : "https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg"} alt="" /></span>{curElem.name}</td>
                                                            <td data-label="Title">{curElem.title}</td>
                                                            <td data-label="Price"> ₹ {curElem.total_amount}</td>

                                                            <td data-label="Status">
                                                                {curElem.status === "2" ? <div className='earn_status complete' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Approved</div> : curElem.status === "3" ? <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</div>}
                                                            </td>
                                                            <td data-label="Action"><p style={{ color: "#000", fontWeight: "400", cursor: "pointer", height: "25px" }} className="d-flex align-items-center start_task gap-1" onClick={() => navigation(`/admin/submission-details?id=${curElem.id}`)}><MdOutlineRemoveRedEye style={{ fontSize: "16px", marginTop: "3px" }} />
                                                                View</p></td>

                                                        </tr>
                                                    )
                                                }) : <td className="text-center pt-3" colSpan={7}> {filteredData?.length === 0 ? "No Task Found" : ""}</td>}


                                            </table>
                                        </div>
                                    </div>
                                    <div className=' my-3 me-4'>
                                        <CusstomPagination
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            totalLength={totalLength}
                                            loader={loader.fetch}
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </ScrollPanel >
            }
        </>
    )
}
export default AdminSubmissions

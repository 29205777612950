import React from 'react'
import DashboardFooter from './DashboardFooter'
import Navbar from './Navbar'

const PrivacyPolicy = () => {
    return (
        <>
       <Navbar />
            <div className='container-fluid privacy-policy my-5'>
           <div className='account-block mb-4'>
           <h1 className='text-center my-4'>Privacy Policy</h1>
           </div>
                <div className='col-lg-12'>
                    <div className='account-block'>
                        {/* <h1 className='text-center my-5'>Privacy Policy</h1> */}
                        <div className='submission_wrap  mb-4'>
                            <h2>Access to Third-Party Accounts</h2>
                            <h3>Facebook Account Access</h3>
                            <h4>Permissions granted:</h4>
                            <ul className='lists'>
                                <li><strong>Personal Information:</strong> About Me, Contact Email</li>
                                <li><strong>Friends and Connections:</strong> Access Friend Lists, Manage Friend Lists, List of Friends, Friend Subscriptions</li>
                                <li><strong>Page Management:</strong> Access Page CTA, Manage Pages, Publish as Page, Show List of Managed Pages</li>
                                <li><strong>Notifications and Messaging:</strong> Access Requests, App Notifications, Mobile Messaging on behalf of Page, News Feed and Wall, Page Messaging, Text Messaging</li>
                                <li><strong>Content Management:</strong> Interests, Likes, Photos, Upload Photos, Videos, Video Upload, Video Watches Action, Status, Status Updates, User Timeline Posts Access, Custom User Actions</li>
                                <li><strong>Sharing:</strong> Publish to the Wall, Share</li>
                                <li><strong>Groups:</strong> Manage Groups</li>
                            </ul>
                            <h3>Stripe and Twitter Account Access</h3>
                            <p><strong>Personal Data:</strong> Various types of data as outlined in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Advertising</h2>
                            <h3>AdMob and Facebook Audience Network</h3>
                            <ul>
                                <li><strong>Personal Data Collected:</strong> Cookies, unique device identifiers for advertising (e.g., Google Advertiser ID, IDFA), and Usage Data</li>
                            </ul>
                            <h3>AdKaora and Yahoo App Publishing</h3>
                            <ul>
                                <li><strong>Personal Data Collected:</strong> Cookies and Usage Data</li>
                            </ul>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Analytics</h2>
                            <ul className='lists'>
                                <li>Google Analytics (with anonymized IP)</li>
                                <li>AdEspresso Conversion Tracking</li>
                                <li>Facebook Ads Conversion Tracking</li>
                                <li>Google Analytics</li>
                                <li>KISSmetrics</li>
                                <li>Twitter Ads Conversion Tracking</li>
                                <li>WordPress Stats</li>
                                <li>Yahoo Advertising Conversion Tracking</li>
                            </ul>
                            <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Backup Saving and Management</h2>
                            <ul className='lists'>
                                <li>Backup on Dropbox</li>
                                <li>Backup on Google Drive</li>
                                <li><strong>Personal Data Collected:</strong> Various types of data as outlined in the service's privacy policy</li>
                            </ul>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Backup Saving and Management</h2>
                            <ul className='lists'>
                                <li>Backup on Dropbox</li>
                                <li>Backup on Google Drive</li>
                                <li><strong>Personal Data Collected:</strong> Various types of data as outlined in the service's privacy policy</li>
                            </ul>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Commercial Affiliation</h2>
                            <ul className='lists'>
                                <li>JVZoo &amp; JVShare</li>
                                <li>Amazon Affiliation</li>
                                <li>AliExpress Affiliate</li>
                                <li>ClickBank</li>
                                <li>Zooplus Affiliate Programme</li>
                            </ul>
                            <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                            <h2>Contacting the User</h2>
                            <h3>Contact Form</h3>
                            <p><strong>Personal Data Collected:</strong> Address, City, Company Name, Country, Email Address, Field of Activity, First Name, Gender, Last Name, Phone Number, Profession, User ID, VAT Number, Website, and ZIP/Postal Code</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Content Commenting</h2>
                        <p><strong>Personal Data Collected:</strong> Cookies, Usage Data, and various other types of data as specified in the service's privacy policy</p>
                        <ul className='lists'>
                <li><strong>Facebook Comments</strong></li>
                <li><strong>Personal Data Collected:</strong> Cookies and Usage Data</li>
            </ul>
            <h3>Displaying Content from External Platforms</h3>
            <p><strong>GitHub Button and Social Widgets, YouTube Video Widget (without cookies)</strong></p>
            <p><strong>Personal Data Collected:</strong> Usage Data</p>
            <h3>Google Fonts</h3>
            <p><strong>Personal Data Collected:</strong> Usage Data and various other types of data as specified in the service's privacy policy</p>
            <p><strong>Google Site Search, Instagram Widget, YouTube Video Widget</strong></p>
            <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Handling Payments</h2>
                        <h3>Payment Services</h3>
                        <ul className='lists'>
                <li>2Checkout</li>
                <li>Amazon Payments</li>
                <li>Authorize.Net</li>
                <li>PayPal</li>
                <li>PayPal Payments Hub</li>
                <li>Braintree</li>
                <li>Google Wallet</li>
                <li>Simplify</li>
                <li>Stripe</li>
                <li>Zooz</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Various types of data as specified in each service's privacy policy</p>
            <p><strong>PayPal Carrier Payments</strong></p>
            <p><strong>Personal Data Collected:</strong> Phone Number and various other types of data as specified in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Hosting and Backend Infrastructure</h2>
                        <h3>Google Cloud Storage</h3>
                        <p><strong>Personal Data Collected:</strong> Various types of data as specified in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Interaction with Data Collection Platforms and Other Third Parties</h2>
                        <h3>GetResponse Widget</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies, Email Address, First Name, and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Interaction with External Social Networks and Platforms</h2>
                        <h3>Social Buttons and Widgets</h3>
                        <ul className='lists'>
                <li>Facebook Like Button and Social Widgets</li>
                <li>PayPal Button and Widgets</li>
                <li>Pinterest “Pin it” Button and Social Widgets</li>
                <li>Reddit Button and Widgets</li>
                <li>ShareThis</li>
                <li>Tumblr Sharing Button and Social Widgets</li>
                <li>Twitter Tweet Button and Social Widgets</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
            <h3>YouTube Button and Social Widgets</h3>
            <p><strong>Personal Data Collected:</strong> Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Interaction with Live Chat Platforms</h2>
                        <h3>Facebook Messenger Customer Chat</h3>
                        <p><strong>Personal Data Collected:</strong> About Me, Cookies, and Usage Data</p>
                        <h3>Freshchat Widget</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies, Email Address, and Usage Data</p>
                        <h3>Tawk.to Widget and Zopim Widget</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Interaction with Support and Feedback Platforms</h2>
                        <h3>Zendesk Widget</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Managing Contacts and Sending Messages</h2>
                        <h3>Email Marketing Services</h3>
                        <ul className='lists'>
                <li>GetResponse</li>
                <li>AWeber</li>
                <li>Sendgrid</li>
                <li>Mailgun</li>
                <li>Drip</li>
                <li>MailChimp</li>
                <li>ZOHO Campaigns</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Email Address</p>
            <h3>iContact</h3>
            <p><strong>Personal Data Collected:</strong> Email Address and Usage Data</p>
            <h3>Twilio</h3>
            <p><strong>Personal Data Collected:</strong> Phone Number</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Managing Landing and Invitation Pages</h2>
                        <h3>Leadpages</h3>
                        <p><strong>Personal Data Collected:</strong> Email Address, Usage Data, and various other types of data as specified in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Managing Support and Contact Requests</h2>
                        <h3>Freshdesk, Zendesk, and ZOHO CRM Email</h3>
                        <p><strong>Personal Data Collected:</strong> Various types of data as specified in each service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Platform Services and Hosting</h2>
                        <h3>Tumblr</h3>
                        <h3>WordPress.com</h3>
                        <p><strong>Personal Data Collected:</strong> Various types of data as specified in each service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Registration and Authentication</h2>
                        <h3>Supported Services:</h3>
                        <ul className='lists'>
                <li>Log In with PayPal</li>
                <li>Facebook Authentication</li>
                <li>GitHub OAuth</li>
                <li>Google OAuth</li>
                <li>Instagram Authentication</li>
                <li>Login with Amazon</li>
                <li>OneDrive OAuth</li>
                <li>Pinterest OAuth</li>
                <li>Stripe OAuth</li>
                <li>Twitch.tv Authentication</li>
                <li>Twitter OAuth</li>
                <li>WordPress.com Single Sign-On</li>
                <li>YouTube OAuth</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Various types of data as specified in each service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Remarketing and Behavioral Targeting</h2>
                        <h3>Services:</h3>
                        <ul className='lists'>
                <li>AdRoll</li>
                <li>Facebook Remarketing</li>
                <li>Perfect Audience</li>
                <li>Twitter Remarketing</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Cookies and Usage Data</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Custom Audiences:</h2>
                        <ul className='lists'>
                <li>Facebook Custom Audience</li>
                <li>Twitter Tailored Audiences</li>
            </ul>
            <p><strong>Personal Data Collected:</strong> Cookies and Email Address</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Traffic Optimization and Distribution</h2>
                        <h3>CloudFlare</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies and various types of data as specified in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>User Database Management</h2>
                        <h3>ActiveCampaign and ZOHO CRM</h3>
                        <p><strong>Personal Data Collected:</strong> Email Address and various types of data as specified in each service's privacy policy</p>
                        <h3>FullContact</h3>
                        <p><strong>Personal Data Collected:</strong> Company Name, Date of Birth, Email, First Name, Gender, Last Name, and various types of data as specified in the service's privacy policy</p>
                        <h3>Infusionsoft</h3>
                        <p><strong>Personal Data Collected:</strong> Various types of data as specified in the service's privacy policy</p>
                        <h3>Intercom</h3>
                        <p><strong>Personal Data Collected:</strong> Cookies, Email Address, Usage Data, and various types of data as specified in the service's privacy policy</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>CCPA Compliance</h2>
                        <p>DoTask and its related brands/products will never sell your personal information. In accordance with California law, we provide an option for you to opt out or request details about your information. This option is accessible within the product dashboard.</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Social Networks and Third-Party Services</h2>
                        <p>When you connect your social networks to our Services or authorize a third-party service to access your account, you agree to share information with these social networks and third-party services according to their respective terms and privacy policies. For instance, if you link your YouTube account to our Services, this connection utilizes YouTube’s API services, and you will be subject to the Google Privacy Policy, which can be found at YouTube Terms of Service and Google Privacy Policy.</p>
                        <p>If you have granted us access to your social network account, you can revoke this access at any time by clicking the delete button next to the account in your app. For example, if you have authorized us to access your information through the YouTube API services, you can revoke our access to your data via the Google security settings page at Google Security Settings.</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Further Information about Personal Data</h2>
                        <h3>General Data Protection Regulation (GDPR) Compliance </h3>
                        <p>This app stores users' private information, such as names and email addresses, as well as users' uploaded graphics (images, videos, music files) and text-to-speech (TTS) data. Users can download their data by selecting the "Download Your Data" option</p>
                        <p>If users wish to stop the processing of their private data on this app, they can do so by clicking the "Stop Processing My Data" button. Please note that this action will disable their account, rendering the app unusable. To reactivate the account, users must contact our support team at support@dotasks.in.</p>
                        <p>If users wish to delete all their private data, they can do so by clicking the "Delete My Data" button. This action will permanently erase all data from our servers and is irreversible. Once deleted, users cannot use the app further, and this step cannot be undone by support. To use the app again, users will need to start from scratch.</p>
                        <p>Users have complete control over their data and can download, deactivate, or delete it through options available under the privacy settings or profile settings of the app.</p>
                        </div>
                        <div className='submission_wrap  mb-4'>
                        <h2>Selling Goods and Services Online</h2>
                        <p>The personal data collected are used to provide users with services or to sell goods, including handling payments and delivery.</p>
                        <p>To complete a payment, the personal data collected may include credit card information, bank account details, or other payment methods. The specific type of data collected depends on the payment system used by this application.</p>
                        <p><strong>Contact Information</strong> <br/>
            <strong>Owner and Data Controller</strong> DoTask <br/>
            <strong>Owner Contact Email:</strong> support@dotasks.in
           </p>
                        </div>
</div>
                </div>
            </div>
            <DashboardFooter />
        </>
    )
}

export default PrivacyPolicy

import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState, useEffect } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { IoLocationOutline } from 'react-icons/io5'
import { PiUserCircle } from 'react-icons/pi'
import frame from '../../../images/icon-2/Frame.svg'
import { BsDot } from "react-icons/bs";
import Small from '../../../images/icon-2/Small.png';
import { IoIosStarOutline } from 'react-icons/io'
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../Common/Pagination";
import { ProgressBar } from 'primereact/progressbar';
import AdminCreatorNav from './AdminTaskDeatils/AdminCreatorNav'
import { Rating } from "primereact/rating";
import DashboardFooter from '../../Common/DashboardFooter'

const AdminReviews = (props) => {

    const [reviewData, setReviewData] = useState([])

    useEffect(() => {
        if (props.data) {
            setReviewData(props?.data)
        }
        else {
            setReviewData([])
        }
    }, [props.data])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = reviewData.slice(indexofFirstTodo, indexofLastTodo);

    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
<>
                    <ScrollPanel className="custombar2 content-area manage-users pt-2 p-4">
                        <div className="main_task_details ps-3 pe-4 pb-4">
                            <div className="do_task_wrapper ">
                                {/* <AdminCreatorNav data={props.profiledata} /> */}
                                <div className="row">
                                    <div className="account-block ex">
                                        <h6 className="d-flex align-items-center pb-3">
                                            {/* <img src={require("../../../images/icon/star2.svg").default} alt="" /> <span className="ps-2">5.0 - 7 reviews</span> */}
                                            <Rating className='rating-color' value={props?.averagerating || 0} readOnly cancel={false} allowHalf />
                                            <div style={{ fontSize: "16px" }} className="ps-3">{props?.averagerating || 0} - {props?.totalreview || 0} reviews</div>
                                        </h6>

                                        <div className="review-list mt-2 mx-2">
                                            <ul>
                                                {
                                                    currentTodo?.length > 0 ? currentTodo?.map((curElem, i) => {
                                                        const reviewRating = JSON.parse(curElem.reviewratings)

                                                        return (
                                                            <div className='border-bottoms' key={i}>
                                                                <li>
                                                                    <div className="review-list-single">
                                                                        <div className="review-list-single-top">
                                                                            <div className="review-list-single-img"><img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                                                                            <div className="review-list-single-txt">
                                                                                <h4>{curElem.name}</h4>
                                                                                <h4>{curElem.title}</h4>
                                                                                <h6>{curElem.reviewdate}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <p className="pt-3">{curElem.message}</p>
                                                                    </div>

                                                                </li>
                                                                <div className="rev-card mb-3">
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Availability</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.availability} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.availability}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Cooperation</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.copperation} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.copperation}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Communication</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.communication} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.communication}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Quality of work</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.work_quality} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.work_quality}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Skills</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.skillset} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.skillset}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="rev-card-single">
                                                                                <h6>Adhere to deadline</h6>
                                                                                <div className="rev-card-stat">
                                                                                    <div className="rev-card-percentage">
                                                                                        <ProgressBar value={20 * reviewRating.deadline} style={{ height: "20px" }} />
                                                                                    </div>
                                                                                    <div>{reviewRating.deadline}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="d-flex justify-content-center align-items-center w-100">No Review Recived</div>
                                                }
                                            </ul>                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Pagination
                                listData={reviewData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={reviewData}
                                loader={props.loader.fetch}
                            />
                        </div>
                    </ScrollPanel>
                    <DashboardFooter/>
</>
            }

        </>
    )
}

export default AdminReviews

import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IoSearchOutline } from 'react-icons/io5';
import { ScrollPanel } from 'primereact/scrollpanel';
import { LuDownload } from 'react-icons/lu';
import event from '../../../images/icon/events.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PayoutPDF from './PayoutPDF'; // Import the PDF component

import { FaSpinner } from 'react-icons/fa';
import RaisedModel from '../ModalComponents/RaisedModel';
import CusstomPagination from '../../Common/CusstomPagination';
import { fetchEarningsData1 } from '../../../Redux/Actions/EarningsAction';
import { useDispatch } from 'react-redux';


const PayoutHistory = () => {

    const [searchInput, setSearchInput] = useState('')

    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])
    const [totalLength, setTotalLength] = useState(0);
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(
        {
            fetch: false,
        }
    )

    const [raisedId, setRaisedId] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setRaisedId(id)
    }





    const handlesort = () => {

        const sortedData = [...filteredData].sort((a, b) => {
            const dateA = new Date(a.withdraw_date);
            const dateB = new Date(b.withdraw_date);

            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data?.myearning) {
            if (searchInput) {

                const newFilteredData = data?.myearning?.filter(val => {
                    return (String(val.amount || '').includes(searchInput.toLowerCase()) ||
                        String(val.id || '').includes(searchInput.toLowerCase())
                    )
                }
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data?.myearning);
            }
        }
    }, [searchInput, data.myearning]);

    useEffect(() => {
        if (data?.myearning) {
            setFilteredData(data?.myearning)
        } else {
            setFilteredData([])
        }
        if (data?.total_count) {
            setTotalLength(data?.total_count)
        }
    }, [data?.myearning])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1
        }

        dispatch(fetchEarningsData1("fetch-history", formData, setData, setLoader, loader))
    }

    useEffect(() => {

        fetchData()
    }, [pagination?.currentPage]);


    return (
        <>
            <ScrollPanel className="custombar2 earnings_payouts content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <div className="table-bar">
                        <div className="table-bar-left">
                            <div className="table-bar-icon">
                                <div className="table-nav">
                                    <div className="profile-menu">
                                    </div>
                                    {/* <div className="profile-menu">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="successN" className="drop-btn">
                                                <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet
                                                </li>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet.
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                </div>
                                <ul>
                                    <li title="Sort by Date" >
                                        <img src={require("../../../images/icon/sort.svg").default} alt="" onClick={handlesort} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="table-bar-right">
                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    name="searchInput"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <span className="left-icon"><IoSearchOutline /></span>
                            </div>
                        </div>
                    </div>

                    <div className="account-block bg-transparent mt-2 pt-2">
                        {loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className='spin ms-1' style={{ fontSize: "40px" }} /></div> :
                            <div className="table-responsive earnings_table mt-1">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            {/* <th>
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </th> */}
                                            <th>S.N.</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                            <th>Payout Amount</th>
                                            <th>Remaining Balance</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Paid Status</th>
                                            <th className='text-center'>Action</th>


                                            {/* <th>Invoice</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? filteredData.map((curElem, i) => {
                                            const bankDetails = JSON.parse(curElem.payment_detail || null)
                                            // console.log("bank",bank)

                                            return (
                                                <tr key={i}>
                                                    {/* <td data-label="">
                                            <span className="custom-check">
                                                <input
                                                    type="checkbox"
                                                />
                                                <span className="checkMark"></span>
                                            </span>
                                        </td> */}

                                                    <td data-label="Transaction ID">{i + 1}</td>
                                                    <td data-label="Description">Bank Transfer for ₹{curElem.amount} to {curElem.payment_mode}</td>
                                                    <td data-label="Date"><img src={event} alt="" />{curElem.created}</td>
                                                    <td data-label="Payout Amount">₹{curElem.amount}</td>
                                                    <td data-label="Remaining Balance">₹{curElem.balance}</td>
                                                    <td data-label="Status">
                                                        {curElem.status === "2" ? <div className='earn_status complete' style={{ backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Approved</div> : curElem.status === "3" ? <div className='earn_status' style={{ backgroundColor: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status' style={{ backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</div>}
                                                    </td>
                                                    <td data-label="Paid Status">
                                                        {curElem.is_payed === "1" ? <div className='earn_status complete' style={{ backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Paid</div> : curElem.is_payed === "2" ? <div className='earn_status' style={{ backgroundColor: "#f8d7da", color: "#721c24" }}>Payment failed</div> : <div className='earn_status' style={{ backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Unpaid</div>}
                                                    </td>
                                                    {curElem.is_payed === "1" ? <td>  <button className='theme-btn' onClick={() => handleShow(curElem.id)}>Raised Query</button></td> : ""}
                                                    {/* {
                                                        curElem.status === 1 ?
                                                            <td data-label="Invoice">
                                                                <PDFDownloadLink
                                                                    document={<PayoutPDF data={curElem} />}
                                                                    // fileName="payout_history.pdf"
                                                                    fileName={`payout_history_${i}.pdf`}
                                                                >
                                                                    {({ loading }) => (loading ? 'Loading document...' : <><LuDownload /> PDF</>)}
                                                                </PDFDownloadLink>
                                                            </td>
                                                            : ""
                                                    } */}

                                                </tr>
                                            )
                                        }) : <td className='text-center py-3' colSpan={7}>{data?.myearning?.length === 0 ? "No histroy found" : ""}</td>}

                                    </tbody>
                                </table>

                                <div className='my-4'>
                                    <CusstomPagination
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        totalLength={totalLength}
                                        loader={loader.fetch}
                                    />
                                    {/* <Pagination

                                        listData={filteredData}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        currentTodo={currentTodo}
                                        listArr={data}
                                        loader={props.loader.fetch}
                                    /> */}
                                </div>
                            </div>
                        }
                    </div>
                    <RaisedModel
                        show={show}
                        handleClose={handleClose}
                        raisedId={raisedId}
                    />
                </div>
            </ScrollPanel >
        </>
    );
}

export default PayoutHistory;

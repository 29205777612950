import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logoutAllDevice, onDestroySecurity, onFetchSecurity } from '../../../Redux/Actions/AuthActions'
import { FaSpinner } from 'react-icons/fa'
import { ScrollPanel } from 'primereact/scrollpanel'
import DashboardFooter from '../../Common/DashboardFooter'

const AdminSecurity = () => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [id, setId] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        save: false
    })
    const handleAllAccountLogOut = () => {

        dispatch(logoutAllDevice())
    }
    const fetchData = () => {
        dispatch(onFetchSecurity(setData, loader, setLoader))
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleDestroy = (id) => {
        setId(id)
        setLoader({
            ...loader,
            save: true
        })
        let data = {
            "session_id": id
        }
        dispatch(onDestroySecurity(data, loader, setLoader, fetchData))

    }

    return (
        <>{
            loader.fetch ? <FaSpinner className='spin ms-1 w-100 d-flex mt-4' style={{ fontSize: "40px" }} /> :
                <>
                    <ScrollPanel className="custombar2 content-area pt-2 manage-users">
                        <div className="table-responsive mt-3 account-block Task ">
                            <table className="table theme-table">
                                <thead>
                                    <tr>
                                        <th>Login Time</th>
                                        {/* <th>Logout Time</th> */}
                                        <th>Ip Address</th>
                                        <th>Location</th>
                                        <th>System Information</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        data?.length > 0 ? data?.map((curElem, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td data-label="Login Time">{curElem?.created}</td>
                                                    {/* <td data-label="Logout Time">{curElem?.modified}</td> */}
                                                    <td data-label="Ip Address">{curElem?.ip}</td>
                                                    <td data-label="Location">{curElem?.location}
                                                    </td>
                                                    <td data-label="System Information">{curElem?.system}
                                                    </td>
                                                    <td data-label="Action">
                                                        <div className='common-btn badges' onClick={() => handleDestroy(curElem?.id)} style={{ cursor: "pointer", }}>Destroy{curElem.id === id ? loader.save ? <FaSpinner className="spin ms-1" /> : null : ""}</div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                            : ""
                                    }


                                </tbody>
                            </table>
                        </div>
                        <div className="account-block mt-4 mb-5">
                            <h6 className="pb-3">Security</h6>
                            <div className="supportBlock" onClick={handleAllAccountLogOut}>
                                <h6>Log out of all devices</h6>
                                <p>Log out of all other active sessions on other devices besides this one.</p>
                            </div>
                            <div className="supportBlock">
                                <h6 className="txt-red">Delete my account</h6>
                                <p>Permanently delete the account and remove access from all devices.</p>
                            </div>
                        </div>
                    </ScrollPanel>
                    {/* <DashboardFooter /> */}
                </>
        }


        </>
    )
}

export default AdminSecurity

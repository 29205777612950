import { Rating } from '@mui/material'
import React from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'

const ViewTaskUserDetails = ({ userDetails }) => {
    return (
        <>
            <div className="side_task_wrapper task_details_side_wrapper">

                <div className="account-block top mt-1 bg-transparent text-center ">
                    <h6> About User</h6>
                </div>
                <div className="account-block mx-2 mx-lg-0 about_box text-center" style={{ paddingBottom: "50px" }}>

                    <div className="about_img">
                        <img className='img-fluid' src={userDetails?.profile ? userDetails?.profile : "https://backend.dotasks.in/public/uploads/profile/user.png"} alt="" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <h6 className='mt-3'>{userDetails?.name}</h6>
                        <h6 style={{ color: "rgba(28, 28, 28, 0.80)" }}>{userDetails?.email}
                        </h6>
                        <h6 style={{ color: "rgba(28, 28, 28, 0.80)" }}>{userDetails?.mobileNumber}
                        </h6>
                        <span>{userDetails?.created}</span>
                    </div>

                </div>

            </div></>
    )
}

export default ViewTaskUserDetails

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'primereact/dropdown';
import { FaSpinner } from 'react-icons/fa';


function AdminNotificationModal(props) {



    return (
        <>
            <Modal className="theme-modal forgotpass-moda creator_new_task link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span>  */}
                        <h3 className="m-auto" style={{ fontSize: "25px" }}>Add Notification
                        </h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span>    */}

                    </div>
                    <form onSubmit={props.handleSubmit}>
                        <div className="modal-card mt-4 ">
                            <div className='account-block mb-0 px-1 py-1'>
                                <div className='submission_wrap submission_bg mt-3 mb-3'>
                                    <label htmlFor="" class="form-label">Message
                                    </label>
                                    <input style={{ padding: "8px" }}
                                        className="border-0 w-100"
                                        type="text"
                                        placeholder="Enter Message"
                                        value={props.data.message}
                                        onChange={props.onChangehandle}
                                        name="message"
                                        required

                                    />
                                </div>
                            </div>
                            <div className="link_btn text-end my-2">
                                <button className='theme-btn saveBtn ms-auto me-2 d-flex' type="submit" style={{ padding: "8px 24px" }}>Submit {props.loader.save ? <FaSpinner className="spin ms-1" /> : null}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>
    )

}
export default AdminNotificationModal
import React, { useEffect, useState } from 'react';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import TitleBar from '../../Common/TitleBar';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import queryString from "query-string"
import { fetchSumissionDetails } from '../../../Redux/Actions/AdminDashboardActions';
import AdminBanModel from '../AdminModal/AdminBanModel';
import AdminActiveModel from '../AdminModal/AdminActiveModel';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from 'react-icons/fa';
import { adminSubmissionApprove, adminSubmissionReject } from '../../../Redux/Actions/AdminManagerNavAction';


const AdminTaskSubmissionDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false,
        banAccount: false,
        active: false
    })
    const [task_detail, setTask_detail] = useState([])
    const [user_detail, setUser_detail] = useState([])
    const id = queryString.parse(location.search).id;
    const [isActive, setIsActive] = useState(false);
    const [data, setData] = useState([])
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const [isActive2, setIsActive2] = useState(false);
    const toggleNotification = () => {
        setIsActive2(!isActive2);


    };
    const [submissionId, setSubmissionId] = useState('')
    const [reason, setReason] = useState('')
    const [showModel, setShowModel] = useState(false)
    const [showModel1, setShowModel1] = useState(false)
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)

    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "submission_id": id
        }
        dispatch(fetchSumissionDetails(formData, setData, loader, setLoader, setTask_detail, setUser_detail))
    }

    useEffect(() => {
        fetchData()
    }, [id])

    const handleApprove = () => {
        setLoader({
            ...loader,
            banAccount: true

        })
        let data = {
            "id": submissionId
        }


        dispatch(adminSubmissionApprove(data, loader, setLoader, handleClose1, fetchData))
    }
    const handleReject = () => {
        setLoader({
            ...loader,
            active: true

        })
        let data = {
            "id": submissionId,
            "reason": reason
        }

        if (reason === '') {
            setLoader({
                ...loader,
                active: false

            })
            dispatch(setAlert("Reason  is requried", "danger"))
        } else {

            dispatch(adminSubmissionReject(data, loader, setLoader, handleClose, fetchData))
        }
    }
    return (
        <>

            <TitleBar title="Users" />
            <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
                <SidePanelAdmin type="url" />

                <div className="dashboard-in">
                    <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Manage Users" />
                    <ScrollPanel className="custombar2 content-area submission-task-height">
                        {
                            loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div> :
                                <div className="content-area-in tab-style ">
                                    <div className='row mt-4 mt-lg-0'>
                                        <div className='col-lg-5 col-xl-4 col-md-5 mb-3'>
                                            <div className="account-block h-100  d-flex flex-column">
                                                <h6 className='mb-3'>User Details</h6>
                                                <div className='user-profile d-flex align-items-center gap-2 justify-content-center'>
                                                    <img className='img-fluid' src={user_detail?.profile ? user_detail?.profile : "https://plus.unsplash.com/premium_photo-1672239496290-5061cfee7ebb?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="" />
                                                </div>
                                                <div className='admin_profile_details mt-2 w-100'>
                                                    <div class="row mb-2 submission-user-details">
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <label for="staticEmail" className="">Name</label>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <p>{user_detail?.name}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2 submission-user-details">
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <label for="staticEmail" className="">Date</label>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <p>{user_detail?.created}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2 submission-user-details">
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <label for="staticEmail" className="">Phone Number</label>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-6 col-xl-6'>
                                                            <p>{user_detail?.mobileNumber}</p>
                                                        </div>

                                                    </div>
                                                    {/* <div className='profileDetails'>
                                                <ul>
                                                    <li className='mt-0 mb-2 justify-content-center'><span className='w-100'>Phone number <span className="tooltipN"><AiOutlineExclamationCircle /><span className="tooltiptext">Phone Number</span></span></span><span className="">5263587452</span></li>

                                                </ul>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-xl-8 col-md-7 mb-3'>
                                            <div className='account-block h-100 '>
                                                <h6 className='mb-3'>Task Details</h6>
                                                <div className='admin_profile_details submissions_task_details mt-3 w-100'>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Title</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p>{task_detail?.title}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Price</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p> ₹ {task_detail?.price}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Due-Date</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p>{task_detail?.due_date}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Caption</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p>{task_detail.caption}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">HashTag</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p>{task_detail.hashtag}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Url</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <p>{task_detail.url}</p>
                                                        </div>

                                                    </div>
                                                    <div class="row mb-2">
                                                        <div className='col-lg-4 col-sm-4'>
                                                            <label for="staticEmail" className="">Description</label>
                                                        </div>
                                                        <div className='col-lg-8 col-sm-8'>
                                                            <ScrollPanel style={{ width: '100%', height: '100px' }}>

                                                                <p dangerouslySetInnerHTML={{ __html: task_detail.description }} />


                                                            </ScrollPanel>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mx-3 py-1'>
                                        <div className="table-responsive mt-3 mb-5">
                                            <table className="table theme-table">
                                                <thead>
                                                    <tr>
                                                        <th>S.NO</th>
                                                        <th>Type</th>
                                                        <th>Proof</th>
                                                        <th>Created Date</th>
                                                        <th>modified Date</th>
                                                        <th>Status</th>

                                                        <th className='text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                {data.length > 0 ? data.map((curElem, i) => {

                                                    return (
                                                        <tr key={i}>
                                                            <td data-label="S.NO">{i + 1}</td>
                                                            <td data-label="Type">{curElem.proof_type}</td>
                                                            <td data-label="Proof">{curElem.proof}</td>
                                                            <td data-label="Created Date">{curElem.created}</td>
                                                            <td data-label="modified Date">{curElem.modified}</td>


                                                            <td data-label="Status">
                                                                {curElem.status === "2" ? <div className='earn_status complete' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Approved</div> : curElem.status === "3" ? <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</div>}
                                                            </td>
                                                            <td data-label="Action">
                                                                <div className='d-flex justify-content-end gap-2'>
                                                                    {curElem.status === "1" ?
                                                                        <>
                                                                            <p className="theme-btn"
                                                                                onClick={() => {
                                                                                    handleShow1()
                                                                                    setSubmissionId(curElem.id)
                                                                                }}
                                                                            >
                                                                                Approval</p>
                                                                            <p className='theme-btn'
                                                                                onClick={() => {
                                                                                    handleShow()
                                                                                    setSubmissionId(curElem.id)
                                                                                }}
                                                                            >
                                                                                Rejected</p>
                                                                        </>
                                                                        : curElem.status === "2" ?
                                                                            <p className='theme-btn'
                                                                                onClick={() => {
                                                                                    handleShow()
                                                                                    setSubmissionId(curElem.id)
                                                                                }}
                                                                            >
                                                                                Rejected</p> :
                                                                            <p className="theme-btn"
                                                                                onClick={() => {
                                                                                    handleShow1()
                                                                                    setSubmissionId(curElem.id)
                                                                                }}
                                                                            >
                                                                                Approval</p>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                    <td className="text-center pt-3" colSpan={7}> {data.length === 0 ? "No Task Found" : ""}</td>
                                                }


                                            </table>
                                        </div>
                                    </div>
                                </div>
                        }

                    </ScrollPanel>
                    <DashboardFooter />
                </div>

            </div>
            <AdminBanModel show={showModel} handleClose={handleClose} setReason={setReason} reason={reason} loader={loader} handleBanAccount={handleReject} type="submission" />
            <AdminActiveModel show={showModel1} handleClose={handleClose1} loader={loader} handleBanAccount={handleApprove} type="submission" />



        </>
    )
}

export default AdminTaskSubmissionDetails

import React, { useState } from "react";

function RolesPermissionsTabs() {

    return(
        <>

          <div className="account-block">
            <h6>Roles and Permissions</h6>
          </div>

        </>
    )
}

export default RolesPermissionsTabs;
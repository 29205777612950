import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { FaSpinner } from 'react-icons/fa'
import { fetchASocialActionTasker, socialAction } from '../../../Redux/Actions/AuthActions'

const SocialLink = () => {
    const [social, setSocial] = useState("")
    const dispatch = useDispatch()
    const [type, setType] = useState("")
    const [loader, setLoader] = useState(false)
    const [socialLink, setSocialLink] = useState([])
    const handleChange = (e, type) => {

        setSocial(e.target.value)
        setType(type)

    }
    const fetchSocial = () => {
        dispatch(fetchASocialActionTasker(setLoader, setSocialLink))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        // const urlRegex = /^(https?:\/\/|ftp:\/\/|www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
        const regexMap = {
            facebook: /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/,
            twitter: /^https?:\/\/(www\.)?(twitter\.com|x\.com)\/[a-zA-Z0-9_]+/,
            instagram: /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.]+/,
            youtube: /^https?:\/\/(www\.)?(youtube\.com\/(@[a-zA-Z0-9_-]+|(watch\?v=|channel\/|user\/)[a-zA-Z0-9_-]+)|youtu\.be\/[a-zA-Z0-9_-]+)/,
        };
        if (!regexMap[type].test(social.trim())) {
            setLoader(false)
            setSocial("")
            setType("")
            dispatch(setAlert(`The provided ${type} URL is invalid.`, "danger"))
            return
        }
        let data = {

            "type": type,
            "url": social

        }
        dispatch(socialAction(data, setLoader, setSocial, setType, fetchSocial))



    }

    useEffect(() => {
        fetchSocial()
    }, [])
    return (
        <div className='creator_new_task h-100'>
            <div className='account-block phone-emails mb-5'>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Facebook</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="https://facebook.com/username" name="facebook" value={type === "facebook" ? social : ""} onChange={(e) => handleChange(e, "facebook")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button class="theme-btn px-4" type="submit"  >Update{type === "facebook" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">YouTube</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="https://www.youtube.com/@username" name="youtube" value={type === "youtube" ? social : ""} onChange={(e) => handleChange(e, "youtube")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "youtube" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Twitter</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="https://x.com/username" name="twitter" value={type === "twitter" ? social : ""} onChange={(e) => handleChange(e, "twitter")} />
                    </div>
                    <div class="modal-button-bottom mb-4" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "twitter" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                </form>
                <form onSubmit={handleSubmit}>
                    <div className="submission_wrap submission_bg mt-3 mb-4" style={{ height: "115px" }}>
                        <label htmlForfor="" className="form-label fs-5 fw-semibold">Instagram</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="https://instagram.com/username" name="instagram" value={type === "instagram" ? social : ""} onChange={(e) => handleChange(e, "instagram")} />
                    </div>
                    <div>
                        <div class="modal-button-bottom mt-3" style={{ background: "unset" }}><button className="theme-btn px-4" type="submit"  >Update {type === "instagram" && loader ? <FaSpinner className="spin ms-1" /> : null} </button></div>
                    </div>
                </form>
            </div>
            <div className="table-responsive mt-3 mb-5 px-2">
                <table className="table theme-table">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Type</th>
                            <th>Link</th>
                        </tr>
                    </thead>

                    {socialLink.length > 0 ? socialLink.map((curElem, i) => {
                        return (
                            <tr key={i}>
                                <td data-label="S.NO">{i + 1}</td>
                                <td data-label="Type">{curElem.type}</td>
                                <td data-label="Link"><a href={curElem.url} target='_blank'>{curElem.url}</a></td>

                            </tr>

                        )
                    }) : <td className='py-3  text-center' colSpan={7}>No social account</td>}
                </table>
            </div>
        </div>
    )
}

export default SocialLink

import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";

export const EmailNotificationSend = (url, formData, setLoader, loader, fetchEmail) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchEmail()
                dispatch(setAlert(res.msg, "success"));
            }
            else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)

        })
}
export const EmailNotificationFetch = (url, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios(url, {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)

            }
            else {
                console.log(res)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)

        })
}
export const AddNotificationSend = (url, formData, setLoader, loader, fetchEmail, handleClose) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchEmail()
                dispatch(setAlert(res.msg, "success"));
            }
            else {
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose()
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)

        })
}
export const deleteNotificationSend = (url, formData, setLoader, loader, fetchEmail) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchEmail()
                dispatch(setAlert(res.msg, "success"));
            }
            else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                delete: false
            })
        }).catch((err) => {
            console.log(err)

        })
}

/*    onbording api */

export const onBordingFetchData = (url, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios(url, {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)

            }
            else {
                console.log(res)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const onBordingSave = (url, formData, setLoader, loader, fetchEmail, setDescription, setUrl) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchEmail()
                dispatch(setAlert(res.msg, "success"));

            }
            else {
                dispatch(setAlert(res.msg, "danger"));
            }
            // setDescription(''),
            //     setUrl('')
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)

        })
}
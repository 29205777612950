import { ScrollPanel } from 'primereact/scrollpanel'
import React from 'react'

const CommonStepperContent = () => {
    return (
        <>
            <div className='account-block mb-4 mt-4' style={{ minHeight: "490px" }}>
                <div style={{ position: 'relative', width: '100%', height: '40vh' }}>
                    <div className='d-flex step-btn align-items-center gap-2 mb-4'>
                        <button className='theme-btn step-inactive'>Hindi</button>
                        <button className='theme-btn'>English</button>
                    </div>
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://reeelapps-app.s3.us-west-2.amazonaws.com/20002b252-7970-4c70-aca8-b27e21a292b2video.mp4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow=""
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{ border: 'none', display: 'block' }}
                    ></iframe>
                </div>                        </div>
            <div className='account-block'>
                <h6 className='mb-2'>Description:</h6>
                <ScrollPanel style={{height:"100px"}}>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus dolores maiores ut, fuga ipsa illo dicta qui harum incidunt nesciunt reprehenderit omnis corrupti quibusdam, iste optio magnam dolorem nam, repellat laboriosam. Eius alias labore tenetur, non et sequi eveniet. Consequuntur maiores harum dolore tempore atque nisi ut commodi corrupti veniam ea expedita debitis dolorum omnis excepturi est quos provident modi delectus id iste exercitationem, ipsa similique. Aspernatur officiis molestiae iusto veritatis possimus est perferendis facilis ducimus vero corporis consequuntur rerum distinctio totam non iure dolore quibusdam minus, facere saepe error dolores. Ab recusandae nesciunt molestias accusamus asperiores aliquam maxime libero explicabo ex laudantium repudiandae, repellendus aliquid facere ducimus odio totam veritatis alias modi animi nulla quo. Suscipit nobis, ea reprehenderit dolore nesciunt molestias veritatis adipisci commodi facilis aliquid dolorem illo fugit eveniet iusto necessitatibus porro aspernatur ad repudiandae eum harum magni. Autem inventore facere nam doloribus, reiciendis assumenda rem? Debitis dolores odio numquam, mollitia molestiae voluptates ratione esse! Ipsa, excepturi rem. Excepturi accusamus reiciendis dolorum dolore veritatis id, culpa quia nisi reprehenderit quam eaque? Quisquam, minus! Cum ipsam nisi quibusdam itaque magnam quia similique suscipit iste praesentium. Porro cumque mollitia et tempora accusantium, explicabo quisquam sunt, maiores, dolor numquam quia!</p>

                    </ScrollPanel>
            </div>
        </>
    )
}

export default CommonStepperContent

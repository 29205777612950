import React from 'react'
import DashboardFooter from './DashboardFooter'
import Navbar from './Navbar'

const TermsOfServices = () => {
    return (
        <>
            <Navbar />
            <div className='container-fluid privacy-policy my-5'>
                <div className='account-block mb-4'>
                    <h1 className='text-center my-4'>Terms of Service</h1>
                </div>
                <div className='account-block mb-4'>
                    <div className='col-lg-12'>
                        <div className='account-block'>
                            <div className='submission_wrap  mb-4'>
                                <h2>Website Terms and Conditions of Use</h2>
                                <h3>1. Terms</h3>
                                <p>By accessing this website, you agree to be bound by these Terms and Conditions of Use, all applicable laws and regulations, and accept responsibility for compliance with any relevant local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials on this website are protected by applicable copyright and trademark laws.</p>
                                <h3>2. Use License</h3>
                                <p>Permission is granted to temporarily download one copy of the materials (information or software) on the DoTask website for personal, non-commercial, transitory viewing only. This constitutes a license, not a transfer of title, and under this license, you may not:</p>
                                <ul className='lists'>
                                    <li>Modify or copy the materials;</li>
                                    <li>Use the materials for any commercial purpose or for any public display (commercial or non-commercial);</li>
                                    <li>Attempt to decompile or reverse engineer any software on the DoTask</li>
                                    <li>Remove any copyright or other proprietary notations from the materials; or</li>
                                    <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                                </ul>

                                <p>This license will automatically terminate if you violate any of these restrictions and may be terminated by DoTask at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession, whether in electronic or printed format.</p>

                                <p>All “one-time” or “single” payment-based licenses include 12 months of support and updates. We do not offer support or updates after 12 months unless you have purchased an additional support extension or are a monthly subscriber. You can retain access to the product beyond the initial 12-month period and use it as it is.</p>

                                <h3>3. Disclaimer</h3>
                                <p>The materials on the DoTask website are provided "as is”. DoTask makes no warranties, express or implied, and hereby disclaims and negates all other warranties, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other rights violations. Furthermore, DoTask does not warrant or make any representations regarding the accuracy, likely results, or reliability of the use of the materials on its website or otherwise related to such materials or on any sites linked to this site.</p>

                                <h3>4. Limitations</h3>
                                <p>In no event shall DoTask or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising from the use or inability to use the materials on the DoTask website, even if DoTask or an authorized representative has been notified orally or in writing of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations of liability for consequential or incidental damages, so these limitations may not apply to you.</p>

                                <h3>5. Revisions and Errata</h3>
                                <p>The materials on the DoTask website may include technical, typographical, or photographic errors. DoTask does not guarantee that any materials on its website are accurate, complete, or current. DoTask may make changes to the materials on its website at any time without notice but does not commit to updating the materials.</p>

                                <h3>6. Links</h3>
                                <p>DoTask has not reviewed all of the sites linked to its website and is not responsible for the contents of any linked site. The inclusion of any link does not imply endorsement by DoTask of the site. Use of any such linked website is at the user's own risk.</p>

                                <h3>7. Site Terms of Use Modifications</h3>
                                <p>DoTask may revise these Terms and Conditions of Use for its website at any time without notice. By using this website, you agree to be bound by the then-current version of these Terms and Conditions of Use.</p>

                                <h3>8. Governing Law</h3>
                                <p>Any claim relating to the DoTask website shall be governed by the laws of the United States, without regard to its conflict of law provisions.</p>
                                <p>General Terms and Conditions applicable to the use of a website.</p>

                                <h3>9. Social Networks and Third-Party Services</h3>
                                <p>When using our Services and connecting your social networks or authorizing a third-party service to access your account, you agree to provide information to these social networks and third-party services under their respective terms and privacy policies. For example, if you connect your YouTube account to the Services, this connection uses YouTube’s API services, and the Google Privacy Policy at <a target="_blank" href="https://www.youtube.com/t/terms">YouTube Terms of Service</a> and <a target="_blank" href="https://policies.google.com/privacy">Google Privacy Policy</a> will apply</p>

                                <p>If you have authorized us to access your social network account, you can revoke this access at any time by clicking the delete button next to it in your app. For instance, if you have authorized us to access your information via the YouTube API services, you can revoke our access to your data through the Google security settings page at <a target="_blank" href="https://security.google.com/settings/security/permissions">Google Security Settings</a>.</p>

                                <h3>Privacy Policy</h3>
                                <p>Your privacy is very important to us. We have developed this policy to help you understand how we collect, use, communicate, disclose, and manage personal information. The following outlines our privacy policy:</p>
                                <ul className='lists'>
                                    <li><strong>Purpose Identification:</strong> Before or at the time of collecting personal information, we will identify the purposes for which the information is being collected.</li>
                                    <li><strong>Use and Collection:</strong> We will collect and use personal information solely for the purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
                                    <li><strong>Data Retention:</strong> We will only retain personal information as long as necessary for the fulfillment of those purposes.</li>
                                    <li><strong>Lawful Collection:</strong> We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
                                    <li><strong>Data Relevance:</strong> Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
                                    <li><strong>Security Safeguards:</strong> We will protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification.</li>
                                    <li><strong>Transparency:</strong> We will make readily available to customers information about our policies and practices relating to the management of personal information.</li>
                                </ul>
                                <p>We are committed to conducting our business in accordance with these principles to ensure that the confidentiality of personal information is protected and maintained.</p>
                                <p><strong>COPYRIGHT NOTICE. © 2024 Prepared for: DoTask, All rights reserved.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </>
    )
}

export default TermsOfServices

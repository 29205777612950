import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { IoEyeOffOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { onUpdatePassword } from '../../../Redux/Actions/AuthActions';
import { onUpdatePasswordCreatorAdmin } from '../../../Redux/Actions/AdminDashboardActions';
import { ScrollPanel } from 'primereact/scrollpanel';
import DashboardFooter from '../../Common/DashboardFooter';

const AdminCreatorPassword = (props) => {
    const regpass = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,16}$/;
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()
    const [update, setUpdate] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setUpdate({
            ...update,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        if (regpass.test(update?.password) === false) {
            dispatch(setAlert("Use 8 or more characters with a mix of letters, numbers & symbols ", "danger"));
            setLoader(false)
        }
        else if (update.password !== update.confirmPassword) {
            dispatch(setAlert("Password and confirm password is not equel", "danger"))
            setLoader(false)
        }
        else {
            const updateData = {
                "currentpassword": update.oldPassword,
                "password": update?.password,
                "confirmpassword": update?.confirmPassword,
                "id": props.data?.id

            }


            dispatch(onUpdatePasswordCreatorAdmin(updateData, setLoader))

        }
    }
    return (
        <>
        <ScrollPanel className='custombar2 content-area manage-users'>
            <div className='creator_new_task h-100 '>
                <div className='account-block phone-emails mb-3'>
                    <form onSubmit={handleSubmit}>
                        <div className="submission_wrap submission_bg mt-3 mb-3">
                            <label htmlFor="currentPassword" className="form-label">Current Password</label>
                            <input type="password" className="form-control" id="currentPassword" placeholder="Enter current password"
                                name="oldPassword"
                                value={update.oldPassword}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="submission_wrap submission_bg mt-3 mb-3">
                            <label htmlFor="newPassword" className="form-label">New Password</label>
                            <input type="password" className="form-control" id="newPassword" placeholder="Enter new password"
                                name="password"
                                value={update.password}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="submission_wrap submission_bg mt-3 mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
                            <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm new password"
                                name="confirmPassword"
                                onChange={handleChange}
                                value={update.confirmPassword}
                            />
                        </div>

                        <div className="modal-button-bottom" style={{ background: "unset" }}>
                            <button className="theme-btn px-4" type="submit">Update Password{loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                    </form>
                </div>
            </div>
            </ScrollPanel>
            <DashboardFooter/>
        </>
    )
}

export default AdminCreatorPassword

import React, { useState } from 'react'
import NavCreatorTask from './NavCreatorTask'
import DashboardHeader from "../../Common/DashboardHeader";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import TitleBar from '../../Common/TitleBar';

const CreatorTask = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <TitleBar title="Task" />
            <div className="dashboard creator_task_container container-fluid overflow-hidden tab-height">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelTaskCreator Type="url" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in ">

                            <DashboardHeader toggleSidebar={toggleClass} title="Tasks" />

                            <NavCreatorTask />

                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default CreatorTask

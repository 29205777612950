import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { FaSpinner } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { updateNotification } from "../../../Redux/Actions/AuthActions";

function NotificationTabs() {
    const dispatch = useDispatch()
    const notificationData = useSelector(state => state.auth?.user?.notification)

    const [notification, setNotification] = useState({
        web_notification: '0',
        sms_notification: '0',
        email_notification: '0',
        whatsapp_notification: '0',
    });

    const [loader, setLoader] = useState({
        fetch: true,
        save: false,
    });

    useEffect(() => {

        if (notificationData) {
            setNotification({
                web_notification: notificationData.web_notification || '0',
                sms_notification: notificationData.sms_notification || '0',
                email_notification: notificationData.email_notification || '0',
                whatsapp_notification: notificationData.whatsapp_notification || '0',
            });
        }
    }, [notificationData]);
    const handleChange = (e) => {
        const { name, value, checked } = e.target
        setNotification({ ...notification, [name]: checked ? '1' : '0' })
    }
    const handleSubmit = () => {
        setLoader({
            ...loader,
            save: true
        })

        dispatch(updateNotification(notification, setLoader, loader))
    }

    return (
        <>
            <div className="account-block mb-3 mb-lg-5">
                <h6>Notification Settings</h6>
                <div className="block-white mt-4">
                    <h6>Notifications</h6>
                    <div className="notification-cardN">
                        <div className="notification-card-single">
                            <div className="notification-card-txt">
                                <h6>Web push notifications</h6>
                                <p>Receive push notifications on your web app.</p>
                            </div>
                            <span className="custom-switch">
                                <input type="checkbox" name="web_notification" checked={notification.web_notification === '1'} onChange={handleChange} />
                                <span className="checkMark"></span>
                            </span>
                        </div>

                        <div className="notification-card-single">
                            <div className="notification-card-txt">
                                <h6>Email notifications</h6>
                                <p>Receive email updates.</p>
                            </div>
                            <span className="custom-switch">
                                <input type="checkbox" name="email_notification" checked={notification.email_notification === '1'} onChange={handleChange} />
                                <span className="checkMark"></span>
                            </span>
                        </div>

                        <div className="notification-card-single">
                            <div className="notification-card-txt">
                                <h6>SMS notifications</h6>
                                <p>Receive updates in your mobile number.</p>
                            </div>
                            <span className="custom-switch">
                                <input type="checkbox" name="sms_notification" checked={notification.sms_notification === '1'} onChange={handleChange} />
                                <span className="checkMark"></span>
                            </span>
                        </div>

                        <div className="notification-card-single">
                            <div className="notification-card-txt">
                                <h6>Whatsapp notifications</h6>
                                <p>Receive updates in your whatsapp number.</p>
                            </div>
                            <span className="custom-switch">
                                <input type="checkbox" name="whatsapp_notification" checked={notification.whatsapp_notification === '1'} onChange={handleChange} />
                                <span className="checkMark"></span>
                            </span>
                        </div>
                        <div className="btnGroup mt-4"><button type="button" className="theme-btn" onClick={handleSubmit}>Update {loader.save ? <FaSpinner className="spin ms-1" /> : null}</button></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationTabs;
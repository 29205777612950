import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
export const FetchAdminPayOut = (url, formData, loader, setLoader, setData) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data)



            } else {
                console.log(res.msg)

            }

            setLoader({
                ...loader,
                fetch: false,
            })


        }).catch((err) => {
            console.log(err)

        })
}
export const adminApprovedAction = (data, setLoader, handleClose, fetchdata) => (dispatch, getState) => {

    commonAxios("approve-payout-request", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                fetchdata()


            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}
export const adminRejecteddAction = (data, setLoader, handleClose, fetchdata) => (dispatch, getState) => {

    commonAxios("reject-payout-request", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                fetchdata()
            } else {

                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { ADDBankDetail } from '../../../Redux/Actions/PaymentActions';




function StripModal(props) {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')

    const regEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;


    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault()

        if (regEmail.test(email) === false) {
            dispatch(setAlert("Incorrect Email", "danger"))
            return
        }
        let formData = {
            "account": email,
            "type": props?.typeStripe === "Stripe" ? "stripe" : "paypal"
        }
        setEmail('')
        dispatch(ADDBankDetail(formData, setLoader, props.handleClose))

    }
    return (
        <>
            <Modal className="theme-modal creator_new_task forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span>  */}
                        <h3 className="m-auto" style={{ fontSize: "20px" }}>Enter Your {props.typeStripe} Account</h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span> */}

                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-card account-block mt-4 ">
                            <div className='submission_wrap submission_bg mt-3 mb-3'>
                                <label htmlFor="" class="form-label">Email</label>
                                <input style={{ padding: "8px" }}
                                    className="login-input border-0"
                                    type="email"
                                    placeholder="Enter your email "
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    required
                                />

                            </div>
                            <div className="link_btn mt-5 text-end ">
                                <button className='theme-btn saveBtn ms-auto me-3 d-flex' type="submit" style={{ padding: "8px 24px" }}>Submit {loader ? <FaSpinner className="spin ms-1" /> : null}</button>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >

        </>
    )
}
<div className="creator_new_task">
    <div className='account-block'>
        <div className='submission_wrap'>

        </div>
    </div>
</div>
export default StripModal


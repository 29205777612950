import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import AssignTaskModal from './AssignTaskModal';
import { useDispatch } from 'react-redux';
import { fetchAssignActionList, fetchUserList, SubmitUserAssignList } from '../../../Redux/Actions/TaskAssign';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { Dropdown } from 'primereact/dropdown';
import CusstomPagination from '../../Common/CusstomPagination';
import { FaSpinner } from 'react-icons/fa6';
import ConfirmTaskModel from './ConfirmTaskModel';
import { Link } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
const TaskAssign = () => {
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searchInput, setSearchInput] = useState('')

    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState({})
    const [totalLength, setTotalLength] = useState(0);
    const [checkedId, setCheckedId] = useState([])
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [userAssignList, setUserAssignList] = useState([])

    const [EmployeeId, setEmployeeId] = useState(null)
    const dispatch = useDispatch()
    const [deleteShow, setDeleteShow] = useState(false);
    const handleClose2 = () => setDeleteShow(false);
    const handleShow2 = () => setDeleteShow(true);

    const [loader, setLoader] = useState(
        {
            fetch: false,
            assign: false,
            delete: false
        }
    )






    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data?.task_submission) {
            if (searchInput) {

                const newFilteredData = data?.task_submission?.filter(val => {
                    return (String(val.amount || '').includes(searchInput.toLowerCase()) ||
                        String(val.id || '').includes(searchInput.toLowerCase()) ||
                        String(val.name.toLowerCase() || '').includes(searchInput.toLowerCase())
                    )
                }
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data?.task_submission);
            }
        }
    }, [searchInput, data.task_submission]);

    useEffect(() => {
        if (data?.task_submission) {
            setFilteredData(data?.task_submission)
        } else {
            setFilteredData([])
        }
        if (data?.total_count) {
            setTotalLength(data?.total_count)
        }
    }, [data?.task_submission])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: Number(limit?.code) || 20
    });
    const userList = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchUserList("fetch-employee", setUserAssignList, setLoader, loader))
    }
    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1,
            "limit": limit.code
        }

        dispatch(fetchAssignActionList("fetch-tasker-submission", formData, setData, setLoader, loader))
    }

    useEffect(() => {
        fetchData()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })
    }, [pagination.currentPage, limit.code]);
    useEffect(() => {
        userList()
    }, [])
    const handleChange = (e) => {
        const { value, checked } = e.target

        let arr = [...checkedId]
        if (checked) {
            arr.push(value)
        }
        else {
            const indexId = checkedId.indexOf(value)
            if (indexId !== -1) {
                arr.splice(indexId, 1)
            }
        }
        setCheckedId(arr)
    }

    const handleSelectAll = (e) => {
        const { checked } = e.target;

        if (checked) {
            const allIds = filteredData.map((item) => item.id);
            setCheckedId(allIds);
        } else {
            setCheckedId([]);
        }

        setIsAllChecked(checked);
    };
    const handleSubmitAssign = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            assign: false
        })
        if (EmployeeId === null) {
            dispatch(setAlert("Select the employee first", "danger"))
            setLoader({
                ...loader,
                assign: false
            })
            return;
        }
        handleShow2()
    }
    const handlesubmitDelete = () => {
        setLoader({
            ...loader,
            assign: false,
            delete: true,

        })

        let formData = {
            "emp_id": EmployeeId?.code,
            "task_id": checkedId
        }

        dispatch(SubmitUserAssignList("assign-task", formData, setLoader, loader, fetchData, handleClose, setCheckedId, handleClose2))
    }


    return (
        <>
            <ScrollPanel className="custombar2  content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <div className="account-block bg-transparent p-0">
                        <div className="table-bar mobile-table-bar">
                            <div className="table-bar-left">
                                <div className="table-bar-icon">
                                    <div className="table-nav">
                                        <div className="profile-menu">

                                        </div>
                                        {/* <div className="profile-menu">
                    <Dropdown>
                        <Dropdown.Toggle variant="successN" className="drop-btn">
                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="agentType"
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                dolor sit amet
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="agentType"
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                dolor sit amet.
                            </li>


                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                                    </div>


                                    <ul>
                                        <li title="Sort by Date">
                                            <img src={require("../../../images/icon/sort.svg").default} alt="" onClick={handlesort} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="table-bar-right">
                                <div className='d-flex align-items-center gap-3 bar-btns'>
                                    <div className='task-dropdown' style={{ width: "25%" }}>
                                        <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                            placeholder="Select a Limit" className="rounded-2 border-0" />
                                    </div>
                                    <div className="header-search">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            name="searchInput"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}

                                        />

                                        <span className="left-icon"><IoSearchOutline
                                        /></span>
                                    </div>
                                    <div onClick={handleShow} className='theme-btn' style={{ padding: "4px 10px", fontSize: "13px", width: "30%" }}>Assign Task</div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive badge-mob earnings_table mt-3">
                            {loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div> :
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        checked={isAllChecked}
                                                        onChange={handleSelectAll}
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                            </th>
                                            <th>Id</th>
                                            <th>Profile</th>
                                            <th>Task Title</th>
                                            <th>Assigned to</th>
                                            <th>Status</th>
                                            <th>Created</th>
                                            <th>Price
                                            </th>
                                            <th>Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {filteredData.length > 0 ? filteredData.map((curElem, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td data-label="">
                                                        <span className="custom-check">
                                                            <input
                                                                type="checkbox"
                                                                id={curElem.id}
                                                                checked={checkedId.includes(curElem.id)}
                                                                value={curElem.id}
                                                                onChange={(e) => handleChange(e)}
                                                            />
                                                            <span className="checkMark"></span>
                                                        </span>
                                                    </td>

                                                    <td data-label="Id">{curElem.id}</td>
                                                    <td data-label="Task Creator"><span className="userImg"><img src={curElem?.profile ? curElem.profile : "https://backend.dotasks.in/public/uploads/profile/user.png"} alt="" /></span>{curElem.name}</td>
                                                    <td data-label="Task Title">{curElem.title}</td>
                                                    <td data-label="Assigned to">{curElem.assigned_name}</td>

                                                    <td data-label="Status">
                                                        {curElem.status === "2" ? <div className='earn_status complete' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Approved</div> : curElem.status === "3" ? <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</div>}
                                                    </td>
                                                    <td data-label="Created">{curElem.created}</td>
                                                    <td data-label="Price">₹ {curElem.total_amount}</td>
                                                    <td data-label="Action">

                                                        <Link to="/admin/view-submission" state={{ slug: curElem?.submission_id }}><p style={{ color: "#000000d1", fontWeight: "400", cursor: "pointer", height: "25px" }} className="d-flex align-items-center start_task gap-1"><MdOutlineRemoveRedEye style={{ fontSize: "16px" }} />
                                                            View</p></Link>

                                                    </td>
                                                </tr>
                                            )
                                        }) : <td className='text-center mt-3 pt-2' colSpan={7}>No Tasks found</td>}



                                    </tbody>

                                </table>}
                            <div className='my-5'>
                                <CusstomPagination
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    totalLength={totalLength}
                                    loader={loader.fetch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollPanel >
            <AssignTaskModal
                show={show}
                handleClose={handleClose}
                EmployeeId={EmployeeId}
                setEmployeeId={setEmployeeId}
                handleSubmitAssign={handleSubmitAssign}
                loader={loader}
                setLoader={setLoader}
                userAssignList={userAssignList}
            />
            <ConfirmTaskModel show={deleteShow} handleClose={handleClose2} loader={loader} handlesubmitDelete={handlesubmitDelete} />

        </>
    )
}

export default TaskAssign

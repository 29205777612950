import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack } from "react-icons/io5";

function VerificationSecondStep() {
    return (
        <>
            <div className="verification-wrap">
                <div className="verification-outer">
                    <div className="verification-title">
                        <button className="nextBtn in-active"><IoChevronBack /></button>
                        <h2>2-step Verification</h2>
                        <button className="nextBtn"><IoClose /></button>
                    </div>
                    <div className="verification-main">
                        <div className="text-center"><img src={require("../../../images/icon/lock.svg").default} alt="" /></div>
                        <h4 className="text-center pt-4">2-step verification is off</h4>
                        <p className="text-center pt-2">For the security of your account, please turn on the 2-step verification. Add any of the following ways to turn on 2-step verification.</p>

                        <div className="verify-option">
                            <h6>2-step verification</h6>
                            <span className="custom-switch">
                                <input type="checkbox" />
                                <span className="checkMark"></span>
                            </span>
                        </div>

                        <div className="verify-method">
                            <div className="verify-method-icon"><img src={require("../../../images/icon/auth.svg").default} alt="" /></div>
                            <div className="verify-method-txt">
                                <h6>Authenticator app</h6>
                                <p>Use an authenticator app such as <a href="">Google Authenticator.</a></p>
                            </div>
                        </div>

                        <div className="verify-method">
                            <div className="verify-method-icon"><img src={require("../../../images/icon/phone.svg").default} alt="" /></div>
                            <div className="verify-method-txt">
                                <h6>Phone number</h6>
                                <p>Use phone number for 2-step verification.</p>
                            </div>
                        </div>

                        <div className="verify-method">
                            <div className="verify-method-icon"><img src={require("../../../images/icon/mail.svg").default} alt="" /></div>
                            <div className="verify-method-txt">
                                <h6>Email</h6>
                                <p>Use Email for 2-step verification.</p>
                            </div>
                        </div>

                        <p className="text-center pt-4">Adjust the sorting to change the default 2-step verification.</p>


                    </div>
                </div>
            </div>
        </>
    )
}

export default VerificationSecondStep;
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Link from '../../../images/icon/link.png'
import upload from "../../../images/icon-2/LinkUpload.svg"
import edit from "../../../images/icon-2/LinkEdit.svg"
import { FaSpinner } from 'react-icons/fa'
import pdf from '../../../images/icon/FilePdf.svg';
import jpg from '../../../images/icon/FileJpg.svg';
import xml from '../../../images/icon/FileXls.svg';
import { useDispatch } from 'react-redux'
import { submitTaskModelAction } from '../../../Redux/Actions/TaskTaskerActions'
import PendingFilesModel from './PendingFilesModel'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { useNavigate } from 'react-router-dom'
const SubmitTaskModal = (props) => {
    const [link, setLink] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [loader, setLoader] = useState(false);
    const [files, setFiles] = useState([]);
    const navigation = useNavigate()
    const [disabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        navigation("/tasks")
    }
    const handleShow = () => setShow(true);

    const [coverLetter, setCoverLetter] = useState('');
    const dispatch = useDispatch()
    const handleLinkChange = (e) => setLink(e.target.value);
    const handleCheckboxChange = () => setIsChecked(!isChecked);
    const handleFileChange = (e) => setFiles([...e.target.files]);
    const handleCoverLetterChange = (e) => setCoverLetter(e.target.value);
    const urlRegex = /^(https?:\/\/|ftp:\/\/|www\.)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isChecked) {
            alert("Please confirm you've completed the task by checking the box.");
            return;
        }
        setLoader(true)
        const formData = new FormData();

        if (props.type === "3" || props.type === "5") {
            if (!urlRegex.test(link.trim())) {
                dispatch(setAlert("Please provide valid link", "danger"));
                setLoader(false);
                return;
            } else {
                formData.append("link", link);
            }

        }
        if (props.type === "1" || props.type === "2" || props.type === "6") {
            files.forEach((file, index) => {
                formData.append(`file`, file);
            });
        }
        if (props.type === "4" || props.type === "6") {
            if (coverLetter === '') {
                dispatch(setAlert("Please provide text ", "danger"));
                setLoader(false);
                return;
            } else {
                formData.append("text", coverLetter);
            }

        }

        formData.append("slug", props.slugId);



        // console.log("formdata", formData)
        setDisabled(true)
        dispatch(submitTaskModelAction(formData, setLoader, handleShow, props.handleClose))

    };

    return (
        <>
            <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}></Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className="submit_modal">
                        <h3 className="text-center">How do you want to submit your task?</h3>
                        <p className="text-center pt-2">Be careful, you can’t alter your submission without approval.</p>

                        {/* Link Submission */}
                        <div className="modal-card link_submit_modal mt-4">
                            <form onSubmit={handleSubmit}>
                                {props.type === "3" || props.type === "5" ? <>    <h6 className='mb-1'>Submit Link</h6>
                                    <p style={{ fontSize: "13px" }} className='mt-0 mb-2'>Please submit a link related to your task, don’t paste any malicious link.</p>
                                    <div className='mb-3 d-flex align-items-center gap-2'>
                                        <input
                                            type="text"
                                            value={link}
                                            onChange={handleLinkChange}
                                            required
                                        />
                                    </div></> : ""}


                                {/* File Upload Section */}
                                {props.type === "1" || props.type === "2" || props.type === "6" ? <div className="modal-card upload_file_modal mt-2 pt-2">
                                    <h6>Upload Files</h6>
                                    <div className="upload_file_wrap">
                                        <ul>
                                            {files.length > 0 ? files.map((file, i) => (
                                                <li key={i}>
                                                    <div className="file_formate_img p">
                                                        <img src={file.type.includes("pdf") ? pdf : file.type.includes("jpeg") ? jpg : xml} alt="" />
                                                    </div>
                                                    <div className="file_para">
                                                        <p>{file.name}</p>
                                                        <span>{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                                                    </div>
                                                </li>
                                            )) : (
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    No files have been uploaded yet.
                                                </div>
                                            )}
                                        </ul>
                                        <div className="account-block d-flex w-100 flex-row">
                                            <span>Drop files here or upload files</span>
                                            <button className='theme-btn light' type="button">Upload</button>
                                            <input
                                                type="file"

                                                style={{ opacity: 0 }}
                                                onChange={handleFileChange}
                                                accept=".pdf,.jpg,.xls,.xlsx"
                                            />
                                        </div>
                                    </div>
                                </div> : ""}

                                {/* Cover Letter Section */}
                                {props.type === "4" || props.type === "6" ?
                                    <div className="modal-card mt-0 pt-1">
                                        <h6 className='mb-3'>Cover Letter</h6>
                                        <div className="application_submit_wrap">
                                            <textarea
                                                className="border-0 w-100 rounded-3 p-3"
                                                id="exampleFormControlTextarea1"
                                                rows="12"
                                                name="textarea"
                                                placeholder='Write a few words to impress your client'
                                                value={coverLetter}
                                                onChange={handleCoverLetterChange}
                                                required
                                            ></textarea>
                                        </div>
                                    </div> : ""
                                }
                                <div className='link_checkbox d-flex align-items-center'>
                                    <input
                                        id="taskCheckbox"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        required
                                    />
                                    <label style={{ fontSize: "13px", marginTop: "3px" }} htmlFor="taskCheckbox">
                                        By checking this box, you agree to the task submission guidelines and the platform's terms and conditions.
                                    </label>
                                </div>
                                {/* Submit Button */}
                                <div className="application_btn text-center my-3">
                                    <button className="theme-btn" type="submit" disabled={disabled}>Submit {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PendingFilesModel show={show} handleClose={handleClose} slug={props?.slugId} />
        </>
    )
}

export default SubmitTaskModal;

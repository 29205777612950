import React, { useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { ProgressBar } from 'primereact/progressbar';
import { FaSpinner } from "react-icons/fa";
import Pagination from '../../Common/Pagination';
import { Rating } from "primereact/rating";

function ReviewsGiven(props) {
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    let spendingData = [];
    if (props.reviewData) {
        spendingData = props.reviewData;
    }


    const indexOfLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemOnPage;
    const currentTodo = spendingData.slice(indexOfFirstTodo, indexOfLastTodo);
    return (
        <>
            {
                props.loader.fetch ? <FaSpinner className='spin ms-1 w-100 d-flex mt-4' style={{ fontSize: "40px" }} /> :
                    <div className="account-block ex mb-5 mb-lg-3">
                        <h6 className="d-flex align-items-center pb-3">
                            {/* <img src={require("../../../images/icon/star2.svg").default} alt="" /> <span className="ps-2">5.0 - 7 reviews</span> */}
                            <Rating className='rating-color' value={props?.rating?.averagerating || 0} readOnly cancel={false} allowHalf />
                            <span className="ps-2">{props?.rating?.averagerating || 0} - {props?.rating?.totalreview || 0} reviews</span>
                        </h6>


                        <div className="review-list mt-2">
                            <ul>
                                {currentTodo?.length > 0 ?


                                    currentTodo.map((curElem, index) => {
                                        const rating = JSON.parse(curElem.reviewratings)
                                        return (
                                            <React.Fragment key={index}>
                                                <div className="border-bottoms" >
                                                    <li>
                                                        <div className="review-list-single">
                                                            <div className="review-list-single-top">
                                                                <div className="review-list-single-img">
                                                                    <img src={curElem?.profile ? curElem?.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                                                                <div className="review-list-single-txt">
                                                                    <h4>{curElem.name}</h4>
                                                                    <h4>{curElem.title}</h4>
                                                                    <h6>{curElem.reviewdate}</h6>
                                                                </div>
                                                            </div>
                                                            <p className="pt-3">{curElem.message}</p>
                                                        </div>

                                                    </li>
                                                    <div className="rev-card mb-3">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Availability</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * rating.availability} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.availability}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Cooperation</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * rating.copperation} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.copperation}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Communication</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * rating.communication} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.communication}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Quality of work</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * rating.work_quality} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.work_quality}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Skills</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * rating.skillset} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.skillset}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="rev-card-single">
                                                                    <h6>Adhere to deadline</h6>
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * rating.deadline} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{rating.deadline}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </React.Fragment>
                                        )
                                    })

                                    : <div className="d-flex justify-content-center align-items-center m-auto">No Review Received</div>

                                }


                            </ul>


                        </div>
                        {spendingData.length > 0 ?
                            <Pagination
                                listData={spendingData}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={props.reviewData}
                                loader={props.loader.fetch}
                            />
                            : ""
                        }
                    </div>
            }
        </>
    )
}

export default ReviewsGiven;
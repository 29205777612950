import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import SidePanel from '../../Common/SidePanel';
import DashboardHeader from '../../Common/DashboardHeader';
import TitleBar from '../../Common/TitleBar';
import AvailableTasks2 from './TaskDetails/TaskModuleComponent/AvailableTasks2';
import AvailableTasks from './TaskDetails/TaskModuleComponent/AvailableTasks';
import { useDispatch, useSelector } from 'react-redux';
import { FetchTaskTaskerData } from '../../../Redux/Actions/TaskTaskerActions';
import DashboardFooter from '../../Common/DashboardFooter';
import RecomnendedTasks from './TaskDetails/TaskModuleComponent/RecomnendedTasks';
import OngoingTasks from './TaskDetails/TaskModuleComponent/OngoingTasks';
import CompletedTasks from './TaskDetails/TaskModuleComponent/CompletedTasks';
import SavedTasks from './TaskDetails/TaskModuleComponent/SavedTasks';
import ApprovedTasks from './TaskDetails/TaskModuleComponent/ApprovedTasks';
import RejectedTasks from './TaskDetails/TaskModuleComponent/RejectedTasks';

const Task = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <TitleBar title="Tasks" />

            <div className="dashboard Tasker_main_container container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in">
                            <DashboardHeader toggleSidebar={toggleClass} title="Tasks" />
                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className="row" >
                                        <div className="col-12">
                                            <Nav variant="pills" style={{ padding: "15px 28px 0px" }} >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" > Available Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Recommended Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Ongoing Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Completed Tasks</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth">Saved Tasks </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="sixth">Approved Tasks </Nav.Link>
                                                </Nav.Item> <Nav.Item>
                                                    <Nav.Link eventKey="seven">Rejected Tasks </Nav.Link>
                                                </Nav.Item>

                                            </Nav>


                                            <div className="setting_right_side_wrap fix-pad">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <AvailableTasks />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <RecomnendedTasks />
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="third">
                                                        <OngoingTasks />

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fourth">
                                                        <CompletedTasks type="completed" />

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="fifth">
                                                        <SavedTasks type="save" />

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="sixth">
                                                        <ApprovedTasks type="approve" />

                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="seven">
                                                        <RejectedTasks type="rejected" />

                                                    </Tab.Pane>



                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container >
                            </div>
                        </div>
                        <DashboardFooter />
                    </div>
                </div >
            </div >

        </>
    )
}

export default Task

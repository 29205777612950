import axios from "axios";
import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";
import { baseURL } from "../../Global/Global";
import { fetchArticles, fetchVideos } from "./HelpActions";
import { fetchRebrandData } from "./RebrandingAction";
import { fetchSocialAccounts } from "./SocialActions";
import { FetchBankDetail } from "./PaymentActions";
import { FetchTaskTaskerData } from "./TaskTaskerActions";

export const loadUser = () => (dispatch) => {
    let token = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).auth.token : false
    if (token) {
        commonAxios("fetch-users-details", {}, dispatch, token)
            .then((res) => {
                // console.log("fetch-users-details",res.data)
                if (res.status) {
                    dispatch({ type: 'LOAD_USER_DATA', payload: res.data });
                    // dispatch(fetchVideos(false))
                    // dispatch(fetchArticles(false))
                    // dispatch(fetchRebrandData())
                    // dispatch(fetchSocialAccounts())
                    dispatch(FetchBankDetail())
                    dispatch(FetchTaskTaskerData())
                }
            }).catch((err) => {
                console.log(err)
            })
    }
}

export const adminloadUser = () => (dispatch) => {
    let token = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')).auth.token : false
    if (token) {
        commonAxios("fetch-users-details", {}, dispatch, token)
            .then((res) => {

                if (res.status) {
                    dispatch({ type: 'LOAD_USER_DATA', payload: res.data });
                }
            }).catch((err) => {
                console.log(err)
            })
    }
}
export const masterLoginUser = (user, setLoader, navigate) => (dispatch) => {
    localStorage.clear();
    commonAxios("login", user, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'LOGIN_USER', payload: res.data });

                dispatch(adminloadUser())
                navigate('/admin/dashboard');
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


export const loginUser = (data, setLoader) => (dispatch) => {
    localStorage.clear();
    commonAxios("login", data, dispatch)
        .then((res) => {
            if (res.status) {
                // console.log("login",res.data)
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}

export const fetchASocialActionTasker = (setLoader, setSocial) => (dispatch, getState) => {
    commonAxios("fetch-social-accounts", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setSocial(res.data.list)
            } else {
                console.log(res)
            }
            setLoader(false)

        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const socialAction = (data, setLoader, setSocial, setType, fetchSocial) => (dispatch, getState) => {
    commonAxios("add-social", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                fetchSocial()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
            setSocial('');
            setType('')

        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const AdminTakserSocialAction = (data, setLoader, setSocial, setType, setSocialLink) => (dispatch, getState) => {
    commonAxios("add-user-social-account", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setSocialLink(res.data)
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
            setSocial('');
            setType('')
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const forgetPassword = (data, setLoader) => (dispatch) => {
    commonAxios("forgot-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const resetPassword = (data, setLoader, handleClose, setSession_id, handleShow) => (dispatch) => {
    commonAxios("forget-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                // navigate('/');
                handleClose()
                handleShow()
                setSession_id(res.data.hash)
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const MobileLogin = (data, setLoader, handleClose, setSession_id, handleShow, setSignInfo, signInfo, setMobile) => (dispatch) => {
    commonAxios("validatelogin", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                // navigate('/');
                handleClose()
                handleShow()
                setSession_id(res.data.session_id)
                setMobile(res.data.mobile)
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
            setSignInfo({
                ...signInfo,
                phone: "",
            })
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const ForgetOtpVerify = (data, setLoader, handleClose, handleShow) => (dispatch) => {
    commonAxios("verify-forgetpasword", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                // navigate('/');
                handleClose()
                handleShow()

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const UpdatePasswordForget = (data, setLoader, handleClose) => (dispatch) => {
    commonAxios("reset-password", data, dispatch)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                // navigate('/');
                handleClose()


            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}
export const ReSendOtpAction = (data) => (dispatch) => {
    commonAxios("resend-otp", data, dispatch)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }

        }).catch((err) => {
            console.log(err)

        })

}
export const ForgetReSendOtpAction = (data) => (dispatch) => {
    commonAxios("resend-reset-otp", data, dispatch)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));

            } else {
                dispatch(setAlert(res.msg, "danger"));

            }

        }).catch((err) => {
            console.log(err)

        })

}

export const updateName = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_NAME', payload: data.name });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);

        })
}


export const updatePassword = (data, setLoader) => (dispatch, getState) => {
    commonAxios("profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })
}




export const gdprAction = (data, sweet, setSweet) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: `${baseURL}${sweet.ep}`,
        data: data,
        headers: { 'Content-Type': 'application/json', 'Authorization': getState().auth.token }
    }).then(res => {
        if (res.data.status === true) {
            if (sweet.ep === 'download-user-data') {
                window.location.href = res.data.file;
            } else {
                dispatch(logoutUser());
            }
            dispatch(setAlert(res.data.message, "success"));
        } else {
            dispatch(setAlert(res.data.message, "danger"));
        }
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "",
            text: "",
            ep: "",
            loader: false
        })
    }).catch(error => {
        setSweet({
            enable: false,
            id: false,
            confirmButtonName: "",
            text: "",
            ep: "",
            loader: false
        })
        dispatch(setAlert('Getting Internal Server Error', "danger"));
    })
}

// NEW ACTIONS


export const onUpdateUser = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data === 1) {
                    dispatch(logoutUser())
                }
                dispatch({ type: "CHANGE_USER_NAME", payload: data })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                user: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                user: false
            })
        })
}

export const onChangeEmail = (data, setLoader) => (dispatch, getState) => {
    commonAxios("change-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_EMAIL', payload: data.email });
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            setLoader(false);
        })

}

export const onUpdatePassword = (data, setLoader) => (dispatch, getState) => {
    commonAxios("update-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch(logoutUser())
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const logoutUser = () => (dispatch, getState) => {
    commonAxios("logout", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGOUT' });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const changeProfile = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-profile-image", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'CHANGE_USER_IMAGE', payload: res.data.profile });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                image: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                image: false
            })
        })
}
export const otpMobileLoginVerfication = (data, setLoader, navigation, setShowModal, setOTP) => (dispatch, getState) => {

    commonAxios("validate-login-otp", data, dispatch)
        .then((res) => {

            if (res.status) {

                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())
                setShowModal(true)
                setTimeout(() => {
                    if (res.data.user.role === "Tasker") {
                        navigation('/dashboard')
                    }

                    else {
                        navigation('/creator/dashboard')

                    }


                }, 3000);



                dispatch(setAlert(res.msg, "Success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setOTP('')
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })


}

export const otpVerfication = (data, setLoader, navigation, setShowModal) => (dispatch, getState) => {

    commonAxios("verify-otp", data, dispatch)
        .then((res) => {

            if (res.status) {
                // dispatch({ type: 'LOGIN_USER', payload: res.data });
                // dispatch(loadUser())
                // navigation('/');
                setShowModal(true)
                setTimeout(() => {
                    navigation('/mobile-verification', { state: { "mobile": res?.data?.mobile, "Id": res?.data?.session_id, "is_refered": res?.data?.is_refered } });

                }, 3000);


                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {

            setLoader(false)
        })


}
export const otpMobileVerfication = (data, setLoader, navigation, setShowModal) => (dispatch, getState) => {

    commonAxios("verify-otp", data, dispatch)
        .then((res) => {

            if (res.status) {

                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())
                setShowModal(true)
                setTimeout(() => {
                    if (res.data.user.role === "Tasker") {
                        navigation('/dashboard')
                    }

                    else {
                        navigation('/creator/dashboard')

                    }


                }, 3000);



                dispatch(setAlert(res.msg, "Success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })


}

export const skipMobileVerfication = (data, navigation,) => (dispatch, getState) => {

    commonAxios("skip-mobile-verify", data, dispatch)
        .then((res) => {

            if (res.status) {

                dispatch({ type: 'LOGIN_USER', payload: res.data });
                dispatch(loadUser())


                if (res.data.user.role === "Tasker") {
                    navigation('/dashboard')
                }

                else {
                    navigation('/creator/dashboard')

                }






                dispatch(setAlert(res.msg, "Success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }

        }).catch((err) => {
            console.log(err)

        })


}

export const userInformation = (data, setLoader, loader, handleClose) => (dispatch, getState) => {

    commonAxios("update_profile_details", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch({ type: 'LOAD_USER_DATA', payload: res.data });

                dispatch(loadUser())

                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose()
            setLoader({
                ...loader,
                save: false
            })
            handleClose()
        }).catch((err) => {

            setLoader({
                ...loader,
                save: true
            })
        })


}
export const TaskPerfernceData = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("get_category", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)
                // dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {

            setLoader({
                ...loader,
                fetch: false
            })
        })


}
export const TaskPerfernceUpdate = (data, setLoader, loader) => (dispatch, getState) => {

    commonAxios("add-task-preference", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {

            setLoader({
                ...loader,
                save: false
            })
        })


}

export const AddQulification = (data, setLoader, handleClose) => (dispatch, getState) => {

    commonAxios("add-qualification-experience", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                console.log("res", res.data)

                dispatch({ type: 'LOAD_USER_DATA', payload: res.data });

                // dispatch(loadUser())

                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            console.log(err)
            setLoader(false)
            handleClose()
        })


}
export const updateNotification = (data, setLoader, loader) => (dispatch, getState) => {

    commonAxios("update-notification-setting", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {

            setLoader({
                ...loader,
                save: false
            })
        })


}

export const logoutAllDevice = () => (dispatch, getState) => {
    commonAxios("logout-all-device", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                dispatch({ type: 'LOGOUT' });
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const UpdateMobileEmail = (data, loader, setLodder, handleShow) => (dispatch, getState) => {
    commonAxios("update", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                handleShow()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }

            if (data?.type === "email") {
                setLodder({
                    ...loader,
                    email: false,
                })
            }
            else {
                setLodder({
                    ...loader,
                    mobile: false,
                })
            }

        }).catch((err) => {
            console.log(err)
        })
}
export const UpdateMobileEmailVerify = (data, setLodder, handleClose) => (dispatch, getState) => {
    commonAxios("verify", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));
                handleClose()

            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            handleClose()
            setLodder(false)
        }).catch((err) => {
            console.log(err)
            setLodder(false)
        })
}
export const onFetchSecurity = (setData, loader, setLoader) => (dispatch, getState) => {

    commonAxios("login-session", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)
                // dispatch(setAlert(res.msg, "success"));
            } else {
                // dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {

            setLoader({
                ...loader,
                fetch: false
            })
        })


}
export const onDestroySecurity = (data, loader, setLoader, fetchData) => (dispatch, getState) => {

    commonAxios("destroy-session", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"));
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {

            setLoader({
                ...loader,
                save: false
            })
        })


}

export const onKycUpdate = (data, loader, setLoader, setDisabled) => (dispatch, getState) => {
    let config = {
        headers: {
            'Authorization': getState().auth.token,
            "Content-Type": "multipart/form-data",
        }
    }

    commonAxios("update-kyc", data, dispatch, getState().auth.token, config)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setDisabled(false)
            setLoader({
                ...loader,
                update: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                update: false
            })
            console.log(err)
            // dispatch(setAlert(err.msg, "danger"))
        })
}
export const onKycRejectQuery = (data, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("create-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose()
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
            // dispatch(setAlert(err.msg, "danger"))
        })
}

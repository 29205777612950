import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import dollarIcon from '../../../images/icon/dollarIcon.svg';
import event from '../../../images/icon/events.svg';
import { LuDownload } from 'react-icons/lu';
import PayoutRequestModal from '../ModalComponents/PayoutRequestModal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PayoutPDF from './PayoutPDF';
import Pagination from '../../Common/Pagination';
import { FaSpinner } from 'react-icons/fa';
import { fetchEarningsData, fetchEarningsData1 } from '../../../Redux/Actions/EarningsAction';
import CusstomPagination from '../../Common/CusstomPagination';
import { useDispatch } from 'react-redux';

const EarningsPayouts = () => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [data, setData] = useState([])
    const [totalLength, setTotalLength] = useState(0);
    const [loader, setLoader] = useState(
        {
            fetch: true,
        }
    )

    const filterData = (data, filter) => {

        const now = new Date();
        let startDate, endDate;

        switch (filter) {

            case 'Week':
                startDate = new Date(now);
                startDate.setDate(now.getDate() - 6);
                endDate = new Date(now);
                // startDate = new Date(now.setDate(now.getDate() - now.getDay())); 
                // endDate = new Date(now.setDate(startDate.getDate() + 6)); 
                break;
            case 'Month':
                // startDate = new Date(now.getFullYear(), now.getMonth(), 1); 
                // endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); 
                startDate = new Date(now);
                startDate.setMonth(now.getMonth() - 1); // Start date is one month ago from today
                endDate = new Date(now); // End date is today

                break;
            case 'Year':
                // startDate = new Date(now);
                // startDate.setFullYear(now.getFullYear() - 1); // Start date is one year ago from today
                // endDate = new Date(now); // End date is today
                startDate = new Date(now.getFullYear(), 0, 1);
                endDate = new Date(now.getFullYear(), 11, 31);
                break;
            default:

                return data;
        }

        return data.filter(item => {

            const itemDate = new Date(item.created_at);

            return itemDate >= startDate && itemDate <= endDate;
        });
    };


    useEffect(() => {

        setFilteredData(filterData(data, filter));
    }, [data, filter]);
    useEffect(() => {
        if (data?.myearning) {
            setFilteredData(data?.myearning)
        } else {
            setFilteredData([])
        }
        if (data?.total_count) {
            setTotalLength(data?.total_count)
        }
    }, [data?.myearning])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 20
    });
    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1
        }

        dispatch(fetchEarningsData1("fetch-earning", formData, setData, setLoader, loader))
    }

    useEffect(() => {

        fetchData()
    }, [pagination.currentPage]);

    return (
        <>
            <div className="col-12">
                {
                    loader.fetch ? <div className='d-flex align-items-center justify-content-center' style={{ height: "100vh" }}><FaSpinner className='spin ms-1' style={{ fontSize: "40px" }} /></div> :
                        <>
                            <div className="account-block mx-2 mt-2">
                                <h6>Earnings</h6>

                                <div className="d-flex task_main_wrap" style={{ borderBottom: "1px solid #1C1C1C1A", paddingBottom: "20px" }}>
                                    <div className="task_process">
                                        <span>Net Earnings</span>

                                        <div className="progress_wrap" style={{ position: "relative" }}>
                                            <h3 style={{ fontWeight: "700" }}>₹{data?.earningslist?.earning}</h3>
                                        </div>

                                    </div>
                                    <div className="task_process ps-3 pe-3">
                                        <span className="text-center w-100">Withdrawn</span>
                                        <div className="task_process_bar text-center">
                                            <h3>₹{data?.earningslist?.withdrawl}</h3>
                                        </div>
                                    </div>
                                    <div className="task_process ps-3 pe-3">
                                        <span className="text-center w-100">Available</span>
                                        <div className="task_process_bar text-center">
                                            <h3>₹{data?.earningslist?.avaiable}</h3>
                                        </div>
                                    </div>



                                </div>
                                <div className="withdraw_request_wrap border-0" style={{ background: "#E5ECF680", borderRadius: "8px", padding: "16px" }}>

                                    <div className="withdraw_wrap d-flex">
                                        <div className="withdraw_icon me-2">
                                            <img src={dollarIcon} alt="" />
                                        </div>
                                        <div className="withdraw_para">
                                            <h6 style={{ fontWeight: "400", marginBottom: "5px" }}>Withdraw Your Money to a Bank Account</h6>
                                            <p>Withdraw money securely to your bank account. The minimum payout is Rs.100, and payments are released every Monday.</p>
                                        </div>
                                    </div>
                                    <div className="withdraw_btn mt-lg-0 mt-md-4 mt-4">
                                        <button className='theme-btn' onClick={handleShow}>Request Payout</button>
                                    </div>
                                </div>
                            </div>

                            <div className="account-block mx-2 mt-4 mb-3 mb-lg-5">
                                <div className="task_border d-flex justify-content-between border-0 pb-2">
                                    <h6>All Earnings</h6>
                                    {/* <div className="table_drop_down">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    {filter}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setFilter("Week")}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilter("Month")}>This Month</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilter("Year")}>This Year</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                                </div>

                                <div className="table-responsive earnings_table mt-1">
                                    <table className="table theme-table">
                                        <thead>
                                            <tr>
                                                <th>S.N.</th>
                                                <th>Task Title</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th >Mode</th>
                                                {/* <th>Invoice</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.length > 0 ? filteredData.map((curElem, i) => (
                                                <tr key={i}>
                                                    <td data-label="Task ID">{i + 1}</td>
                                                    <td data-label="Task Title">{curElem.title}</td>
                                                    <td data-label="Type">{curElem.transction_type}</td>
                                                    <td data-label="Date"><img src={event} alt="" />{curElem.created}</td>
                                                    <td data-label="Amount">₹{curElem.amount}</td>
                                                    <td data-label="Mode">
                                                        <div className='earn_status complete' style={{ width: "fit-content", backgroundColor: curElem.mode === "credit" ? "rgb(222, 248, 238)" : "#f8d7da", color: curElem.mode === "credit" ? "rgb(74, 167, 133)" : "#721c24", paddingBlock: "14px" }}>{curElem.mode}</div>
                                                    </td>
                                                    {/* <td data-label="Status" className='text-center'>{curElem.type}

                                                    </td> */}
                                                    {/* <td data-label="Status">
                                            {curElem.status === 1 ? <div className='earn_status complete'>Complete</div> : curElem.status === 2 ? <div className='earn_status'>Rejected</div> : <div className='earn_status'>Pending</div>}
                                        </td> */}
                                                    {/* {
                                            curElem.status === 1 ?
                                                <td data-label="Invoice">
                                                    <PDFDownloadLink
                                                        document={<PayoutPDF data={curElem} />}
                                                        fileName={`payout_${i}.pdf`}
                                                    >
                                                        {({ loading }) => (loading ? 'Loading document...' : <><LuDownload /> PDF</>)}
                                                    </PDFDownloadLink>
                                                </td> : ""
                                        } */}

                                                </tr>
                                            )) :
                                                <td className='text-center mt-3 pt-2' colSpan={7}>No history found</td>
                                            }
                                        </tbody>
                                    </table>
                                    <PayoutRequestModal
                                        show={show}
                                        handleClose={handleClose}
                                        avaiable={data?.earningslist?.avaiable}
                                    />
                                    <div className='my-3'>

                                        <CusstomPagination
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            totalLength={totalLength}
                                            loader={loader.fetch}
                                        />

                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    );
}

export default EarningsPayouts;

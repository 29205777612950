import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { IoClose, IoChevronBack } from "react-icons/io5";

function VerificationFifthStep() {
    return (
        <>
            <div className="verification-wrap">
                <div className="verification-outer">
                    <div className="verification-title">
                        <button className="nextBtn in-active"><IoChevronBack /></button>
                        <h2>Add phone number</h2>
                        <button className="nextBtn"><IoClose /></button>
                    </div>
                    <div className="verification-main">
                        <div className="text-center"><img src={require("../../../images/icon/check.svg").default} alt="" /></div>
                        <h4 className="text-center pt-4">Phone number has been added</h4>

                        <div className="text-center mt-4">
                            <button className="theme-btn light large">View 2-step methods</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default VerificationFifthStep;
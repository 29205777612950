import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { RxCross1 } from 'react-icons/rx';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaSpinner } from 'react-icons/fa';

const DeleteNewModal = (props) => {
    return (
        <>
            <Modal className="theme-modal admin-approve table" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Body>
                    <div className='modal-btn'><span style={{ right: "20px", top: "20px" }} className='ms-auto' onClick={props.handleClose}><RxCross1 /></span></div>
                    <div className="py-3">
                        <span className='bin-icon mx-auto mb-3' style={{width:"70px", height:"70px"}}>
                            <RiDeleteBin5Line style={{ fontSize: "40px", color: "#d92d20" }} />
                        </span>
                        <h5 className='text-center fw-bold'>Are You Sure</h5>
                        <div class="verify_btn mt-4 d-flex justify-content-center gap-3">
                            <button className="theme-btn cancel-btn" type="button" style={{ padding: "8px 24px" }} onClick={props.handleClose}>Cancel </button>
                            <button className="theme-btn approve-btn" type="button" style={{ padding: "8px 24px" }} onClick={props.handlesubmitDelete}>Confirm{props.loader.delete?<FaSpinner className='spin ms-1'/>:""} </button></div>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    );
};

export default DeleteNewModal;

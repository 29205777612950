import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ADDBankDetail } from '../../../Redux/Actions/PaymentActions'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { Calendar } from 'primereact/calendar';
import { CiCalendarDate } from "react-icons/ci";
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { raisedQueryAction } from '../../../Redux/Actions/EarningsAction';

const RaisedModel = (props) => {
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()

    const [input, setInput] = useState({
        "textarea": "",
        "checked": "",
    })
    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "checked") {
            setInput({ ...input, [name]: checked ? 1 : 0 })
        }
        else {
            setInput({ ...input, [name]: value })
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        if (input.textarea === "") {

            dispatch(setAlert("Text Area is filed ", "danger"))
            setLoader(false)
            return
        }
        let data = {
            "text": input.textarea,
            "id": props?.raisedId
        }

        dispatch(raisedQueryAction(data, setLoader, props.handleClose))
    }
    return (
        <>
            <Modal className="theme-modal link_submit_modal bank-details" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <form onSubmit={handleSubmit}>
                        <h3 className="text-center">Raised Query
                        </h3>
                        {/* {/ <p className="text-center pt-2">Don't worry, you can upload in images, videos, audio any format</p> /} */}
                        <div className="modal-card mt-4 ">
                            <div className="application_submit_wrap">
                                <textarea
                                    className="border-0 w-100 rounded-3 p-3"
                                    id="exampleFormControlTextarea1"
                                    rows="12"
                                    name="textarea"
                                    placeholder='Write a few words to impress your client'
                                    value={input.textarea}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>



                            <div className="col-sm-6">
                                <div className="task-reference-block py-2">
                                    <span className="custom-check">
                                        <input
                                            type="checkbox"
                                            name="checked"
                                            required
                                            checked={input.checked === 1 ? true : false}
                                            id='checked'
                                            onChange={handleChange}
                                        />
                                        <span className="checkMark"></span>
                                    </span>
                                    <div className="task-reference-info">
                                        <label htmlFor="checked">I Accept All the terms
                                        </label>

                                    </div>
                                </div>
                            </div>

                            <div className="link_btn text-end mt-3">
                                <button className='theme-btn cancelBtn light' type="button" onClick={props.handleClose}>Cancel</button>
                                <button className='theme-btn saveBtn' type="submit" style={{ cursor: "pointer" }}>Submit{loader ? <FaSpinner className="spin ms-1" /> : null} </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default RaisedModel

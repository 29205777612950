import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import DashboardHeader from '../../Common/DashboardHeader'
import ApprovePayout from './ApprovePayout'
import AdminPayoutHistory from './AdminPayoutHistory'
import { FetchAdminPayOut } from '../../../Redux/Actions/AdminPayOutAction'
import { useDispatch } from 'react-redux'
import TitleBar from '../../Common/TitleBar'
import DashboardFooter from '../../Common/DashboardFooter'
import RejectedPayoutHistory from './RejectedPayoutHistory'

const ApproveNavPayout = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };


    return (
        <>
            <TitleBar title="Payout" />
            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in  ">
                            <DashboardHeader toggleSidebar={toggleClass} title="Manage Payouts" />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Nav variant="pills" style={{ padding: "14px 28px 0 28px" }} >
                                            <Nav.Item>
                                                <Nav.Link eventKey="first" >Approve Payout</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Payout History</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Third">Rejected History</Nav.Link>
                                            </Nav.Item>


                                        </Nav>


                                        <div className="setting_right_side_wrap payout-history-footer">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <ApprovePayout />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <AdminPayoutHistory />

                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Third">
                                                    <RejectedPayoutHistory />

                                                </Tab.Pane>


                                            </Tab.Content>
                                        </div>
                                    </Tab.Container >

                                </div>
                            </div>
                            <div className='mt-0 pt-0'><DashboardFooter /></div>
                        </div>
                    </div>


                </div>
            </div >

        </>
    )
}

export default ApproveNavPayout

import React, { useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';

function PaymentMethodsTabs() {

  const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'Every Monday', code: 'NY' },
        { name: 'Every Monday', code: 'RM' },
        { name: 'Every Monday', code: 'LDN' }
    ];

  return (
    <>
      <div className="account-block">
        <h6>Allowed Payment Method</h6>
        <div className="block-white mt-4">
          <div className="payment-method-single mb-4">
            <div className="payment-method-img"><img src={require("../../../images/stripe.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Stripe</h6>
              <p>Connect your Stripe account.</p>
            </div>
            <div className="payToggle">
              <span className="custom-switch">
                <input type="checkbox" />
                <span className="checkMark"></span>
              </span>
            </div>
          </div>
          <div className="payment-method-single mt-2">
            <div className="payment-method-img"><img src={require("../../../images/paypal.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>PayPal</h6>
              <p>Connect your PayPal account.</p>
            </div>
            <div className="payToggle">
              <span className="custom-switch">
                <input type="checkbox" />
                <span className="checkMark"></span>
              </span>
            </div>
          </div>
          <div className="payment-method-single mt-4">
            <div className="payment-method-img"><img src={require("../../../images/credit.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Credit card</h6>
              <p>Add a credit card.</p>
            </div>
            <div className="payToggle">
              <span className="custom-switch">
                <input type="checkbox" />
                <span className="checkMark"></span>
              </span>
            </div>
          </div>

          <div className="payment-method-single mt-4">
            <div className="payment-method-img"><img src={require("../../../images/upi.svg").default} alt="" /></div>
            <div className="payment-method-txt">
              <h6>Unified Payment Interface</h6>
              <p>Add an UPI account.</p>
            </div>
            <div className="payToggle">
              <span className="custom-switch">
                <input type="checkbox" />
                <span className="checkMark"></span>
              </span>
            </div>
          </div>

        </div>
      </div>

      <div className="account-block mt-4 mb-5">
        <h6>More Settings</h6>
        <div className="input-wrap border-0 alt">
            <label className="label mb-2" htmlFor="">Release Payment On</label>
            <PrimeDropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" 
          placeholder="Select" className="input" />
        </div>
        <div className="input-wrap border-0 alt">
            <label className="label mb-2" htmlFor="">Minimum Threshold Amount ( $ ) </label>
            <input className="input" type="text" name=""  value="$100" />
        </div>
        <div className="input-wrap border-0 alt">
            <label className="label mb-2" htmlFor="">Task Creator’s Charge ( % ) </label>
            <input className="input" type="text" name=""  value="10%" />
        </div>
        <div className="btnGroup my-4">
          <button className="theme-btn light">Cancel</button>
          <button className="theme-btn">Save Changes</button>
        </div>
      </div>
    </>
  )
}

export default PaymentMethodsTabs;
import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
const KycModel = (props) => {
    return (
        <>
            <Modal className="theme-modal forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>


                        {/* <span onClick={props.handleClose}><RxCross1 /></span> */}
                        <h1 className='d-flex justify-content-center align-items-center m-auto' style={{ fontSize: "25px" }}>Submit your query</h1>
                    </div>
                    <div className='creator_new_task h-100'>
                        <form onSubmit={props.handleSubmit}>
                            <div className="modal-card mt-4 account-block">

                                <div className='submission_wrap submission_bg p-0'>
                                    <textarea className="form-control border-0" id="" rows="4" placeholder='comment' name="text" value={props.text} onChange={(e) => props.setText(e.target.value)}></textarea>
                                </div>

                                <div className="link_btn mt-2 text-end">
                                    <button className='theme-btn saveBtn' type="submit" style={{ padding: "8px 24px" }} >
                                        Submit
                                        {props.loader.submit ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default KycModel

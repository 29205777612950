import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
export const FetchAdminManager = (url, formData, loader, setLoader, setData) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                setData(res.data)

            } else {
                console.log(res.msg)

            }
            setLoader({
                ...loader,
                fetch: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const adminMangerApprovedAction = (data, setLoader, handleClose, fetchdata) => (dispatch, getState) => {

    commonAxios("admin-approve-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                fetchdata()

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}
export const adminMangerRejecteddAction = (data, setLoader, handleClose, fetchdata) => (dispatch, getState) => {

    commonAxios("admin-reject-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                fetchdata()
            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}



export const adminSubmissionApprove = (data, loader, setLoader, handleClose1, fetchdata) => (dispatch, getState) => {

    commonAxios("approve-completed-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchdata()

            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose1()
            setLoader({
                ...loader,
                banAccount: false,
            })
        }).catch((err) => {
            console.log(err)

        })
}

export const adminSubmissionReject = (data, loader, setLoader, handleClose, fetchdata) => (dispatch, getState) => {

    commonAxios("reject-completed-task", data, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {
                dispatch(setAlert(res.msg, "success"))

                fetchdata()
            } else {
                console.log(res.msg)
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                active: false,
            })
            handleClose()
        }).catch((err) => {
            console.log(err)

        })
}
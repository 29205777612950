import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import calendar from '../../../../images/icon/CalendarBlank.svg'
import { RiEdit2Line } from "react-icons/ri";
import { useLocation } from 'react-router-dom'
import { MdCancel, MdOutlineAddPhotoAlternate } from 'react-icons/md'
import { FaRegFilePdf, FaSpinner } from 'react-icons/fa'
import { MdOutlineVideoLibrary } from "react-icons/md";
import DashboardFooter from '../../../Common/DashboardFooter'
import { useDispatch } from 'react-redux'
import AdminAPproveModal from '../../AdminModal/AdminApproveModal'
import AdminRejectModal from '../../AdminModal/AdminRejectModal'


const AdminTaskDetails = (props) => {
    const [producatId, setProducatId] = useState('')
    const [showModel, setShowModel] = useState(false)
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const [showModel1, setShowModel1] = useState(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)
    const dispatch = useDispatch()


    const handleAccept = () => {

        handleShow()
        setProducatId(props.data?.slug)
    }
    const handleDecline = () => {
        handleShow1()
        setProducatId(props.data?.slug)
    }
    const handleViewFile = (curElem) => {

        if (curElem.url) {
            const a = document.createElement('a');
            a.href = curElem.url;
            a.target = '_blank';
            a.click();

        }
    }
    return (
        <>
            {
                props.loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4" style={{ height: "100vh" }}><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                    <>
                        {/* <div className='ps-3 pe-3 '>
                <div className="table-bar">
                    <div className="table-bar-left">
                        <div className="table-bar-icon">
                            <div className="table-nav">
                                <div className="profile-menu">

                                </div>
                                <div className="profile-menu">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="successN" className="drop-btn">
                                            <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                            <li className="drop-list">
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        name="agentType"
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                                dolor sit amet
                                            </li>
                                            <li className="drop-list">
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        name="agentType"
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                                dolor sit amet.
                                            </li>


                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>


                            <ul>
                                <li title="Sort by Date">
                                    <img src={require("../../../../images/icon/sort.svg").default} alt="" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="table-bar-right">
                        <div className="header-search">
                            <input
                                type="text"
                                placeholder="Search"
                            />
                            <span className="left-icon"><IoSearchOutline
                            /></span>
                        </div>
                    </div>
                </div>
            </div> */}

                        <ScrollPanel className="custombar2 content-area pt-2">

                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='account-block ms-2
                             me-2 '>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                {/* <h3>Task Type</h3> */}
                                                <div class="earn_status me-auto ms-2" style={{ background: props.data?.status === "1" ? "rgb(222, 248, 238)" : props.data?.status === "0" ? "rgb(255, 243, 205)" : "rgb(248, 215, 218)", fontSize: "12px", padding: "5px 15px", borderRadius: "50px", color: props.data?.status === "1" ? "rgb(74, 167, 133)" : props.data?.status === "0" ? "rgb(133, 100, 4)" : "rgb(114, 28, 36)", cursor: "pointer" }}>{props.data?.status === "1" ? "Approved" : props.data?.status === "0" ? "Pending" : "Rejected"}</div>                                    {
                                                    props.data?.status === "0" ? <> <div class="theme-btn me-2" onClick={handleDecline}>Rejected</div> <div class="theme-btn" onClick={handleAccept}>Approved</div></> : props.data?.status === "1" ? <><div class="theme-btn" onClick={handleDecline}>Rejected</div></> : <div class="theme-btn me-2" onClick={handleAccept}>Apporved</div>
                                                }

                                            </div>
                                            {/* <div className="row g-3">
                                    <div className="col-lg-6 col-12">
                                        <div className="submission_wrap mt-3 py-4 ">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h3>Limited Submission</h3>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                                </div>
                                            </div>
                                            <div className='new_task_para mt-2'>
                                                <p>Limited Submission
                                                    There are no limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="submission_wrap mt-3 py-4 ">
                                            <div className='d-flex justify-content-between'>
                                                <h3>Unlimited Submission</h3>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                                </div>
                                            </div>
                                            <div className='new_task_para mt-2'>
                                                <p>Limited Submission
                                                    There are no limits to the number of times you can participate in this task, allowing you to contribute multiple times</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                            <div className="col-12">

                                                <div className="submission_wrap mt-3 py-4 ">
                                                    <span>Task Category</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.category_name}</p>
                                                    {/* <div style={{ marginLeft: "-10px" }}>
                                            <Form.Select aria-label="Task selection">
                                                <option value="" disabled selected>Select a task category</option>
                                                <option value="survey-research">Survey and Market Research Tasks</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </div> */}
                                                </div>
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Task Title</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.title}</p>
                                                </div>
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Task Type</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.task_name}</p>
                                                </div>
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Social Media Type</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.social_media_name}</p>
                                                </div>
                                                <div className="submission_wrap mt-3 p-4 ">
                                                    <span>Task Description</span>
                                                    <div dangerouslySetInnerHTML={{ __html: props.data.description }} />
                                                    {/* <h3 className='mt-2' style={{ fontWeight: "500" }}>We invite you to take part in a brief survey designed to gather insights on [specific topic]. Your valuable input will help us improve our products and services.</h3> */}
                                                    {/* <div className='mt-2 mb-2' >
                                            <p>
                                                <b> Task Type:</b>  Survey Participation

                                            </p>
                                            <p>
                                                <b>  Estimated Time to Complete:</b> 5-10 minutes

                                            </p>
                                            <p>
                                                <b> Reward:</b> $X.XX per completed survey

                                            </p>

                                        </div> */}
                                                    {/* <div className='mt-2 mb-2' >
                                            <h3>Instructions:</h3>
                                            <ol>
                                                <li>
                                                    Click on the survey link provided below.
                                                </li>
                                                <li>
                                                    Answer all the questions honestly and to the best of your ability.
                                                </li>
                                                <li>
                                                    Submit your responses by clicking the "Submit" button at the end of the survey.
                                                </li>
                                                <li>
                                                    You may complete this survey multiple times, but only one submission per person will be considered.
                                                </li>
                                                <li>
                                                    Click on the survey link provided below.
                                                </li>
                                            </ol>
                                            <h3 className='mt-3'>Requirements:</h3>
                                            <ul>
                                                <li>Must have access to the internet.</li>
                                                <li>Must be able to read and understand [language].</li>
                                                <li>Must provide genuine and thoughtful responses.</li>
                                            </ul>
                                        </div> */}
                                                </div>
                                                <div className="submission_wrap mt-3 py-4 ">
                                                    <span>Experience Required</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.level_name}</p>
                                                </div>
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Maximum Allowed Submission</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.max_submission}</p>
                                                </div>
                                                {
                                                    props.data?.type === "5" || props.data?.type === "3" ?
                                                        <>
                                                            <div className="submission_wrap mt-3  ">
                                                                <span>Caption</span>
                                                                <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.caption}</p>
                                                            </div>
                                                            <div className="submission_wrap mt-3  ">
                                                                <span>Hashtag</span>
                                                                <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.hashtag}</p>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                                {
                                                    props.data?.type === "1" || props.data?.type === "2" || props.data?.type === "4" ?
                                                        <>
                                                            <div className="submission_wrap mt-3  ">
                                                                <span>Url</span>
                                                                <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.url}</p>
                                                            </div>

                                                        </>
                                                        : ""
                                                }
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Price ( Per submission )</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>₹ {props.data?.price}</p>
                                                </div>
                                                <div className="submission_wrap mt-3 mb-1 ">
                                                    <span>Due Date</span>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.due_date}</p>
                                                        <img src={calendar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="submission_wrap mt-3  ">
                                                    <span>Task Status</span>
                                                    <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.task_status === "0" ? "Open" : props.data?.task_status === "1" ? "Closed" : ""}</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 mb-3'>
                                                        <div className='submission_wrap mt-3 py-4'>
                                                            <div className="task-reference-block pt-0">
                                                                <span className="custom-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_feature"
                                                                        name="is_feature"

                                                                        checked={props.data.is_feature === "1" ? true : false}


                                                                    />
                                                                    <span className="checkMark"></span>
                                                                </span>
                                                                <div className="task-reference-info">
                                                                    <label className="form-check-label" for="is_feature">
                                                                        Feature Task
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.data.is_feature === "1" ?
                                                        <div className='col-lg-6 mb-3'>
                                                            <div className="submission_wrap submission_bg mt-3" style={{ minHeight: "100px" }}>
                                                                <span className='mb-2'>Bonus Points
                                                                </span>
                                                                <p className='mt-2' style={{ fontWeight: "500" }}>{props.data?.bonus}</p>

                                                            </div>
                                                        </div>
                                                        : ""}



                                                </div>

                                                <div className='submission_wrap p-3 mb-3 icon-boxes-main d-flex flex-row justify-content-start gap-3 '>
                                                    {
                                                        props.data?.attachment?.length > 0 ? props.data?.attachment?.map((curElem, index) => {


                                                            return (
                                                                <div className='account-block mb-0 icon-boxes' key={index}>
                                                                    <span className='icon-box-cross'>
                                                                        {/* <MdCancel style={{ color: "#afa4a8" }} /> */}
                                                                    </span>
                                                                    <span onClick={() => handleViewFile(curElem)}>
                                                                        {curElem.attachmenttype === "PDF" ? <FaRegFilePdf style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : curElem.attachmenttype === "Image" ? <MdOutlineAddPhotoAlternate
                                                                            style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : <MdOutlineVideoLibrary
                                                                            style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} />}
                                                                    </span>
                                                                    <p>{curElem.name}</p>
                                                                </div>
                                                            )
                                                        }) : <div className='d-flex align-items-center justify-content-center w-100'>No attachment file</div>
                                                    }



                                                </div>

                                                {/* <div className="new_task_create ">
                                        <button className='theme-btn light'>Cancel</button>
                                        <button className='theme-btn'>Publish Task</button>
                                    </div> */}

                                                <div className='submission_wrap p-3'>
                                                    <div className="account-block Task">
                                                        <div className="d-flex justify-content-between">
                                                            <h6>Payments Task</h6>
                                                        </div>
                                                        <div className="table-responsive mt-3">
                                                            <table className="table theme-table">
                                                                <thead>
                                                                    <tr className='text-center'>
                                                                        <th>S.No</th>
                                                                        <th>Total Amount</th>
                                                                        <th>Tax</th>
                                                                        <th>Order Id</th>
                                                                        <th>Description</th>
                                                                        <th>TransctionId</th>
                                                                        <th>Transction Status</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {props?.data?.payment_details?.length > 0 ? props?.data?.payment_details.map((curElem, i) => {
                                                                        return (
                                                                            <tr className='text-center' key={i}>
                                                                                <td data-label="S.No">{i + 1}</td>
                                                                                <td data-label="Total Amount">{curElem.totalamount}</td>
                                                                                <td data-label="Tax">{curElem.tax}</td>
                                                                                <td data-label="Order Id">
                                                                                    {curElem.orderId}
                                                                                </td>
                                                                                <td data-label="Description">{curElem.description}</td>
                                                                                <td data-label="TransctionId">{curElem.transctionId}</td>
                                                                                <td data-label="Transction Status">{curElem.transctionstatus}</td>

                                                                            </tr>
                                                                        )
                                                                    })

                                                                        : <td colSpan={7} className='text-center pt-3'>No Payment Details</td>}






                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <AdminAPproveModal show={showModel} handleClose={handleClose} producatId={producatId} type="allTasks" fetchdata={props.fetchdata} />
                                                        <AdminRejectModal show={showModel1} handleClose={handleClose1} producatId={producatId} type="allTasks" fetchdata={props.fetchdata} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollPanel>
                        <DashboardFooter />
                    </>
            }        </>

    )
}

export default AdminTaskDetails

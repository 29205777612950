import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const fetchTakerDashboardAction = (setSubmissionData, setLoader) => (dispatch, getState) => {

    commonAxios("dashboard", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setSubmissionData(res.data)

            }
            else {

                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}
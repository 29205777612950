import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoIosStarOutline } from 'react-icons/io'
import { IoSearchOutline } from 'react-icons/io5'
import { BsDot } from "react-icons/bs";
import Small from '../../../../images/icon-2/Small.png';
import { FaAngleRight, FaSpinner } from "react-icons/fa";
import DashboardFooter from '../../../Common/DashboardFooter'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { onFetchReviewDetails } from '../../../../Redux/Actions/TaskCreatorAction'
import { ProgressBar } from 'primereact/progressbar';
import { Rating } from "primereact/rating";
const CreatorTaskReviews = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const subSlugId = location.state.id.slug;
    const [data, setData] = useState([])
    const [averageData, setAverageData] = useState({})
    const [lengthData, setLengthData] = useState('')
    const [averageReview, setAverageReview] = useState('')

    const [showAll, setShowAll] = useState(false);
    const [loader, setLoader] = useState({
        fetch: false
    })
    const fecthReviewData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "slug": subSlugId
        }


        dispatch(onFetchReviewDetails(formData, loader, setLoader, setData, setAverageData, setLengthData, setAverageReview))
    }
    useEffect(() => {
        if (subSlugId) {
            fecthReviewData()
        }

    }, [subSlugId])

    return (
        <>
            {
                loader.fetch ? <FaSpinner className='spin ms-1' style={{ fontSize: "40px", textAlign: "center", width: "100%", marginTop: "40px" }} /> :
                    <>
                        <ScrollPanel className="custombar2 content-area pt-4">

                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-lg-12'>

                                        <div className="task_applications_wrap">
                                            <div className="account-block">
                                                <div className="creator_wrap_reviews px-2">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="creator_review_head">
                                                                <h3 className='mb-4'>Average rating:<Rating className='rating-color' value={averageReview || 0} readOnly cancel={false} allowHalf />{averageReview || 0} <BsDot style={{ position: "static" }} /> <span>{lengthData || 0} reviews</span></h3>
                                                                {/* <IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline /><IoIosStarOutline />5.0 <BsDot style={{ position: "static" }} /> <span>7 reviews</span></h3> */}

                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Requirements Clarity</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * averageData.availability} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.availability || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Timely Feedback</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">
                                                                            <ProgressBar value={20 * averageData.deadline} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.deadline || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Communication</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * averageData.communication} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.communication || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Cooperation</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * averageData.copperation} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.copperation || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Clear Instructions</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * averageData.work_quality} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.work_quality || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="review_rating_wrap">
                                                                <div className="review_rating">
                                                                    <p>Professionalism</p>
                                                                    {/* <span></span>
                                                                    <p>5.0</p> */}
                                                                    <div className="rev-card-stat">
                                                                        <div className="rev-card-percentage">

                                                                            <ProgressBar value={20 * averageData.skillset} style={{ height: "20px" }} />
                                                                        </div>
                                                                        <div>{averageData.skillset || 0}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        {data.length > 0 ? data.slice(0, showAll ? data.length : 12).map((curElem, i) => {
                                                            return (
                                                                <div className="col-lg-6 col-12" key={i}>
                                                                    <div className="reviews_profiles_wrap">
                                                                        <div className="reviews_profiles">
                                                                            <div className="reviews_profile_img">
                                                                                <img src={curElem.profile ? curElem.profile : Small} alt="" />
                                                                            </div>
                                                                            <div className="review_details">
                                                                                <h3>{curElem.name ? curElem.name : "Abhi Divedi"}</h3>
                                                                                <div className='mt-2'>
                                                                                    <p>{curElem.title ? curElem.title : "Leave a Comment on Insta Video"}</p>
                                                                                    <span>{curElem.reviewdate ? curElem.reviewdate : "Feb 25, 2023"}</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="review_details_para">
                                                                            <p>{curElem.message ? curElem.message : "It was fun working with Mehedi. Excellent quality of work."}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : <div className='d-flex align-items-center justify-content-center mt-5'>No Review</div>}
                                                        {/* <div className="col-lg-6 col-12">
                                                            <div className="reviews_profiles_wrap">
                                                                <div className="reviews_profiles">
                                                                    <div className="reviews_profile_img">
                                                                        <img src={Small} alt="" />
                                                                    </div>
                                                                    <div className="review_details">
                                                                        <h3>Abhi Divedi</h3>
                                                                        <div className='mt-2'>
                                                                            <p>Leave a Comment on Insta Video  </p>
                                                                            <span>Feb 25, 2023</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="review_details_para">
                                                                    <p>It was fun working with Mehedi. Excellent quality of work.</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="reviews_profiles_wrap">
                                                                <div className="reviews_profiles">
                                                                    <div className="reviews_profile_img">
                                                                        <img src={Small} alt="" />
                                                                    </div>
                                                                    <div className="review_details">
                                                                        <h3>Abhi Divedi</h3>
                                                                        <div className='mt-2'>
                                                                            <p>Leave a Comment on Insta Video  </p>
                                                                            <span>Feb 25, 2023</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="review_details_para">
                                                                    <p>Nice person to work with!</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="reviews_profiles_wrap">
                                                                <div className="reviews_profiles">
                                                                    <div className="reviews_profile_img">
                                                                        <img src={Small} alt="" />
                                                                    </div>
                                                                    <div className="review_details">
                                                                        <h3>Abhi Divedi</h3>
                                                                        <div className='mt-2'>
                                                                            <p>Leave a Comment on Insta Video  </p>
                                                                            <span>Feb 25, 2023</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="review_details_para">
                                                                    <p>Thank you for the comprehensive and well-structured technical documentation. The clarity and precision of the information are outstanding, making it easy for our team to understand and implement..</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <div className="reviews_profiles_wrap">
                                                                <div className="reviews_profiles">
                                                                    <div className="reviews_profile_img">
                                                                        <img src={Small} alt="" />
                                                                    </div>
                                                                    <div className="review_details">
                                                                        <h3>Abhi Divedi</h3>
                                                                        <div className='mt-2'>
                                                                            <p>Leave a Comment on Insta Video  </p>
                                                                            <span>Feb 25, 2023</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="review_details_para">
                                                                    <p className='mb-4'>You have a great eye for detail, and your creativity shines through in every element. Excellent work !
                                                                        ...</p>
                                                                    <a href="#" className='show-more'>Show more </a><span><FaAngleRight /></span>

                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-12 text-center">
                                                            {data.length > 12 ? <button className='theme-btn light review_btn mt-5 border-black bg-light' onClick={() => setShowAll(!showAll)} >{showAll ? "Show less" : `Show all ${data.length} reviews`}</button> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </ScrollPanel >
                        <div style={{ marginTop: " 30px" }}><DashboardFooter /></div>
                    </>

            }
        </>

    )
}

export default CreatorTaskReviews

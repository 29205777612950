import React, { useState, useEffect } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function MyAchievements(props) {
    const [data, setData] = useState('')


    const navigation = useNavigate()
    useEffect(() => {
        if (props.data) {
            setData(props?.data)
        }
    }, [props.data])


    return (
        <>

            <ScrollPanel className="achive-right">
                <div className="achive-right-wrap">


                    <div className="AchievementsSingle">
                        <h6>My Achievements</h6>
                        <div className="account-block mt-4">
                            <div className="img-circle"><img src={data?.profile ? data.profile : "https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg"} alt="" /></div>
                            <h6 className="text-center pt-3">{data.name}</h6>
                            <div className="stars mt-3">
                                {+data?.rating
                                    ? [...Array(Math.floor(+data.rating))].map((star, index) => (
                                        <BsStarFill key={index} color={"gold"} />
                                    ))
                                    : ""}
                                {+data.rating % 1 !== 0 ? <BsStarHalf color={"gold"} /> : ""}
                            </div>
                            <div className="contInfo mt-3">
                                <ul className="justify-content-center">
                                    <li><PiUserCircleLight /><span>Identity Verified</span></li>
                                </ul>
                            </div>
                            <div className="contStat small mt-3">
                                <ul>
                                    <li>
                                        <h6>Earned Points</h6>
                                        <h5>{data?.earned}</h5>
                                    </li>
                                    <li>
                                        <h6>Badge</h6>
                                        <h5>{data?.badge}</h5>
                                    </li>
                                    <li>
                                        <h6>Next Level</h6>
                                        <h5>{data?.next_level}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="AchievementsSingle">
                        <h6 className="pt-4">Awaiting Review</h6>
                        <div className="account-block mt-4 mb-3">
                            <h6 className="text-center">You have no <br /> items awaiting review!</h6>
                            <div className="text-center my-4"><img src={require("../../../images/icon/no-review.svg").default} alt="" /></div>
                            <div className="text-center mt-3"><button className="theme-btn" type="button" onClick={() => navigation("/tasks")}>Explore new tasks</button></div>
                        </div>
                    </div>

                </div>






            </ScrollPanel>

        </>
    )
}

export default MyAchievements;
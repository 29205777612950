import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import OtpModelForget from './OtpModelForget';
import { useDispatch } from 'react-redux';
import { MobileLogin } from '../../Redux/Actions/AuthActions';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { Dropdown } from 'primereact/dropdown';

import PhoneNumber from './PhoneNumber';
import MobileLogInOtpModal from './MobileLogInOtpModal';
import { setAlert } from '../../Redux/Actions/AlertActions';
function MobileLogIn(props) {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState({ name: 'India', code: 'IN', phoneCode: '+91' });
    const [countries, setCountries] = useState([])

    const [show, setShow] = useState(false);
    const [signInfo, setSignInfo] = useState({
        "phone": ""
    })
    const [session_id, setSession_id] = useState('')
    const [mobile, setMobile] = useState('')

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleSubmit = (e) => {
        setLoader(true)
        e.preventDefault()
        if (signInfo.phone.length < 10) {
            dispatch(setAlert("Please enter a valid 10-digit mobile number", "danger"))
            setLoader(false)
            return;
        }
        let formData = {
            "mobileNumber": selectedCountry?.phoneCode + signInfo?.phone
        }
        // console.log(formData)
        // handleShow()
        dispatch(MobileLogin(formData, setLoader, props.handleClose, setSession_id, handleShow, setSignInfo, signInfo, setMobile))

    }
    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="d-flex align-items-center ">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div className='ms-2'>{option.name} </div>

                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="d-flex align-items-center ">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div className='ms-2'>{option.name}  ({option.phoneCode})</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };
    return (
        <>
            <Modal className="theme-modal forgotpass-moda sign_form_content creator_new_task link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span>  */}
                        <h3 className="m-auto" style={{ fontSize: "25px" }}>Enter your Phone Number </h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span>    */}

                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-card mt-4 mobile-login">
                            <div className='account-block mb-0 px-1 py-1'>
                                <div className='submission_wrap submission_bg mt-3 mb-3 flex-row' style={{ minHeight: "85px" }}>
                                    <div className="login-input-wrap flex-row" style={{ width: "100%" }}>
                                        <div class="input-group phone_code_wrap ">
                                            <div className="card d-flex justify-content-center phone_code">
                                                <Dropdown
                                                    style={{
                                                        // border: error?.status === false && error?.data?.type === "mobile" ? "1px solid red" : "",
                                                        border: "0",
                                                        borderRight: "0",
                                                        borderRadius: "8px",
                                                        borderTopRightRadius: "0",
                                                        borderBottomRightRadius: "0"
                                                    }}

                                                    value={selectedCountry}
                                                    onChange={(e) => setSelectedCountry(e.value)}
                                                    options={countries}
                                                    optionLabel="name"
                                                    placeholder="Country"
                                                    valueTemplate={selectedCountryTemplate}
                                                    itemTemplate={countryOptionTemplate}
                                                    className="w-full md:w-14rem"
                                                    panelFooterTemplate={panelFooterTemplate}
                                                    dropdownIcon={(opts) => {
                                                        return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                                                    }} />



                                            </div>
                                            <div className="input-logo custom-width">
                                                <input
                                                    type="tel"
                                                    className="login-input border-0"
                                                    placeholder="Phone number"
                                                    name="phone"
                                                    value={signInfo.phone}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value.replace(/[^0-9]/g, '');
                                                        setSignInfo({ ...signInfo, phone: newValue });
                                                    }}

                                                    maxLength={10}
                                                    pattern="[0-9]*"
                                                    inputMode="numeric"
                                                // style={{ border: error?.status === false && error?.data?.type === "mobile" ? "1px solid red" : "" }}
                                                />

                                            </div>
                                            {/* {error?.status === false && error?.data?.type === "mobile" ?
                                                            <p className='mt-2' style={{ color: error?.data?.type === "mobile" && error?.status === false ? "red" : "" }}>{error.msg}</p>
                                                            : ""} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="link_btn text-end my-2">
                                <button className='theme-btn saveBtn ms-auto me-2 d-flex' type="submit" style={{ padding: "8px 24px" }}>Submit {loader ? <FaSpinner className="spin ms-1" /> : null}</button>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
            <PhoneNumber setCountryInput={setCountries} />
            {/* <OtpModelForget show={show} handleClose={handleClose} email={email} session_id={session_id}/> */}
            <MobileLogInOtpModal show={show} handleClose={handleClose} session_id={session_id} mobile={mobile} />
        </>
    )
}

export default MobileLogIn

import React, { useEffect, useState } from 'react'
import { IoClose } from "react-icons/io5";
import { CgMenuRightAlt } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';
const Navbar = ({ userType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigation = useNavigate()
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    console.log(isOpen)
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            console.log("hey")
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <nav className={`navbar navbar-expand-lg sticky-top  ${isScrolled ? 'scrolled' : ''}`}>
                <div className="container-fluid nav_center_wrap">
                    <a className="navbar-brand" href="#">
                        <div className="login-head">
                            <div className="login-logo"><img src={require("../../images/logo.svg").default} alt="" /></div>
                        </div>

                    </a>
                    {/* <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                    <div className="" id="navbarSupportedContent">

                        <ul className={`navbar-nav ${isOpen ? 'open' : ''}`}>
                            <div className='close_icon' onClick={toggleNavbar}>
                                <IoClose />
                            </div>
                            {/* <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Product</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Solutions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Resources</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Download</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Pricing</a>
                            </li> */}


                        </ul>

                    </div>
                    <div className="login_btn_wrap">
                        <div className="sign_up">
                            <button type="" className="login-btn " onClick={() => navigation("/sign-up", { state: userType })}>Sign Up </button>
                        </div>
                        <div className="sign_up sign_in">
                            <button type="" className="login-btn " onClick={() => navigation("/", { state: userType })}>Sign In </button>
                        </div>
                        <div className="menu_icon">
                            <CgMenuRightAlt onClick={toggleNavbar} />
                        </div>
                    </div>
                </div>
            </nav >
        </>
    )
}

export default Navbar

import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState, useEffect } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { IoLocationOutline, IoSearchOutline } from 'react-icons/io5'
import { PiUserCircle } from 'react-icons/pi'
import frame from '../../../images/icon-2/Frame.svg'
import { TbPointFilled } from "react-icons/tb";
import Submission from '../../../images/icon-2/Submission.svg'
import application from '../../../images/icon-2/application.svg'
import CursorClick from '../../../images/icon-2/CursorClick.svg'
import MoneyWavy from '../../../images/icon-2/MoneyWavy.svg'
import click from '../../../images/icon-2/CheckCircle.svg'
import Xeon from '../../../images/icon-2/XCircle.svg'
import { Dropdown } from 'react-bootstrap'
import listImg from '../../../images/PPImage.png'
import usd from '.././../../images/icon/CurrencyDollar.svg'
import { BsCalendar4Event } from "react-icons/bs";
import { NavLink, useNavigate } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'
import Pagination from '../../Common/Pagination'
import AdminCreatorNav from './AdminTaskDeatils/AdminCreatorNav'
import { FiEye } from "react-icons/fi";
import DashboardFooter from '../../Common/DashboardFooter'



const AdminCreatorAllTask = (props) => {
    const navigation = useNavigate()
    const [filteredData, setFilteredData] = useState([])
    const [data, setData] = useState([])

    const [searchInput, setSearchInput] = useState('')
    const [sortOrder, setSortOrder] = useState('asc');
    const handleSort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
        else {
            setData([])
        }
    }, [props.data])
    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data)

        } else {
            const filterSerach = data.filter(item =>

                item.title.toLowerCase().includes(searchInput.toLowerCase())

            );
            setFilteredData(filterSerach)
        }

    }, [data, searchInput])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);

    const handleview = (slug) => {

        navigation("/admin/Task/details", { state: { "slug": slug } })
    }
    const calculateDateDifference = (createdDate) => {
        const now = new Date();
        const created = new Date(createdDate);
        const diffInMs = now - created;

        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInDays > 0) {
            return `${diffInDays} days ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInMinutes} minutes ago`;
        }
    };
    return (
        <>
            {props.loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                <>
                    <ScrollPanel className="custombar2 content-area manage-users">
                        <div className="main_task_details p-4">
                            <div className="do_task_wrapper ">
                                {/* <AdminCreatorNav data={props.profiledata} /> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="main_task_details  pb-2">
                                            <div className="do_task_wrapper ">
                                                <div className="table-bar">
                                                    <div className="table-bar-left">
                                                        <div className="table-bar-icon">
                                                            <div className="table-nav">
                                                                <div className="profile-menu">

                                                                </div>
                                                                {/* <div className="profile-menu">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                        <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                        <li className="drop-list">
                                                                            <span className="custom-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="agentType"
                                                                                />
                                                                                <span className="checkMark"></span>
                                                                            </span>
                                                                            dolor sit amet
                                                                        </li>
                                                                        <li className="drop-list">
                                                                            <span className="custom-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="agentType"
                                                                                />
                                                                                <span className="checkMark"></span>
                                                                            </span>
                                                                            dolor sit amet.
                                                                        </li>


                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div> */}
                                                            </div>


                                                            <ul>
                                                                <li title="Sort by Date" onClick={handleSort}>
                                                                    <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="table-bar-right">
                                                        <div className="header-search">
                                                            <input
                                                                type="text"
                                                                placeholder="Search"
                                                                name="searchInput" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                                                            />
                                                            <span className="left-icon"><IoSearchOutline
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* task_section_container */}

                                                {
                                                    currentTodo.length > 0 ? currentTodo.map((curElem, i) => {

                                                        return (
                                                            <div className='creator_task '>
                                                                <div className="task_section mt-3" key={i}>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div className="task_list_wrap align-items-center w-100">
                                                                            <div className="task_list_img">
                                                                                <img src={curElem.profile ? curElem?.profile : listImg} alt="" />
                                                                            </div>
                                                                            <div className="list_head_wrapper">
                                                                                <div className="list_head">
                                                                                    <h3>{curElem.title}</h3>
                                                                                    <span><TbPointFilled style={{ fontSize: "10px" }} />{calculateDateDifference(curElem.created)}</span>
                                                                                    <div className='earn_status ' style={{ backgroundColor: curElem.status === "0" ? "#fff3cd" : curElem.status === "1" ? "#DEF8EE" : "#f8d7da", color: curElem.status === "0" ? "#856404" : curElem.status === "1" ? "#4AA785" : "#721c24" }}  >{curElem.status === "1" ? "Apporved" : curElem.status === "0" ? "Pending" : "Rejected"} </div>
                                                                                </div>
                                                                                <div className="list_head_bottom">
                                                                                    <div className="head_info">
                                                                                        <img src={usd} alt="" />
                                                                                        <span className='me-4'>Fixed Price - {curElem.price}</span>

                                                                                        <div className='d-flex align-items-center' style={{ gap: "5px" }}>  <BsCalendar4Event /> Due Date: {curElem?.due_date}</div>

                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <span className='ms-auto d-flex align-items-center view-btn' style={{ cursor: "pointer", gap: "3px" }} onClick={() => handleview(curElem?.slug)}><FiEye />
                                                                                View</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="head_para">
                                                                        <div dangerouslySetInnerHTML={{ __html: curElem.description }} />

                                                                    </div>
                                                                    <div className="task_tag_wrap">
                                                                        <div className="task_tag">
                                                                            <span>{curElem.level_name}</span>
                                                                        </div>
                                                                        <div className="task_tag">
                                                                            <span>{curElem.category_name}</span>

                                                                        </div>
                                                                        {/* <div className="task_tag">
                                                            <span>Intermediate</span>
                                                        </div> */}

                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-content-center flex-wrap">
                                                                        <div className="task_person_info">
                                                                            <div className="task_verification">
                                                                                <img src={application} alt="" />
                                                                                <span> 50 Applications</span>
                                                                                {/* <span> Applications</span> */}
                                                                            </div>
                                                                            <div className="task_verification">
                                                                                <img src={Submission} alt="" />
                                                                                <span>{curElem?.max_allowed_submission} </span>
                                                                                <span> Submissions</span>
                                                                            </div>
                                                                            <div className="task_verification">
                                                                                <img src={MoneyWavy} alt="" />
                                                                                <span> 1500$ Spent</span>
                                                                                {/* <span> Spent</span> */}
                                                                            </div>
                                                                            <div className="task_verification">
                                                                                <img src={CursorClick} alt="" />
                                                                                <span> 50 Clicks</span>
                                                                                {/* <span> Clicks</span> */}
                                                                            </div>

                                                                        </div>
                                                                        {/* <div className="task_person_approve">
                                                            <img src={click} alt="" />
                                                            <img src={Xeon} alt="" />
                                                        </div> */}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className='d-flex align-items-center justify-content-center'>{data.length === 0 ? "No Task Found" : ""}</div>
                                                }

                                                <Pagination
                                                    listData={filteredData}
                                                    pagination={pagination}
                                                    setPagination={setPagination}
                                                    currentTodo={currentTodo}
                                                    listArr={data}
                                                    loader={props.loader.fetch}
                                                />







                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>



                    </ScrollPanel>
                    <DashboardFooter />
                </>

            }
        </>
    )
}

export default AdminCreatorAllTask

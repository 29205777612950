import React, { useEffect, useState } from "react";

import { IoCalendarClearOutline } from "react-icons/io5";
import { FiDownload, FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { PiUserCircleLight } from "react-icons/pi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineEnvelope } from "react-icons/hi2";
import ProfileInfo from "./ProfileInfo";

function PaymentMethodsTabs(props) {

  const [bankData, setBankData] = useState([])
  const [upiData, setUpiData] = useState([])
  useEffect(() => {
    if (props.data) {
      if (props.data.bank_details) {
        setBankData(props.data.bank_details)
      }
      // if (props.data.upi_details) {
      //   setUpiData(props.data.upi_details)
      // }

    }
  }, [props.data])
  console.log(bankData)
  return (
    <>
      <div className="account-block mb-5">
        <div className="col-12">
          <div className="campaign-stat-right mt-0 w-100 ps-0 pe-0">
            <div className="pb-0 bank_details mb-3">
              <div className="d-flex justify-content-between">
                <h6>Payment Details List</h6>
                {/* <a href=""><span>Show All</span></a> */}
              </div>
              <div className="table-responsive mt-3">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>S.n</th>
                      <th>Type</th>
                      <th>Account</th>
                      <th>Data</th>

                      {/* <th className="text-center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {bankData.length > 0 ? bankData.map((curElem, i) => {
                      const parsedData = JSON.parse(curElem?.data || null);

                      return (
                        <tr key={i}>
                          <td data-label="S.n.">{i + 1}</td>
                          <td data-label="Type">{curElem.type}</td>
                          <td data-label="Account">{curElem.account}</td>
                          <td data-label="Data">{
                            parsedData ? (
                              Object.entries(parsedData).map(([key, value]) => (
                                <div key={key}>{`${key}: ${value}`}</div>
                              ))
                            ) : (
                              ""
                            )
                          }</td>


                        </tr>
                      )
                    }) : <td className="text-center py-2 pt-lg-3" colSpan={7}>No Payment Details Found</td>}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      {/* <StripModal
        show={show2}
        handleClose={handleClose2}
        typeStripe={typeStripe}
      />
      <BankModal
        show={show}
        handleClose={handleClose}
      /> */}
      {/* <EditBankModel
        show1={show1}
        handleClose1={handleClose1}
        edit={edit}
      /> */}
    </>
  )
}

export default PaymentMethodsTabs;